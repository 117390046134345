import React, { useState } from 'react';
import Agreements from '../../components/Agreements';
import AppBanner from '../../components/AppBanner';
import DownloadApp from '../../components/DownloadApp';
import styles from './AuthPage.module.scss';
import Logo from '../../assets/images/logo.svg';
import InputBase from '../../components/InputBase';
import Button from '../../components/Button';
import LoginButton from '../../components/LoginButton';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'src/hooks/useSnackbar';
import qs from 'query-string';
import Loader from 'src/components/Loader';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import { useCaptcha } from 'src/hooks/useCaptcha';
import { checkEmailFormat } from 'src/utils/checkEmailFormat';
import { useMountedState } from 'src/hooks/useMountedState';
import { sendEmailCode } from 'src/api/auth';
import { authWithCodeThunk } from 'src/store/thunks/authThunks';
import editIcon from 'src/assets/icons/ic_edit.svg';
import ResendCode from './ResendCode';

function AuthPage() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { redirectTo, partner_id, partner_code } = qs.parse(search) as {
    [key: string]: string;
  };
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const { executeCaptcha } = useCaptcha();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [emailStep, setEmailStep] = useState(true);
  const [firstAuth, setFirstAuth] = useState(true);
  const isMount = useMountedState();
  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState('');
  const partnerId = partner_id || qs.parse(redirectTo)?.partner_id;
  const partnerCode = partner_code || qs.parse(redirectTo)?.partner_code;
  const PARTNER_LINK_USER_FLOW =
    partnerId &&
    partnerId !== '53' &&
    partnerId !== '182' &&
    partnerId !== '557' &&
    partnerId !== '577';

  async function sendCode() {
    try {
      if (!checkEmailFormat(email)) {
        setEmailError(t('emailInvalid'));
        return;
      }
      setEmailError('');
      setLoading('pending');
      const captcha = await executeCaptcha();
      const firstAuth = await sendEmailCode(email, captcha);
      if (isMount()) {
        setFirstAuth(!!firstAuth);
        setEmailStep(false);
        setLoading('fulfilled');
      }
    } catch (error: any) {
      setSnackbar({ type: 'error', message: error.message });
      if (isMount()) {
        setLoading('rejected');
      }
    }
  }

  async function checkCode() {
    try {
      setLoading('pending');
      const profile = await dispatch(authWithCodeThunk(email, code, firstAuth));
      if (redirectTo) {
        history.replace(
          `${redirectTo}${
            firstAuth
              ? `${redirectTo.includes('?') ? '&' : '?'}firstAuth=true`
              : ''
          }`,
        );
      } else {
        history.replace({
          pathname:
            profile?.classicPlan.active ||
            profile?.postponePlan.available ||
            profile?.payGoPlan.active
              ? '/'
              : '/countries',
          search: qs.stringify({
            ...qs.parse(search),
            ...(firstAuth
              ? {
                  firstAuth: true,
                }
              : {}),
          }),
        });
      }
      setSnackbar({
        message: firstAuth ? t('thankYouForSigningUp') : t('welcomeBack'),
        type: 'success',
      });
      setLoading('fulfilled');
    } catch (error: any) {
      if (error.badCode && isMount()) {
        if (error.attemps === '3') {
          setCodeError(t('wrongAuthCodeTryResend'));
        } else {
          setCodeError(t('wrongAuthCode'));
        }
        setLoading('rejected');
        return;
      }
      setSnackbar({ type: 'error', message: error.message });
      if (isMount()) {
        setLoading('rejected');
      }
    }
  }

  function onCodeChange(value: string) {
    if (isNaN(+value)) {
      return;
    }
    setCode(value);
  }

  return (
    <>
      <div className={styles.container}>
        {!PARTNER_LINK_USER_FLOW && (
          <>
            <AppBanner />
            <div className={styles.downloadApp}>
              <DownloadApp />
            </div>
          </>
        )}
        <img
          src={Logo}
          className={styles.logo}
          alt="Yesim"
          onClick={() => {
            history.replace(redirectTo || '/');
          }}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>
            {partnerId === '386' && partnerCode === 'lndng100'
              ? t('signInToClaimYourBonus')
              : t('signIn')}
          </h1>
          <div className={styles.loginButtons}>
            <LoginButton service="apple" id="apple-login" />
            <LoginButton service="google" id="google-login" />
          </div>
          <span className={styles.separator}>{t('or')}</span>
          <div className={styles.form}>
            {emailStep ? (
              <InputBase
                value={email}
                onChangeText={setEmail}
                placeholder={t('auth.emailAddress')}
                isError={!!emailError}
                onFocus={() => setEmailError('')}
                onKeyDown={(e) => e.key === 'Enter' && sendCode()}
                id="auth-email-input"
              />
            ) : (
              <>
                <div className={styles.authEditEmail}>
                  <span className={styles.email}>{email}</span>
                  <img
                    src={editIcon}
                    alt={'edit'}
                    onClick={() => {
                      setEmailStep(true);
                      setCode('');
                      setCodeError('');
                    }}
                    className={styles.editIcon}
                  />
                </div>
                <InputBase
                  value={code}
                  type={'numbers'}
                  maxLength={4}
                  onChangeText={onCodeChange}
                  placeholder={t('authorizationCode')}
                  isError={!!codeError}
                  onFocus={() => setCodeError('')}
                  onKeyDown={(e) => e.key === 'Enter' && checkCode()}
                />
                <ResendCode handleSendAgain={sendCode} error={codeError} />
              </>
            )}
          </div>
          {emailError && <span className={styles.error}>{emailError}</span>}
          {codeError && <span className={styles.error}>{codeError}</span>}
          {emailStep ? (
            <Button
              label={t('continue')}
              variant="primary"
              onClick={sendCode}
              disabled={!email}
              key={'emailStep-continue'}
              id="auth-continue-email"
            />
          ) : (
            <Button
              label={t('continue')}
              variant="primary"
              onClick={checkCode}
              disabled={code.length < 4}
              key={'codeStep-continue'}
              id="auth-continue-code"
            />
          )}
          <Agreements />
        </div>
      </div>
      {loading === 'pending' && (
        <div className="loader">
          <Loader />
        </div>
      )}
    </>
  );
}

export default AuthPage;
