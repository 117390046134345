import React, { useMemo, useState } from 'react';
import Agreements from '../Agreements';
import Badge from '../Badge';
import Button from '../Button';
import CurrencySwitcher from '../CurrencySwitcher';
import LogOutPopup from '../LogOutPopup';
import styles from './Sidenav.module.scss';
import '../LanguageSwitcher/LanguageSwitcher.scss';
import '../CurrencySwitcher/CurrencySwitcher.scss';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import MenuIcon from '../../assets/icons/ic_menu.svg';
import CloseMenuIcon from '../../assets/icons/ic_close_menu.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import AppStoreBadge from '../AppStoreBadge';
import GooglePlayBadge from '../GooglePlayBadge';
import LinkedInIcon from '../../assets/icons/ic_linkedin.svg';
import FacebookIcon from '../../assets/icons/ic_facebook.svg';
import InstagramIcon from '../../assets/icons/ic_instagram.svg';
import XIcon from '../../assets/icons/ic_x.svg';
import YouTubeIcon from '../../assets/icons/ic_youtube.svg';
import SwissMade from '../../assets/images/swiss_made.svg';
import { isIOS, isAndroid } from '../../utils/detectDevice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import qs from 'query-string';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import EsimIcon from '../../assets/icons/ic_esim.svg';
import DataIcon from '../../assets/icons/ic_data.svg';
import NumbersIcon from '../../assets/icons/ic_numbers.svg';
import SettingsIcon from '../../assets/icons/ic_settings.svg';
import NotificationsIcon from '../../assets/icons/ic_notifications.svg';
import NotificationsGrayIcon from '../../assets/icons/ic_notification_gray.svg';
import PurchasesIcon from '../../assets/icons/ic_purchases.svg';
import HelpIcon from '../../assets/icons/ic_help.svg';
import FaqIcon from '../../assets/icons/ic_faq.svg';
import PartnersIcon from '../../assets/icons/ic_partners.svg';

type SidenavProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const Sidenav: React.FC<SidenavProps> = ({ isOpen, setOpen }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const isLoggedIn = profile.id;
  const { pathname, search } = useLocation();
  const notifications = useSelector((state: RootState) => state.notifications);
  const notificationsCount = useMemo(
    () => notifications.filter((i) => i.received === '0').length,
    [notifications],
  );
  const [openLogOutPopup, setOpenLogOutPopup] = useState(false);

  return (
    <div className={styles.container}>
      <Badge content={notificationsCount}>
        <img
          src={isOpen ? CloseMenuIcon : MenuIcon}
          alt="Menu"
          className={styles.menuIcon}
          onClick={() => setOpen(!isOpen)}
          id="header-nav-mobile-menu"
        />
      </Badge>
      {isOpen && (
        <div className={styles.content}>
          <nav className={styles.menu}>
            {isLoggedIn && (
              <>
                <h4 className={styles.label}>{t('services')}</h4>
                <ul className={styles.section}>
                  <NavLink
                    to={'/'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    exact
                    id="mobile-menu-my-esim"
                  >
                    <img
                      src={EsimIcon}
                      alt="My eSIM"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('myEsim')}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                  <NavLink
                    to={'/countries'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-mobile-data"
                  >
                    <img
                      src={DataIcon}
                      alt="Mobile data"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('internet')}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                  <NavLink
                    to={'/numbers'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-virtual-numbers"
                  >
                    <img
                      src={NumbersIcon}
                      alt="Virtual numbers"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('virtualNumbers')}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                </ul>
                <h4 className={styles.label}>{t('account')}</h4>
                <ul className={styles.section}>
                  <NavLink
                    to={'/settings'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-settings"
                  >
                    <img
                      src={SettingsIcon}
                      alt="Account settings"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>
                        {t('accountSettings')}
                      </span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                  <NavLink
                    to={'/notifications'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-notifications"
                  >
                    <img
                      src={
                        notificationsCount > 0
                          ? NotificationsIcon
                          : NotificationsGrayIcon
                      }
                      alt="Notifications"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('notifications')}</span>
                      {notificationsCount > 0 && (
                        <div className={styles.badge}>
                          <span className={styles.badgeText}>
                            {notificationsCount}
                          </span>
                        </div>
                      )}
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                  <NavLink
                    to={'/history'}
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-history"
                  >
                    <img
                      src={PurchasesIcon}
                      alt="Purchase history"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>
                        {t('settings.purchaseHistory')}
                      </span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </NavLink>
                  <li
                    className={styles.item}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      window.zE('messenger', 'show');
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      window.zE('messenger', 'open');
                      setOpen(false);
                    }}
                    id="mobile-menu-help-center"
                  >
                    <img
                      src={HelpIcon}
                      alt="Help center"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('helpCenter')}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </li>
                  <a
                    href={`https://yesimsupport.zendesk.com/hc/${
                      i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-faq"
                  >
                    <img src={FaqIcon} alt="FAQ" className={styles.itemIcon} />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{'FAQ'}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </a>
                </ul>
              </>
            )}
            {!isLoggedIn && (
              <Button
                label={t('signIn')}
                onClick={() => {
                  history.push({
                    pathname: '/login',
                    search: qs.stringify({
                      redirectTo: pathname + search,
                    }),
                  });
                  setOpen(false);
                }}
                variant="plain"
                className={styles.signIn}
                id="mobile-menu-sign-in"
              />
            )}
            <h4 className={styles.label}>{t('currency')}</h4>
            <CurrencySwitcher className={'sidenav-currency-switcher'} />
            <h4 className={styles.label}>{t('settings.language')}</h4>
            <LanguageSwitcher className={'sidenav-language-switcher'} />
            {isLoggedIn && (
              <>
                <h4 className={styles.label}>{'Yesim Group Product'}</h4>
                <ul className={styles.section}>
                  <a
                    href="https://partners.yesim.app/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-yesim-partners-program"
                  >
                    <img
                      src={PartnersIcon}
                      alt="Yesim Partners Program"
                      className={styles.itemIcon}
                    />
                    <div className={styles.wrapper}>
                      <span className={styles.text}>
                        {'Yesim Partners Program'}
                      </span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </a>
                </ul>
                <h4 className={styles.label}>{t('usefulLinks')}</h4>
                <ul className={styles.section}>
                  <a
                    href="https://yesim.app"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.item}
                    onClick={() => setOpen(false)}
                    id="mobile-menu-about-yesim"
                  >
                    <div className={styles.wrapper}>
                      <span className={styles.text}>{t('aboutYesim')}</span>
                      <img
                        src={ChevronIcon}
                        alt="Arrow"
                        className={styles.arrowIcon}
                      />
                    </div>
                  </a>
                </ul>
                <div className={styles.agreements}>
                  <Agreements buttonText={t('purchase.paySecurely')} />
                </div>
                <button
                  className={styles.logOut}
                  onClick={() => {
                    setOpenLogOutPopup(true);
                    setOpen(false);
                  }}
                  id="mobile-menu-log-out"
                >
                  <span className={styles.logOutText}>
                    {t('settings.logOut')}
                  </span>
                </button>
              </>
            )}
          </nav>
          <div className={styles.footer}>
            <div className={styles.badges}>
              {(isIOS || !isAndroid) && <AppStoreBadge />}
              {(isAndroid || !isIOS) && <GooglePlayBadge />}
            </div>
            <div className={styles.social}>
              <a
                href="https://www.facebook.com/yesim.travel/"
                target="_blank"
                rel="noreferrer"
                id="mobile-menu-facebook"
              >
                <img
                  src={FacebookIcon}
                  alt="Facebook"
                  className={styles.icon}
                />
              </a>
              <a
                href="https://www.instagram.com/yesim.travel/?hl=en"
                target="_blank"
                rel="noreferrer"
                id="mobile-menu-instagram"
              >
                <img
                  src={InstagramIcon}
                  alt="Instagram"
                  className={styles.icon}
                />
              </a>
              <a
                href="https://twitter.com/Yesim_app"
                target="_blank"
                rel="noreferrer"
                id="mobile-menu-x"
              >
                <img src={XIcon} alt="X" className={styles.icon} />
              </a>
              <a
                href="https://linkedin.com/company/yesimbusiness"
                target="_blank"
                rel="noreferrer"
                id="mobile-menu-linkedin"
              >
                <img
                  src={LinkedInIcon}
                  alt="LinkedIn"
                  className={styles.icon}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCzl5P2jv1SppsnDv4Kqnhqg"
                target="_blank"
                rel="noreferrer"
                id="mobile-menu-youtube"
              >
                <img src={YouTubeIcon} alt="YouTube" className={styles.icon} />
              </a>
            </div>
            <div className={styles.copyright}>
              <span className={styles.copyrightText}>{'© 2023 Yesim'}</span>
              <img
                src={SwissMade}
                alt="Swiss Made"
                className={styles.footerImage}
              />
            </div>
          </div>
        </div>
      )}
      <LogOutPopup
        open={openLogOutPopup}
        onClose={() => setOpenLogOutPopup(false)}
      />
    </div>
  );
};

export default Sidenav;
