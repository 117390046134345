import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Api } from '../../api';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import styles from './MessagesPage.module.scss';

type Message = {
  id: string;
  text: string;
  date: string;
  msg_read: string;
};

const MessagesPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { sender } = qs.parse(search) as { [key: string]: string };
  const [list, setList] = useState<{ data: Message[]; date: string }[]>([]);
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      setLoading('pending');
      const res = await Api.numbers.getMessages(sender);
      if (Array.isArray(res?.['list'])) {
        setList(
          res['list']
            .sort((a, b) => moment(b.date).diff(a.date))
            .reduce(
              (
                accumulator: { data: Message[]; date: string }[],
                currentValue: Message,
                currentIndex: number,
                array: any,
                date = currentValue.date,
              ) => {
                const keyObjectPosition = accumulator.findIndex((item) =>
                  moment(item.date).isSame(date, 'day'),
                );
                if (keyObjectPosition >= 0) {
                  accumulator[keyObjectPosition].data.push(currentValue);
                  return accumulator;
                } else {
                  return accumulator.concat({ data: [currentValue], date });
                }
              },
              [],
            ),
        );
      }
      setLoading('fulfilled');
    }

    init();
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar title={sender} goBack={() => history.push('/numbers')} />
        {list.length > 0 ? (
          <ul className={styles.list}>
            {list.map((section, index) => (
              <li key={index}>
                <p className={styles.date}>
                  {moment(section.date).format('D MMMM')}
                </p>
                {section.data.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <div className={styles.bubble}>
                      <p className={styles.text}>{item.text}</p>
                    </div>
                    <p className={styles.time}>
                      {moment(item.date).format('HH:mm')}
                    </p>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.listEmpty}>{t('noMessagesYet')}</p>
        )}
      </div>
    </div>
  );
};

export default MessagesPage;
