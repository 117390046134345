import React, { useEffect, useRef, useState } from 'react';
import './CurrencySwitcher.scss';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import ExpandMoreIcon from '../../assets/icons/ic_expand_more.svg';
import ExpandLessIcon from '../../assets/icons/ic_expand_less.svg';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import CurrencyIcon from '../../assets/icons/ic_currency.svg';
import { RootState } from '../../store';
import { Currency } from '../../store/reducers/currenciesReducer/currenciesReducer.types';
import { getCountriesPackages } from '../../store/thunks/countriesPackagesThunks';
import { changeCurrency } from '../../store/thunks/currenciesThunk';

type CurrencySwitcherProps = {
  className?: string;
};

const CurrencySwitcher: React.FC<CurrencySwitcherProps> = ({ className }) => {
  const dispatch = useThunkDispatch();
  const [isOpen, setOpen] = useState(false);
  const currencies = useSelector((state: RootState) => state.currencies);
  const profile = useSelector((state: RootState) => state.userProfile);
  const [currentCurrency, setCurrentCurrency] = useState(
    currencies.find((i) => i.code === profile.paymentCurrency?.code),
  );
  const containerRef = useRef();

  useEffect(() => {
    function handleClick(e: any) {
      if (containerRef && containerRef.current) {
        const ref: any = containerRef.current;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  const onChange = async (currency: Currency) => {
    await dispatch(changeCurrency(currency));
    await dispatch(getCountriesPackages());
    setCurrentCurrency(currency);
    setOpen(false);
  };

  return (
    <div className={'currency-switcher-container'} ref={containerRef as any}>
      <div
        className={`currency-switcher-input ${
          className ? ` ${className}` : ''
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        <img
          src={CurrencyIcon}
          alt="Currency"
          className={'currency-switcher-currency-icon'}
        />
        <span className={'currency-switcher-label'}>
          {`${currentCurrency?.symbol}, ${currentCurrency?.code}`}
        </span>
        <img
          src={isOpen ? ExpandLessIcon : ExpandMoreIcon}
          alt="Arrow"
          className={'currency-switcher-arrow-icon'}
        />
        <img
          src={ChevronIcon}
          alt="Icon"
          className={'currency-switcher-chevron-icon'}
        />
      </div>
      {isOpen && (
        <ul className={'currency-switcher-dropdown'}>
          {currencies.map((i) => (
            <li
              key={i.code}
              onClick={() => onChange(i)}
              className={'currency-switcher-label'}
            >
              {`${i?.symbol}, ${i?.code}`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CurrencySwitcher;
