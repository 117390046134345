import React, { InputHTMLAttributes } from 'react';
import CloseIcon from '../../assets/icons/ic_close.svg';
import styles from './InputBase.module.scss';

type InputBaseProps = InputHTMLAttributes<HTMLInputElement> & {
  onChangeText?: (value: string) => void;
  isSuccess?: boolean;
  isError?: boolean;
  className?: string;
};

const InputBase: React.FC<InputBaseProps> = ({
  value,
  onChangeText,
  isError,
  className,
  ...props
}) => {
  return (
    <div
      className={`${styles.container} ${isError && styles.error} ${className}`}
    >
      <input
        className={styles.input}
        onChange={(e) => onChangeText?.(e.target.value)}
        value={value}
        {...props}
      />
      {value && (
        <img
          src={CloseIcon}
          alt="Clear"
          className={styles.clearIcon}
          onClick={() => onChangeText?.('')}
        />
      )}
    </div>
  );
};

export default InputBase;
