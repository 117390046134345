import React, { useState } from 'react';
import Account from '../Account';
import Balance from '../Balance';
import CurrencySwitcher from '../CurrencySwitcher';
import LanguageSwitcher from '../LanguageSwitcher';
import Notifications from '../Notifications';
import styles from './AppHeader.module.scss';
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Sidenav from '../Sidenav';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import GoBack from '../GoBack';
import formatMegabytes from '../../utils/formatMegabytes';

const AppHeader: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const redirectTo = pathname + search;
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const isLoggedIn = profile.id;
  const isPurchaseRoute = useRouteMatch(['/plans', '/payment']);
  const { country, totalMb, period, type, ycoins } = qs.parse(search) as {
    [key: string]: string;
  };
  const [isOpen, setOpen] = useState(false);

  const title =
    {
      '/plans': t('buyPlan'),
      '/payment':
        type === 'ycoins'
          ? `${ycoins} Ycoins`
          : `${country} \n ${formatMegabytes(totalMb)} / ${t('day', {
              days: +period,
              count: +period,
            })}`,
    }[pathname] || '';

  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        {isPurchaseRoute && (
          <div className={styles.back}>
            <GoBack />
          </div>
        )}
        <nav className={styles.content}>
          {isPurchaseRoute && <h4 className={styles.title}>{title}</h4>}
          <img
            src={Logo}
            className={styles.logo}
            alt="Yesim"
            onClick={() => {
              if (isLoggedIn) {
                history.push('/');
              } else {
                history.push({
                  pathname: '/login',
                  search: qs.stringify({
                    redirectTo,
                  }),
                });
              }
              setOpen(false);
            }}
            id="header-logo"
          />
          <ul className={styles.menu}>
            {isLoggedIn && (
              <li>
                <NavLink
                  id="header-nav-my-esim"
                  to="/"
                  className={styles.navLink}
                  activeClassName={styles.active}
                  exact
                >
                  {t('myEsim')}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                id="header-nav-mobile-data"
                to="/countries"
                className={styles.navLink}
                activeClassName={styles.active}
                isActive={(match, { pathname }) => {
                  return [
                    '/countries',
                    '/regions',
                    '/global-packages',
                    '/plans',
                    '/network-operators',
                    '/pay-as-you-go-introduction',
                    '/international-esim-introduction',
                    '/pay-as-you-go',
                    '/international-esim',
                    '/pay-as-you-go-activated',
                    '/international-esim-activated',
                    '/country-prices',
                  ].includes(pathname);
                }}
              >
                {t('internet')}
              </NavLink>
            </li>
            <li>
              <NavLink
                id="header-nav-virtual-numbers"
                to="/numbers"
                className={styles.navLink}
                activeClassName={styles.active}
                isActive={(match, { pathname }) => {
                  return [
                    '/numbers',
                    '/numbers-archive',
                    '/senders',
                    '/messages',
                    '/select-country-service',
                    '/select-region',
                    '/select-number',
                    '/buy-number',
                    '/number-activated',
                  ].includes(pathname);
                }}
              >
                {t('virtualNumbers')}
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={styles.items}>
          {isLoggedIn ? (
            <>
              <Balance />
              <CurrencySwitcher />
              <LanguageSwitcher alternate />
              <Notifications />
              <Account />
            </>
          ) : (
            <span
              className={styles.login}
              onClick={() => {
                history.push({
                  pathname: '/login',
                  search: qs.stringify({
                    redirectTo,
                  }),
                });
              }}
              id="header-sign-in"
            >
              {t('signIn')}
            </span>
          )}
        </div>
        {!isPurchaseRoute && (
          <div className={styles.mobileItems}>
            {isLoggedIn && <Balance onClick={() => setOpen(false)} />}
            <Sidenav isOpen={isOpen} setOpen={setOpen} />
          </div>
        )}
      </div>
    </header>
  );
};

export default AppHeader;
