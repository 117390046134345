import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import SegmentedControl from '../../components/SegmentedControl';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import { getNumbersCountries } from '../../store/thunks/numbersCountriesThunks';
import { getNumbersServices } from '../../store/thunks/numbersServicesThunks';
import getFlag from '../../utils/getFlag';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './SelectCountryServicePage.module.scss';
import ArrowIcon from '../../assets/icons/ic_arrow_forward.svg';

const SelectCountryServicePage: React.FC = () => {
  const { t } = useTranslation();
  const [segment, setSegment] = useState(0);
  const dispatch = useThunkDispatch();
  const countries = useSelector((state: RootState) => state.numbersCountries);
  const services = useSelector((state: RootState) => state.numbersServices);
  const [loading, setLoading] = useLoadingStatus('pending');
  const history = useHistory();

  useEffect(() => {
    async function init() {
      await dispatch(getNumbersCountries());
      await dispatch(getNumbersServices());
      setLoading('fulfilled');
    }

    init();
  }, []);

  useEffect(() => {
    if (segment === 0) {
      pushDataLayerEvent({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: 'numbers_countries',
          item_list_name: 'Select country',
        },
      });
    } else {
      pushDataLayerEvent({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: 'numbers_services',
          item_list_name: 'Select service',
        },
      });
    }
  }, [segment]);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar
          title={segment === 0 ? t('selectCountry') : t('selectService')}
          goBack={() => history.push('/numbers')}
        >
          <SegmentedControl
            options={[
              { label: t('countries'), value: 0 },
              { label: t('services'), value: 1 },
            ]}
            value={segment}
            setValue={setSegment}
          />
        </NavBar>
        {segment === 0 && (
          <ul className={styles.list}>
            {countries.map((item, index) => (
              <li
                key={index}
                className={styles.item}
                onClick={() => {
                  if (item.districts.length == 1) {
                    history.push({
                      pathname: '/select-number',
                      search: qs.stringify({
                        districtId: item.districts[0].id,
                      }),
                    });
                  } else {
                    history.push({
                      pathname: '/select-region',
                      search: qs.stringify({
                        country: item.country,
                        iso: item.iso,
                      }),
                    });
                  }
                }}
              >
                <img
                  src={getFlag(item.iso)}
                  alt="Flag"
                  className={styles.flag}
                />
                <div className={styles.wrapper}>
                  <p className={styles.text}>{item.country}</p>
                  <img
                    src={ArrowIcon}
                    alt="Arrow"
                    className={styles.arrowIcon}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
        {segment === 1 && (
          <>
            <ul className={styles.list}>
              {services.map((item, index) => (
                <li
                  key={index}
                  className={styles.item}
                  onClick={() => {
                    history.push({
                      pathname: '/select-number',
                      search: qs.stringify({
                        serviceId: item.id,
                        serviceName: item.name,
                      }),
                    });
                  }}
                >
                  <img
                    src={item.icon}
                    alt="Icon"
                    className={styles.serviceIcon}
                  />
                  <div className={styles.wrapper}>
                    <p className={styles.text}>{item.name}</p>
                    <img
                      src={ArrowIcon}
                      alt="Arrow"
                      className={styles.arrowIcon}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <p className={styles.hint}>{t('newServices')}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectCountryServicePage;
