import React, { useEffect, useMemo, useState } from 'react';
import InternationalEsim from '../../components/InternationalEsim';
import SegmentedControl from '../../components/SegmentedControl';
import getPackageIcon from '../../utils/getPackageIcon';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './CountriesPage.module.scss';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SearchInput from '../../components/SearchInput';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import getFlag from 'src/utils/getFlag';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { countriesFilter } from 'src/utils/countriesFilter';
import NewIcon from '../../assets/icons/ic_new.svg';
import ArrowIcon from '../../assets/icons/ic_arrow_forward.svg';

const CountriesPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const isRegionsRoute = useRouteMatch(['/regions']);
  const isGlobalPackagesRoute = useRouteMatch(['/global-packages']);
  const { partner_id } = qs.parse(search);
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState('');
  const [segment, setSegment] = useState(
    isRegionsRoute ? 1 : isGlobalPackagesRoute ? 2 : 0,
  );
  const packages = useSelector(
    (state: RootState) => state.countriesPackages.packages,
  );
  const countries = useSelector(
    (state: RootState) => state.countriesPackages.countries,
  );
  const lastPurchasedIso = useSelector(
    (state: RootState) => state.userProfile.lastPurchasedIso,
  );
  const globalPackages = useSelector(
    (state: RootState) => state.countriesPackages.globalPackages,
  );

  const packagesList = useMemo(
    () =>
      packages.filter(
        (i) =>
          i.name?.toLowerCase().includes(filter.toLowerCase()) ||
          i.countries?.join().toLowerCase().includes(filter.toLowerCase()),
      ),
    [filter, packages?.[0]?.package_id],
  );

  const globalPackagesList = useMemo(
    () =>
      globalPackages.filter(
        (i) =>
          i.name?.toLowerCase().includes(filter.toLowerCase()) ||
          i.countries?.join().toLowerCase().includes(filter.toLowerCase()),
      ),
    [filter, globalPackages?.[0]?.package_id],
  );

  const countriesList = useMemo(
    () => countriesFilter(countries, filter),
    [filter, countries?.[0]?.price?.currency],
  );

  const popularCountries = useMemo(
    () =>
      countries
        .filter(
          (i) => Number(i.popular) > 0 || lastPurchasedIso.includes(i.iso),
        )
        .map((i) =>
          lastPurchasedIso.includes(i.iso) ? { ...i, popular: 0 } : i,
        )
        .sort((a, b) => (Number(a.popular) > Number(b.popular) ? 1 : -1)),
    [countries?.[0]?.price?.currency],
  );

  function onChangeSegment(value: number) {
    setSegment(value);
    history.replace({
      pathname:
        value === 0
          ? '/countries'
          : value === 1
          ? '/regions'
          : '/global-packages',
      search,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.languages[0]]);

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'countries',
        item_list_name: 'Countries',
      },
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t('esimMobileDataPlans')}</h1>
        <div className={styles.search}>
          <SearchInput
            value={filter}
            onChangeText={(value) => {
              setFilter(value);
              if (segment === 1) {
                onChangeSegment(0);
              }
            }}
          />
        </div>
        {!filter && (
          <SegmentedControl
            options={[
              { label: t('countries'), value: 0 },
              { label: t('regions'), value: 1 },
              { label: t('global'), value: 2, icon: NewIcon },
            ]}
            value={segment}
            setValue={onChangeSegment}
          />
        )}
        {!filter && <InternationalEsim />}
        {segment === 0 && (
          <>
            {countriesList.length ? (
              <>
                {!filter && (
                  <>
                    <h2 className={styles.label}>{t('popularCountries')}</h2>
                    <ul className={styles.list}>
                      {popularCountries.map((item) => (
                        <li
                          key={item.id}
                          className={styles.item}
                          onClick={() => {
                            history.push({
                              pathname: '/plans',
                              search: qs.stringify({
                                name: item.country,
                                id: item.id,
                                type: 'country',
                                iso: item.iso,
                                partner_id,
                              }),
                            });
                            pushDataLayerEvent({
                              event: 'select_item',
                              ecommerce: {
                                item_list_id: 'countries',
                                item_list_name: 'Countries',
                                items: [
                                  {
                                    item_id: item.id,
                                    item_name: item.country,
                                    item_list_id: 'countries',
                                    item_list_name: 'Countries',
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          <img
                            src={getFlag(item.iso)}
                            alt="Country"
                            className={styles.countryIcon}
                          />
                          <div className={styles.wrapper}>
                            <h4 className={styles.name}>{item.country}</h4>
                            <p className={styles.cost}>
                              {t('fromCostPerGB', {
                                cost: item.price.amount,
                                currencySymbol: item.price.symbol,
                              })}
                            </p>
                          </div>
                          <img
                            src={ChevronIcon}
                            alt="Arrow"
                            className={styles.arrowIcon}
                          />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {!!countriesList.length && (
                  <>
                    {!filter && (
                      <h2 className={styles.label}>{t('countries')}</h2>
                    )}
                    <ul className={styles.list}>
                      {countriesList.map((item) => (
                        <li
                          key={item.id}
                          className={styles.item}
                          onClick={() => {
                            history.push({
                              pathname: '/plans',
                              search: qs.stringify({
                                name: item.country,
                                id: item.id,
                                type: 'country',
                                iso: item.iso,
                                partner_id,
                              }),
                            });
                            pushDataLayerEvent({
                              event: 'select_item',
                              ecommerce: {
                                item_list_id: 'countries',
                                item_list_name: 'Countries',
                                items: [
                                  {
                                    item_id: item.id,
                                    item_name: item.country,
                                    item_list_id: 'countries',
                                    item_list_name: 'Countries',
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          <img
                            src={getFlag(item.iso)}
                            alt="Country"
                            className={styles.countryIcon}
                          />
                          <div className={styles.wrapper}>
                            <h4 className={styles.name}>{item.country}</h4>
                            <p className={styles.cost}>
                              {t('fromCostPerGB', {
                                cost: item.price.amount,
                                currencySymbol: item.price.symbol,
                              })}
                            </p>
                          </div>
                          <img
                            src={ChevronIcon}
                            alt="Arrow"
                            className={styles.arrowIcon}
                          />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            ) : (
              <span className={styles.noResults}>{t('noResults')}</span>
            )}
          </>
        )}
        {segment === 1 && (
          <ul className={styles.list}>
            {packagesList.map((item) => {
              const count = item.countries.length;

              return (
                <li
                  key={item.package_id}
                  className={styles.item}
                  onClick={() => {
                    history.push({
                      pathname: '/plans',
                      search: qs.stringify({
                        name: item.name,
                        id: item.package_id,
                        type: 'package',
                        partner_id,
                      }),
                    });
                    pushDataLayerEvent({
                      event: 'select_item',
                      ecommerce: {
                        item_list_id: 'regions',
                        item_list_name: 'Regions',
                        items: [
                          {
                            item_id: item.package_id,
                            item_name: item.name,
                            item_list_id: 'regions',
                            item_list_name: 'Regions',
                          },
                        ],
                      },
                    });
                  }}
                >
                  <img
                    src={getPackageIcon(item.package_id)}
                    alt="Package"
                    className={styles.packageIcon}
                  />
                  <div className={styles.wrapper}>
                    <h4 className={styles.name}>{item.name}</h4>
                    <p className={styles.availableCountriesText}>
                      {`${count} ${t('purchase.countries', { count })}`}
                    </p>
                  </div>
                  <img
                    src={ChevronIcon}
                    alt="Arrow"
                    className={styles.arrowIcon}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {segment === 2 && (
          <ul className={styles.list}>
            {globalPackagesList.map((item) => {
              const count = item.countries.length;

              return (
                <li
                  key={item.package_id}
                  className={styles.item}
                  onClick={() => {
                    history.push({
                      pathname: '/plans',
                      search: qs.stringify({
                        name: item.name,
                        id: item.package_id,
                        type: 'global-package',
                        partner_id,
                      }),
                    });
                    pushDataLayerEvent({
                      event: 'select_item',
                      ecommerce: {
                        item_list_id: 'global_packages',
                        item_list_name: 'Global',
                        items: [
                          {
                            item_id: item.package_id,
                            item_name: item.name,
                            item_list_id: 'global_packages',
                            item_list_name: 'Global',
                          },
                        ],
                      },
                    });
                  }}
                >
                  <img
                    src={getPackageIcon(item.package_id)}
                    alt="Package"
                    className={styles.packageIcon}
                  />
                  <div className={styles.wrapper}>
                    <h4 className={styles.name}>{item.name}</h4>
                    <div
                      className={styles.availableCountries}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push({
                          pathname: '/available-countries',
                          search: qs.stringify({
                            id: item.package_id,
                            type: 'global-package',
                            partner_id,
                          }),
                        });
                      }}
                    >
                      <p className={styles.availableCountriesText}>
                        {`${count} ${t('purchase.countries', { count })}`}
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Arrow"
                        className={styles.availableCountriesIcon}
                      />
                    </div>
                  </div>
                  <img
                    src={ChevronIcon}
                    alt="Arrow"
                    className={styles.arrowIcon}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CountriesPage;
