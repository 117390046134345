import React, { useEffect, useRef, useState } from 'react';
import './LanguageSwitcher.scss';
import { useTranslation } from 'react-i18next';
import { getCountriesPackages } from '../../store/thunks/countriesPackagesThunks';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import EnLangIcon from '../../assets/images/flags/gb.png';
import EsLangIcon from '../../assets/images/flags/es.png';
import RuLangIcon from '../../assets/images/flags/ru.png';
import DeLangIcon from '../../assets/images/flags/de.png';
import CzLangIcon from '../../assets/images/flags/cz.png';
import NlLangIcon from '../../assets/images/flags/nl.png';
import FrLangIcon from '../../assets/images/flags/fr.png';
import JpLangIcon from '../../assets/images/flags/jp.png';
import UaLangIcon from '../../assets/images/flags/ua.png';
import KzLangIcon from '../../assets/images/flags/kz.png';
import HrLangIcon from '../../assets/images/flags/hr.png';
import SeLangIcon from '../../assets/images/flags/se.png';
import ExpandMoreIcon from '../../assets/icons/ic_expand_more.svg';
import ExpandLessIcon from '../../assets/icons/ic_expand_less.svg';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import { Api } from '../../api';
import moment from 'moment';

const LANGUAGES = [
  { name: 'English', code: 'en' },
  { name: 'Русский', code: 'ru' },
  { name: 'Español', code: 'es' },
  { name: 'Deutsch', code: 'de' },
  { name: 'Čeština', code: 'cs' },
  { name: 'Nederlands', code: 'nl' },
  { name: 'Français', code: 'fr' },
  { name: '日本語', code: 'ja' },
  { name: 'Українська', code: 'uk' },
  { name: 'Қазақ тілі', code: 'kk' },
  { name: 'Hrvatski', code: 'hr' },
  { name: 'Svenska', code: 'sv' },
];

type LanguageSwitcherProps = {
  alternate?: boolean;
  className?: string;
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  alternate,
  className,
}) => {
  const dispatch = useThunkDispatch();
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const currentLanguage = LANGUAGES.find((i) => i.code === i18n.languages[0]);
  const containerRef = useRef();

  useEffect(() => {
    function handleClick(e: any) {
      if (containerRef && containerRef.current) {
        const ref: any = containerRef.current;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  const changeLanguage = (lang: string) => {
    if (lang !== i18n.languages[0]) {
      i18n.changeLanguage(lang);
      localStorage.setItem('lang', lang);
      moment.locale(lang);
      Api.profile.setLang(lang);
      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.('messenger:set', 'locale', lang);
      dispatch(getCountriesPackages());
    }
  };

  return (
    <div className={'language-switcher-container'} ref={containerRef as any}>
      <div
        className={`language-switcher-input${
          alternate ? ' language-switcher-alternate-input' : ''
        }${className ? ` ${className}` : ''}`}
        onClick={() => setOpen(!isOpen)}
        id="language-switcher"
      >
        <img
          src={
            {
              en: EnLangIcon,
              es: EsLangIcon,
              ru: RuLangIcon,
              de: DeLangIcon,
              cs: CzLangIcon,
              nl: NlLangIcon,
              fr: FrLangIcon,
              ja: JpLangIcon,
              uk: UaLangIcon,
              kk: KzLangIcon,
              hr: HrLangIcon,
              sv: SeLangIcon,
            }[currentLanguage!.code]
          }
          alt="Language"
          className={'language-switcher-lang-icon'}
        />
        <span className={'language-switcher-label'}>
          {alternate
            ? {
                ja: 'JPN',
                kk: 'KAZ',
              }[currentLanguage!.code] || currentLanguage!.name.substring(0, 3)
            : currentLanguage!.name}
        </span>
        <img
          src={isOpen ? ExpandLessIcon : ExpandMoreIcon}
          alt="Arrow"
          className={'language-switcher-arrow-icon'}
        />
        <img
          src={ChevronIcon}
          alt="Icon"
          className={'language-switcher-chevron-icon'}
        />
      </div>
      {isOpen && (
        <ul
          className={`language-switcher-dropdown${
            alternate ? ` language-switcher-alternate-dropdown` : ''
          }`}
        >
          {LANGUAGES.map((i) => (
            <li
              key={i.code}
              onClick={() => changeLanguage(i.code)}
              className={'language-switcher-label'}
            >
              {i.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
