import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import './scss/common.scss';
import './scss/colors.scss';
import './scss/fonts.scss';
import 'moment/min/locales';
import './l10n';
import App from './App';

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename="/marketplace">
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
);
