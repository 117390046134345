import { SnackBarPayload } from '../store/reducers/snackbarReducer/snackbarReducer.types';
import {
  dismissSnack,
  setSnack,
} from '../store/reducers/snackbarReducer/snackbarReducer';
import { useDispatch } from 'react-redux';

export const useSnackbar = () => {
  const dispatch = useDispatch();

  return {
    setSnackbar: (snack: SnackBarPayload) => dispatch(setSnack(snack)),
    dismissSnackbar: () => dispatch(dismissSnack()),
  };
};
