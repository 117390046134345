import axios from 'axios';
import { Pagination } from '../hooks/usePagination';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import i18next from 'i18next';

export const getActiveNumbers = () =>
  axios
    .get('/numbers_active', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getArchiveNumbers = () =>
  axios
    .get('/numbers_archive', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getSenders = ({
  number,
  search,
  pagination,
}: {
  number: string;
  search?: string;
  pagination?: Pagination;
}) =>
  axios
    .get('/sms_sender_list', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        number,
        ...(search ? { search } : {}),
        ...(pagination
          ? { pagination: { limit: pagination.limit, page: pagination.page } }
          : { pagination: { limit: 10, page: 1 } }),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getMessages = (sender: string) =>
  axios
    .get('/sms_by_sender', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        sender,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNumbersCountry = () =>
  axios
    .get('/numbers_country', {
      params: {
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNumbersServices = () =>
  axios
    .get('/numbers_services', {
      params: {
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNumbersList = ({
  search,
  pagination,
  districtId,
}: {
  search?: string;
  pagination?: Pagination;
  districtId?: string;
}) =>
  axios
    .get('/numbers_list', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        id: districtId,
        ...(search ? { search } : {}),
        ...(pagination
          ? { pagination: { limit: pagination.limit, page: pagination.page } }
          : { pagination: { limit: 10, page: 1 } }),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNumbersForService = ({
  search,
  pagination,
  serviceId,
}: {
  search?: string;
  pagination?: Pagination;
  serviceId?: string;
}) =>
  axios
    .get('/numbers_search', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        service_id: serviceId,
        ...(search ? { search } : {}),
        ...(pagination
          ? { pagination: { limit: pagination.limit, page: pagination.page } }
          : { pagination: { limit: 10, page: 1 } }),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNumberInfo = (number: string) =>
  axios
    .get('/number_info', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        number,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const enableNumberSubscription = (number: string) =>
  axios
    .post('/did_subscription_on', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        did_number: number,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const disableNumberSubscription = (number: string) =>
  axios
    .post('/did_subscription_off', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        did_number: number,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const activateNumber = (number: string, planId: string) =>
  axios
    .post('/purchase_number_yc', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        did_number: number,
        plan_id: planId,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const checkPurchaseStatus = (number: string) =>
  axios
    .get('/did_purchase_status', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        did_number: number,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
