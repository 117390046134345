import React from 'react';
import styles from './Tooltip.module.scss';
import TooltipIcon from '../../assets/icons/ic_tooltip.svg';

type TooltipProps = {
  content: string;
  position?: 'top' | 'left' | 'bottom' | 'right';
  size?: 'small' | 'large';
  children?: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = 'bottom',
  size = 'large',
}) => {
  return (
    <div className={styles.container}>
      {children ? (
        children
      ) : (
        <img src={TooltipIcon} alt="Tooltip" className={styles[size]} />
      )}
      <span className={`${styles.content} ${styles[position]}`}>{content}</span>
    </div>
  );
};

export default Tooltip;
