import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import NavBar from '../../components/NavBar';
import SearchInput from '../../components/SearchInput';
import { Country } from '../../globalTypes';
import { RootState } from '../../store';
import getFlag from '../../utils/getFlag';
import getPackageIcon from '../../utils/getPackageIcon';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './AvailableCountriesPage.module.scss';

const AvailableCountriesPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { id } = qs.parse(search) as {
    [key: string]: string;
  };
  const countries = useSelector(
    (state: RootState) => state.countriesPackages.countries,
  );
  const globalPackages = useSelector(
    (state: RootState) => state.countriesPackages.globalPackages,
  );
  const globalPackage = globalPackages.find((i) => i.package_id === id);
  const [filter, setFilter] = useState('');

  const data = useMemo(
    () =>
      countries.filter((country: Country) =>
        globalPackage?.countriesIds?.includes(country.id),
      ),
    [globalPackage?.name],
  );

  const list = useMemo(
    () =>
      data.filter((i) =>
        i.country.toLowerCase().includes(filter.toLowerCase()),
      ),
    [filter, data],
  );

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'available_countries',
        item_list_name: globalPackage?.name,
      },
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar
          title={t('availableCountries')}
          goBack={() => history.push('/global-packages')}
        >
          <SearchInput
            placeholder={t('search')}
            value={filter}
            onChangeText={setFilter}
          />
        </NavBar>
        <ul className={styles.list}>
          {list.map((item, index) => (
            <li key={index} className={styles.item}>
              <img src={getFlag(item.iso)} alt="Flag" className={styles.flag} />
              <div className={styles.wrapper}>
                <span className={styles.name}>{item.country}</span>
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.bottom}>
          <div className={styles.details}>
            <img
              src={getPackageIcon(id)}
              alt="Package"
              className={styles.packageIcon}
            />
            <div className={styles.column}>
              <p className={styles.label}>{t('buyPlan')}</p>
              <p className={styles.packageName}>{globalPackage?.name}</p>
            </div>
          </div>
          <Button
            label={t('learnMore')}
            variant="plain"
            className={styles.button}
            onClick={() => {
              history.push({ pathname: '/plans', search });
              pushDataLayerEvent({
                event: 'select_item',
                ecommerce: {
                  item_list_id: 'global_packages',
                  item_list_name: 'Global',
                  items: [
                    {
                      item_id: id,
                      item_name: globalPackage?.name,
                      item_list_id: 'global_packages',
                      item_list_name: 'Global',
                    },
                  ],
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableCountriesPage;
