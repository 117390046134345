export const SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';

export type Notification = {
  id: string;
  date_add: string;
  title: string;
  message: string;
  received: '0' | '1';
  subscr_user_id: string;
};

export interface SaveNotifications {
  type: typeof SAVE_NOTIFICATIONS;
  payload: Notification[];
}

export type NotificationsActions = SaveNotifications;
