import moment from 'moment';
import React from 'react';
import { Currency } from '../../store/reducers/currenciesReducer/currenciesReducer.types';
import styles from './Invoice.module.scss';
import { useTranslation } from 'react-i18next';
import { DelayedPlan } from '../../globalTypes';
import formatMegabytes from '../../utils/formatMegabytes';
import DateIcon from '../../assets/icons/ic_date.svg';
import OfferIcon from '../../assets/icons/ic_offer.svg';
import getPackageIcon from '../../utils/getPackageIcon';
import getFlag from '../../utils/getFlag';
import Button from '../Button';

type InvoiceProps = {
  planId?: string;
  iso?: string;
  packageId?: string;
  plan?: string;
  totalMb?: string;
  date?: string | Date;
  period?: string;
  referralCost?: string | number;
  total?: string | number;
  planCost?: string | number;
  activateLater?: DelayedPlan | boolean;
  className?: string;
  unlimited?: boolean;
  currency?: Currency;
  price?: {
    summ: string;
    total_summ: string;
    discount: string;
  };
  invoiceAmount?: string | null;
  onCreateInvoice?: () => void;
};

const Invoice: React.FC<InvoiceProps> = ({
  planId,
  iso,
  packageId,
  plan,
  date,
  totalMb,
  period,
  referralCost,
  total,
  planCost,
  activateLater,
  unlimited,
  currency,
  price,
  invoiceAmount,
  onCreateInvoice,
}) => {
  const { t } = useTranslation();

  function formatPrice(price?: number | string) {
    if (!price && price !== 0) return '-';
    return currency?.symbol + Number(price).toFixed(2);
  }

  const discount = String(referralCost).includes('%')
    ? Number(planCost) - Number(total)
    : referralCost;

  const totalMbArr = formatMegabytes(totalMb!).split(' ');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {(planId === '577' || planId === '1787' || planId === '2999') && (
          <img src={OfferIcon} alt="Icon" className={styles.planIcon} />
        )}
        {!!packageId &&
          packageId !== '0' &&
          planId !== '577' &&
          planId !== '1787' &&
          planId !== '2999' && (
            <img
              src={getPackageIcon(packageId)}
              alt="Icon"
              className={styles.planIcon}
            />
          )}
        {!!iso &&
          (!packageId || packageId === '0') &&
          planId !== '577' &&
          planId !== '1787' &&
          planId !== '2999' && (
            <img
              src={getFlag(iso?.toLowerCase())}
              alt="Icon"
              className={styles.flagIcon}
            />
          )}
        <div>
          <h2 className={styles.title}>{t('purchase.plan')}</h2>
          <p className={styles.plan}>{plan}</p>
        </div>
      </div>
      <div className={styles.data}>
        <div className={styles.amount}>
          <p className={styles.value}>{unlimited ? '∞' : totalMbArr[0]}</p>
          <p className={styles.unit}>{totalMbArr[1]}</p>
        </div>
        <div className={styles.line} />
        <div className={styles.amount}>
          <p className={styles.value}>{+period!}</p>
          <p className={styles.unit}>{t('day', { count: +period! })}</p>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.option}>
        <p>{t('purchase.activeLater')}</p>
        <p>{activateLater ? t('yes') : t('no')}</p>
      </div>
      <div className={styles.divider} />
      <div className={styles.cost}>
        <div>
          <p className={styles.paid}>{t('purchase.total')}</p>
          <p className={styles.total}>{price?.summ || formatPrice(total)}</p>
        </div>
        {!!discount && discount > 0 && (
          <p className={styles.discount}>
            {`${t('purchase.referralDiscount')}: ${
              price?.discount || formatPrice(discount)
            }`}
          </p>
        )}
      </div>
      <div className={styles.wrapper}>
        <img src={DateIcon} alt="Date" className={styles.dateIcon} />
        <p className={styles.date}>
          {`${t('purchase.date')}: ${moment(date).format('DD/MM/YYYY')}`}
        </p>
      </div>
      {Boolean(onCreateInvoice) && invoiceAmount && (
        <Button
          label={t('createInvoice')}
          variant={'secondary'}
          size="small"
          onClick={onCreateInvoice}
        />
      )}
    </div>
  );
};

export default Invoice;
