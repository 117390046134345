import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Api } from '../../api';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import { getProfileData } from '../../store/thunks/profileThunks';
import Button from '../Button';
import Loader from '../Loader';
import styles from './AutoTopUpModal.module.scss';
import AutoIcon from '../../assets/icons/ic_auto.svg';
import PlanetIcon from '../../assets/icons/ic_planet.svg';
import SmsIcon from '../../assets/icons/ic_sms.svg';
import ClockIcon from '../../assets/icons/ic_clock.svg';
import CreditCardIcon from '../../assets/icons/ic_credit_card.svg';

type AutoTopUpModalProps = {
  open: boolean;
  onClose: () => void;
  confirmation?: boolean;
  setChecked?: (isEnabled: boolean) => void;
};

const AutoTopUpModal: React.FC<AutoTopUpModalProps> = ({
  open,
  onClose,
  confirmation,
  setChecked,
}) => {
  const { t } = useTranslation();
  const { minAuto, autoTopUpAmount } = useSelector(
    (state: RootState) => state.userProfile.ycoinsTopUpInfo,
  );
  const { setSnackbar } = useSnackbar();
  const dispatch = useThunkDispatch();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const isAutoTopUpEnabled = autoTopUpAmount > 0;

  async function disableAutoTopUp() {
    try {
      setLoading('pending');
      await Api.ycoins.disableCoinsAutoTopUp();
      await dispatch(getProfileData());
      setChecked?.(false);
      onClose();
      setLoading('fulfilled');
      setSnackbar({ message: t('autoTopUpDeactivated'), type: 'success' });
    } catch (e: any) {
      setLoading('rejected');
      setSnackbar({ message: e.message, type: 'error' });
    }
  }

  if (!open) return null;

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.content}>
        {loading === 'pending' ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <h2 className={styles.title}>
              {confirmation ? t('disableAutoTopUpQ') : t('autoTopUp')}
            </h2>
            <p className={styles.text}>
              {t('autoTopUpCoinsDesc', { min: minAuto })}
            </p>
            <h4 className={styles.benefits}>{t('loseBenefits')}</h4>
            <ul className={styles.list}>
              <li className={styles.item}>
                <img src={AutoIcon} alt="Icon" className={styles.itemIcon} />
                <p className={styles.itemText}>{t('neverWorryBalance')}</p>
              </li>
              <li className={styles.item}>
                <img src={PlanetIcon} alt="Icon" className={styles.itemIcon} />
                <p className={styles.itemText}>{t('enjoyHightSpeed')}</p>
              </li>
              <li className={styles.item}>
                <img src={SmsIcon} alt="Icon" className={styles.itemIcon} />
                <p className={styles.itemText}>{t('keepVNreserved')}</p>
              </li>
              <li className={styles.item}>
                <img src={ClockIcon} alt="Icon" className={styles.itemIcon} />
                <p className={styles.itemText}>{t('cancelAnyTime')}</p>
              </li>
              <li className={styles.item}>
                <img
                  src={CreditCardIcon}
                  alt="Icon"
                  className={styles.itemIcon}
                />
                <p className={styles.itemText}>{t('noExtraCost')}</p>
              </li>
            </ul>
            <div className={styles.actions}>
              {confirmation ? (
                <>
                  <Button
                    label={t('disableAutoTopUp')}
                    onClick={() => {
                      if (isAutoTopUpEnabled) {
                        disableAutoTopUp();
                      } else {
                        setChecked?.(false);
                        onClose();
                      }
                    }}
                    variant="secondary"
                  />
                  <Button
                    label={t('keepIt')}
                    onClick={onClose}
                    variant="plain"
                  />
                </>
              ) : (
                <Button label={t('great')} onClick={onClose} variant="plain" />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AutoTopUpModal;
