export const types = {
  SAVE_TOKEN: 'token/SAVE_TOKEN',
};

export interface Token {
  sessionId: string;
  willExpireDate?: string;
  firstAuth?: true;
  email?: string;
}

export interface SaveToken {
  type: typeof types.SAVE_TOKEN;
  payload: Token;
}

export type TokenActions = SaveToken;
