import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import i18next from 'i18next';

export const setLang = async (lang: string) =>
  await axios
    .post('/set-lang', null, {
      params: {
        lang,
        auth_key: getAuthKey(),
      },
    })
    .catch(catchApiMessage);

export const updateEmail = async (newEmail: string) =>
  await axios
    .post('/update-email', null, {
      params: {
        newEmail,
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .catch(catchApiMessage);

export const showQrs = (): Promise<[string, string, string, string]> =>
  axios
    .get('/show_my_qrs', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data['Qrs']?.[0])
    .catch(catchApiMessage);

export const setRating = async (rating: number) =>
  await axios
    .post('/set_rating', null, {
      params: {
        rating,
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .catch(catchApiMessage);

export const prolongDelayedPlan = () =>
  axios
    .post('/prolong_delayed_plan', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .catch((error) => {
      return catchApiMessage(error);
    });

export const setPartnerId = ({
  partnerId,
  isRetargeting,
  additional,
}: {
  partnerId?: string;
  isRetargeting?: string;
  additional?: { [key: string]: any };
}) =>
  axios
    .post('/set_partner', null, {
      params: {
        auth_key: getAuthKey(),
        partner_id: partnerId,
        is_retargeting: isRetargeting,
        lang: i18next.languages[0],
        additional: additional,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const activatePayGoPlan = () =>
  axios
    .get('/activate_pay_as_you_go', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const deactivatePayGoPlan = () =>
  axios
    .get('/deactivate_pay_as_you_go', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const requestDeletionAccount = () =>
  axios
    .post('/delete_account', null, {
      params: {
        auth_key: getAuthKey(),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
