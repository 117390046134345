import React, { useState } from 'react';
import styles from './SearchInput.module.scss';
import SearchIcon from '../../assets/icons/ic_search.svg';
import CloseIcon from '../../assets/icons/ic_close.svg';
import { useTranslation } from 'react-i18next';

type SearchInputProps = {
  value: string;
  onChangeText: (value: string) => void;
  placeholder?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChangeText,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [isFocused, setFocused] = useState(false);

  return (
    <div
      className={`${styles.container} ${(isFocused || value) && styles.active}`}
    >
      <img src={SearchIcon} alt="Search" className={styles.searchIcon} />
      <input
        placeholder={placeholder || t('purchase.searchDestination')}
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
        className={styles.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {value && (
        <img
          src={CloseIcon}
          alt="Clear"
          className={styles.clearIcon}
          onClick={() => onChangeText('')}
        />
      )}
    </div>
  );
};

export default SearchInput;
