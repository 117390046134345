import React from 'react';
import formatPrice from '../../utils/formatPrice';
import styles from './Plans.module.scss';
import { Plan } from 'src/globalTypes';
import formatMegabytes from '../../utils/formatMegabytes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import OperatorIcon from '../../assets/icons/ic_operator.svg';

type PlansProps = {
  plans: Plan[];
  onSelect: (plan: Plan) => void;
  isYcoinsApplied: boolean;
  selectedPlanId?: string | null;
  operators: { name: string }[];
};

const Plans: React.FC<PlansProps> = ({
  onSelect,
  plans,
  isYcoinsApplied,
  selectedPlanId,
  operators,
}) => {
  const { t } = useTranslation();
  const { ycoins, paymentCurrency, countOfPurchases } = useSelector(
    (state: RootState) => state.userProfile,
  );
  const rate = +paymentCurrency.rate;
  const firstPaymentAmount = rate * 0.5;

  return (
    <div className={styles.container}>
      {plans.map((item, index) => {
        const cost = +item!.price.amount;
        const totalCost = isYcoinsApplied
          ? (cost / rate -
              ((cost / rate) * 100 > ycoins ? ycoins : (cost / rate) * 100) /
                100) *
            rate
          : cost;

        return (
          <div
            key={index}
            className={`${styles.plan} ${
              item.planId === selectedPlanId && styles.active
            }`}
            onClick={() => {
              onSelect(item);
            }}
          >
            <div
              className={`${styles.content} ${
                item.planId === selectedPlanId && styles.active
              }`}
            >
              <div className={styles.wrapper}>
                <p className={styles.data}>{formatMegabytes(item.totalMb)}</p>
                <p className={styles.period}>
                  {t('day', {
                    days: +item.validityPeriod,
                    count: +item.validityPeriod,
                  })}
                </p>
              </div>
              <p className={styles.price}>
                {`${item.price.symbol}${
                  countOfPurchases === 0 && +totalCost === 0
                    ? firstPaymentAmount
                    : formatPrice(totalCost)
                }`}
              </p>
              <div className={styles.radio} />
            </div>
            {operators.length == 1 && (
              <div className={styles.operator}>
                <img
                  src={OperatorIcon}
                  alt="Operator"
                  className={styles.operatorIcon}
                />
                <p className={styles.operatorText}>
                  {t('operatorOnly', { operator: operators[0].name })}
                </p>
                <div className={styles.label}>
                  <p className={styles.labelText}>{'3G'}</p>
                </div>
                <div className={styles.label}>
                  <p className={styles.labelText}>{'4G'}</p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Plans;
