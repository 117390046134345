import { LOG_OUT } from '../sharedActionTypes';
import { types, TokenActions, Token } from './tokenReducer.types';

const init: Token = {
  sessionId:
    'f13d7f22-c6c9-42a0-92d1-1f0901f67c37-c79b688e-189a-4584-a4cf-380e014d927b',
  willExpireDate: '',
};

export default function (state = init, action: TokenActions) {
  switch (action.type) {
    case types.SAVE_TOKEN:
      return {
        ...action.payload,
      };
    case LOG_OUT:
      return init;
    default:
      return state;
  }
}

export const actions = {
  saveToken: (token: Token): TokenActions => ({
    type: types.SAVE_TOKEN,
    payload: token,
  }),
};
