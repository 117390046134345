import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/numbersServicesReducer/numbersServicesReducer';

export const getNumbersServices = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.numbers.getNumbersServices();
    dispatch(actions.saveNumbersServices(res[0]));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
