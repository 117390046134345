import React, { useState } from 'react';
import styles from './SettingsPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import InputBase from 'src/components/InputBase';
import Modal from 'src/components/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { requestDeletionAccount } from 'src/api/profile';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useMountedState } from 'src/hooks/useMountedState';
import CopyIcon from 'src/assets/icons/ic_copy.svg';
import DeleteAccountImage from 'src/assets/images/delete_account.svg';
import { logOut } from 'src/store/thunks/authThunks';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.userProfile);
  const [isOpenDeleteAccount, setOpenDeleteAccount] = useState(false);
  const { setSnackbar } = useSnackbar();
  const isMount = useMountedState();
  const [loading, setLoading] = useLoadingStatus('unknown');

  const onCopyUserID = () => {
    navigator.clipboard.writeText(profile.id);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  async function deleteAccount() {
    try {
      setOpenDeleteAccount(false);
      setLoading('pending');
      await requestDeletionAccount();
      setSnackbar({ message: t('success'), type: 'success' });
      if (!isMount()) return;
      dispatch(logOut());
    } catch (e: any) {
      setLoading('rejected');
      setSnackbar({ message: e.message, type: 'error' });
    }
  }

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2>{t('accountSettings')}</h2>
      <div className={styles.form}>
        <InputBase value={profile.email} disabled />
      </div>
      <div className={styles.userID} onClick={onCopyUserID} title={t('copy')}>
        <h4>{`Yesim User ID:  ${profile.id}`}</h4>
        <img src={CopyIcon} alt="Copy" className={styles.copyIcon} />
      </div>
      <button
        className={styles.deleteAccountButton}
        onClick={() => setOpenDeleteAccount(true)}
      >
        {t('deleteAccount')}
      </button>

      <Modal open={isOpenDeleteAccount}>
        <div className={styles.deleteAccount}>
          <img
            src={DeleteAccountImage}
            alt="Delete Account"
            className={styles.image}
          />
          <h1 className={styles.title}>{t('askDeleteAccount')}</h1>
          <p className={styles.description}>{t('deleteAccountDesc')}</p>
          <Button
            label={t('deleteAccount')}
            onClick={deleteAccount}
            className={styles.confirmButton}
          />
          <Button
            label={t('back')}
            onClick={() => setOpenDeleteAccount(false)}
            className={styles.cancelButton}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SettingsPage;
