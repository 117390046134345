import React from 'react';
import BadgeImage from '../../assets/images/app_store_badge.svg';
import BadgeAlternateImage from '../../assets/images/app_store_badge_alternate.svg';

type AppStoreBadgeProps = {
  alternate?: boolean;
  link?: string;
  className?: string;
};

const AppStoreBadge: React.FC<AppStoreBadgeProps> = ({
  alternate,
  link,
  className,
}) => {
  return (
    <a
      href={
        link ||
        'https://apps.apple.com/us/app/yesim-esim-internet-app-vpn/id1458505230'
      }
      target="_blank"
      rel="noreferrer"
      id="app-store-badge"
    >
      <img
        src={alternate ? BadgeAlternateImage : BadgeImage}
        alt="App Store"
        className={className}
      />
    </a>
  );
};

export default AppStoreBadge;
