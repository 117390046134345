import React from 'react';
import styles from './Instructions.module.scss';
import AppStoreBadge from '../AppStoreBadge';
import GooglePlayBadge from '../GooglePlayBadge';
import { Trans, useTranslation } from 'react-i18next';
import { isIOS, isAndroid } from 'src/utils/detectDevice';

type InstructionsProps = {
  title: string;
};

const Instructions: React.FC<InstructionsProps> = ({ title }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <div className={styles.content}>
        <p>
          <Trans
            i18nKey="howToUseStep1"
            components={{
              guide: (
                <a
                  href="https://yesimsupport.zendesk.com/hc/sections/360001787657"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
        <p>{t('howToUseStep2')}</p>
      </div>
      <div className={styles.badges}>
        {(isIOS || !isAndroid) && <AppStoreBadge />}
        {(isAndroid || !isIOS) && <GooglePlayBadge />}
      </div>
      <p>
        <Trans
          i18nKey="visitOurHelpCenter"
          components={{
            help: (
              <a
                href={`https://yesimsupport.zendesk.com/hc/${
                  i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
                }`}
                target="_blank"
                rel="noreferrer"
              />
            ),
            contact: (
              <span
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.zE('messenger', 'show');
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.zE('messenger', 'open');
                }}
              />
            ),
          }}
        />
      </p>
    </div>
  );
};

export default Instructions;
