import { AxiosError } from 'axios';
import i18n from 'i18next';

function catchApiMessage(error: AxiosError): Promise<AxiosError> {
  try {
    error.message =
      (typeof error.response?.data === 'string'
        ? error.response?.data
        : error.response?.data?.[0]) ||
      error.response?.data?.message ||
      error.message ||
      i18n.t('somethingWentWrong');
    return Promise.reject(error);
  } catch (error: any) {
    return Promise.reject(
      new Error(i18n.t('somethingWentWrong') + ' Error: 000'),
    );
  }
}

export { catchApiMessage };
