import React, { useEffect, useState } from 'react';
import formatMegabytes from '../../utils/formatMegabytes';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './PaymentSuccessPage.module.scss';
import Instructions from '../../components/Instructions';
import SuccessIcon from '../../assets/icons/ic_success.svg';
import Invoice from '../../components/Invoice';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Button from '../../components/Button';
import YcoinsIcon from '../../assets/images/ycoins.svg';
import { usePayGoActivation } from '../../hooks/usePayGoActivation';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import Checkbox from '../../components/Checkbox';
import { ProfileThunks } from '../../store/thunks';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import AppStoreBadge from '../../components/AppStoreBadge';
import GooglePlayBadge from '../../components/GooglePlayBadge';
import { isAndroid, isIOS } from '../../utils/detectDevice';
import AutoTopUpIcon from '../../assets/icons/ic_auto_top_up_white.svg';
import YcoinsWhiteIcon from '../../assets/icons/ic_ycoins_white.svg';

const PaymentSuccessPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const {
    ycoins,
    bonusAmount,
    planCost,
    totalPlanCost,
    delayed,
    country,
    totalMb,
    period,
    type,
    payAsYouGo,
    buyNumber,
    planId,
    packageId,
    iso,
    autoTopUp,
    currency,
    payment_id,
    category,
    variant,
  } = qs.parse(search) as { [key: string]: string };
  const dispatch = useThunkDispatch();
  const profile = useSelector((state: RootState) => state.userProfile);
  const currencies = useSelector((state: RootState) => state.currencies);
  const paymentCurrency = currencies.find(
    (i) => i.code === currency?.toUpperCase(),
  );
  const [loading, setLoading] = useLoadingStatus('pending');
  const { activatePayGo } = usePayGoActivation(setLoading);
  const [agree, setAgree] = useState(false);
  const NOT_ENOUGH_YCOINS = profile.ycoins < 100;
  const [firstStep, setFirstStep] = useState(true);
  const amount = `+ ${Number(ycoins) + Number(bonusAmount)}`;

  useEffect(() => {
    dispatch(ProfileThunks.getProfileData()).then(() =>
      setLoading('fulfilled'),
    );
  }, []);

  useEffect(() => {
    if (type === 'ycoins') {
      pushDataLayerEvent({
        event: 'purchase',
        ecommerce: {
          transaction_id: payment_id,
          user_id: profile.id,
          currency,
          value: (
            (Number(ycoins) * Number(paymentCurrency?.rate)) /
            100
          ).toFixed(2),
          items: [
            {
              item_id: 'ycoins',
              item_category: 'Ycoins',
              item_name: 'Ycoins',
              price: (
                (Number(ycoins) * Number(paymentCurrency?.rate)) /
                100
              ).toFixed(2),
              currency,
              item_category2: 'WEB',
              quantity: 1,
            },
          ],
        },
      });
    } else {
      if (totalPlanCost !== '0.00') {
        pushDataLayerEvent({
          event: 'purchase',
          ecommerce: {
            transaction_id: payment_id,
            user_id: profile.id,
            currency,
            value: totalPlanCost,
            items: [
              {
                item_id: planId || packageId,
                location_id: iso || packageId,
                item_name: `${country}_${formatMegabytes(totalMb).replace(
                  / /g,
                  '',
                )}_${period}days`,
                discount: bonusAmount,
                price: planCost,
                currency,
                item_category: category,
                item_category2: 'WEB',
                item_variant: variant,
                quantity: 1,
              },
            ],
          },
        });
      }
    }
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  if (autoTopUp === 'true' && firstStep) {
    return (
      <div className={styles.autoTopUp}>
        <div className={styles.content}>
          <img
            src={AutoTopUpIcon}
            alt="Auto top-up"
            className={styles.autoTopUpIcon}
          />
          <h2 className={styles.title}>{t('autoTopUpActivated')}</h2>
          <div className={styles.wrapper}>
            <p className={styles.amount}>{amount}</p>
            <img
              src={YcoinsWhiteIcon}
              alt="Ycoins"
              className={styles.ycoinsIcon}
            />
          </div>
        </div>
        <Button
          label={t('continue')}
          variant="plain"
          className={styles.button}
          onClick={() => setFirstStep(false)}
        />
      </div>
    );
  } else if (type === 'ycoins') {
    return (
      <div className={styles.container}>
        <div className={styles.ycoins}>
          <img src={SuccessIcon} alt="Success" className={styles.successIcon} />
          <div className={styles.amount}>
            <span className={styles.value}>{amount}</span>
            <img src={YcoinsIcon} alt="Ycoins" className={styles.ycoinsIcon} />
          </div>
          <p className={styles.balance}>{`${t('yourBalance')}: ${
            profile.ycoins
          } Ycoins`}</p>
          {(payAsYouGo && !profile.payGoPlan.active) || buyNumber ? (
            NOT_ENOUGH_YCOINS || buyNumber ? (
              <Button
                label={t('continue')}
                variant="plain"
                size="small"
                onClick={() =>
                  buyNumber
                    ? history.replace({ pathname: '/buy-number', search })
                    : history.replace('/international-esim')
                }
              />
            ) : (
              <Button
                label={`${t('activate')} ${t('internationalEsimPlan')}`}
                variant="plain"
                size="small"
                onClick={activatePayGo}
                disabled={profile.classicPlan.active && !agree}
              />
            )
          ) : (
            <Button
              label={t('back')}
              variant="secondary"
              size="small"
              onClick={() => history.replace('/wallet')}
            />
          )}
          {payAsYouGo &&
            profile.classicPlan.active &&
            !profile.payGoPlan.active && (
              <Checkbox
                checked={agree}
                onChange={setAgree}
                label={t('purchase.planWillCanceled')}
                className={styles.checkbox}
              />
            )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.success}>
        <img src={SuccessIcon} alt="Success" className={styles.successIcon} />
        <h2>{t('thankPurchase')}</h2>
      </div>
      <p className={styles.info}>{t('weHaveSentTheReceipt')}</p>
      <div className={styles.downloadApp}>
        <p className={styles.text}>
          {t('downloadAppForQuickEsimInstallation')}
        </p>
        <div className={styles.badges}>
          {(isIOS || !isAndroid) && (
            <AppStoreBadge
              alternate
              link="https://yesim.onelink.me/W9Wz/100386?af_android_url=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230"
            />
          )}
          {(isAndroid || !isIOS) && (
            <GooglePlayBadge
              alternate
              link="https://yesim.onelink.me/W9Wz/100386?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile"
            />
          )}
        </div>
      </div>
      <Invoice
        planId={planId}
        iso={iso}
        packageId={packageId}
        plan={country}
        totalMb={totalMb}
        period={period}
        total={totalPlanCost}
        referralCost={(Number(ycoins) * Number(paymentCurrency?.rate)) / 100}
        planCost={planCost}
        date={new Date()}
        activateLater={delayed === '1'}
        currency={paymentCurrency}
      />
      {profile.esimInstalledInfo.installed ? (
        <Button
          label={t('goToMyEsim')}
          variant="secondary"
          onClick={() => history.replace('/')}
        />
      ) : (
        <>
          <Button
            label={t('yesimInstallation')}
            variant="primary"
            onClick={() => history.replace('/installation')}
          />
          <Instructions title={t('readyToInstallYourYesim')} />
        </>
      )}
    </div>
  );
};

export default PaymentSuccessPage;
