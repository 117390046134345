import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Api } from '../../api';
import { checkPurchaseStatus, getNumberInfo } from '../../api/numbers';
import NoPhoneIcon from '../../assets/icons/ic_no_phone.svg';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import YcoinsWhiteIcon from '../../assets/icons/ic_ycoins_white.svg';
import Agreements from '../../components/Agreements';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import PaymentMethodModal from '../../components/PaymentMethodModal';
import RegionRestrictionsPopup from '../../components/RegionRestrictionsPopup';
import SmsBadge from '../../components/SmsBadge';
import { PaymentMethods } from '../../globalTypes';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import { getActiveNumbers } from '../../store/thunks/activeNumbersThunks';
import { getProfileData } from '../../store/thunks/profileThunks';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './BuyNumberPage.module.scss';

type NumberPlan = {
  cost: string;
  days: string;
  discounted: string;
  id: string;
  title: string;
  price: {
    amount: string;
    currency: string;
    iso2: string;
    iso3: string;
    symbol: string;
  };
};

const BuyNumberPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const {
    number,
    code,
    country,
    district,
    services,
    buyNumberFirstPayment,
    buyNumber,
    numberPlanId,
    numberPlanCost,
    paymentFail,
    payment_id,
  } = qs.parse(search) as {
    [key: string]: any;
  };
  const dispatch = useThunkDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useLoadingStatus('pending');
  const [selectedPlan, setSelectedPlan] = React.useState<NumberPlan>();
  const [plans, setPlans] = useState<NumberPlan[]>([]);
  const profile = useSelector((state: RootState) => state.userProfile);
  const NOT_ENOUGH_YCOINS = profile.ycoins < (selectedPlan?.cost || 500);
  const FIRST_PAYMENT = profile.countOfPurchases === 0;
  const [isOpenPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [isOpenRegionRestrictions, setOpenRegionRestrictions] = useState(false);
  const firstPaymentAmount = Number(profile.paymentCurrency.rate) * 0.5;
  const isSecurePayment = !NOT_ENOUGH_YCOINS && FIRST_PAYMENT;

  function goToPayment(paymentMethod: PaymentMethods, cardIso?: string) {
    setLoading('pending');
    history.push({
      pathname: '/payment',
      search: qs.stringify({
        buyNumberFirstPayment: true,
        number,
        code,
        numberPlanId: selectedPlan?.id,
        numberPlanCost: selectedPlan?.cost,
        prev: window.location.href,
        paymentMethod,
        cardIso,
      }),
    });

    if (FIRST_PAYMENT) {
      pushDataLayerEvent({
        event: 'begin_checkout',
        ecommerce: {
          currency: selectedPlan?.price.currency,
          value: firstPaymentAmount,
          items: [
            {
              item_id: 'secure_payment',
              item_category: 'Numbers',
              item_name: 'Secure payment',
              price: firstPaymentAmount,
              currency: selectedPlan?.price.currency,
            },
          ],
        },
      });
    }
  }

  async function checkStatus() {
    const res = await checkPurchaseStatus(number);

    if (res?.[0] === 'success') {
      await dispatch(getProfileData());
      await dispatch(getActiveNumbers());
      history.replace({
        pathname: '/number-activated',
        search: qs.stringify({ number, code }),
      });
    }

    if (res?.[0] === 'not_available') {
      await dispatch(getProfileData());
      setSnackbar({ message: t('numberSold'), type: 'error' });
    }

    if (res?.[0] === 'unknown') {
      setTimeout(() => {
        checkStatus();
      }, 5000);
    }
  }

  async function activateNumber() {
    try {
      setLoading('pending');
      const res = await Api.numbers.activateNumber(
        number,
        selectedPlan?.id || numberPlanId,
      );
      if (res === 'OK') {
        checkStatus();
      } else {
        history.replace('/numbers');
      }
    } catch (e: any) {
      setSnackbar({ message: e?.message || t('numberSold'), type: 'error' });
      history.replace('/numbers');
    }
  }

  function onTopUp() {
    history.push({
      pathname: '/ycoins',
      search: qs.stringify({
        buyNumber: true,
        number,
        code,
        country,
        district,
        numberPlanId: selectedPlan?.id,
        numberPlanCost: selectedPlan?.cost,
        ...(NOT_ENOUGH_YCOINS ? { notEnoughYcoins: true } : {}),
      }),
    });
  }

  function onPay() {
    if (!profile.did_show) {
      setOpenRegionRestrictions(true);
    } else if (NOT_ENOUGH_YCOINS) {
      onTopUp();
    } else if (FIRST_PAYMENT) {
      setOpenPaymentMethod(true);
    } else {
      activateNumber();
    }
  }

  useEffect(() => {
    async function init() {
      try {
        const res = await getNumberInfo(number);
        const data = res?.['list'];
        if (Array.isArray(data)) {
          setPlans(data);
          setSelectedPlan(data[0]);
          setLoading('fulfilled');
        }
      } catch (e: any) {
        setSnackbar({ message: e?.message || t('numberSold'), type: 'error' });
        history.replace('/numbers');
      }
    }

    if (buyNumberFirstPayment) {
      pushDataLayerEvent({
        event: 'purchase',
        ecommerce: {
          transaction_id: payment_id,
          user_id: profile.id,
          currency: selectedPlan?.price.currency,
          value: firstPaymentAmount,
          items: [
            {
              item_id: 'secure_payment',
              item_category: 'Numbers',
              item_name: 'Secure payment',
              price: firstPaymentAmount,
              currency: selectedPlan?.price.currency,
              item_category2: 'WEB',
              quantity: 1,
            },
          ],
        },
      });
    }

    if (
      (buyNumberFirstPayment || buyNumber) &&
      profile.ycoins >= numberPlanCost
    ) {
      activateNumber();
    } else {
      init();
    }

    if (paymentFail) {
      setSnackbar({
        message: t('somethingWentWrong'),
        type: 'error',
      });
    }
  }, [profile.paymentCurrency.code]);

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: number,
            item_name: formatPhoneNumber(number, code),
          },
        ],
      },
    });
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar
          title={t('buyNumber')}
          goBack={() => history.push('/numbers')}
        />
        <div className={styles.top}>
          <p className={styles.country}>{`${country}, ${district}`}</p>
          <h2 className={styles.number}>{formatPhoneNumber(number, code)}</h2>
          <div className={styles.services}>
            <SmsBadge />
            {services?.split(',').map((item: string, index: string) => (
              <img
                key={index}
                src={item}
                alt="Service"
                className={styles.serviceIcon}
              />
            ))}
          </div>
        </div>
        <ul className={styles.plans}>
          {plans.map((item, index) => (
            <li
              key={index}
              className={`${styles.item}${
                selectedPlan!.id === item.id ? ` ${styles.selected}` : ''
              }`}
              onClick={() => setSelectedPlan(item)}
            >
              {item.discounted !== '0' && (
                <div className={styles.badge}>
                  <p className={styles.text}>
                    {t('discount', { discount: item.discounted })}
                  </p>
                </div>
              )}
              <h2 className={styles.title}>{item.title}</h2>
              <p
                className={styles.price}
              >{`${item.price.amount}${item.price.symbol}`}</p>
              <div className={styles.ycoins}>
                <p className={styles.ycoinsAmount}>{item.cost}</p>
                <img
                  src={
                    selectedPlan!.id === item.id ? YcoinsWhiteIcon : YcoinsIcon
                  }
                  className={styles.ycoinsIcon}
                  alt="Ycoins"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.note}>
          <img src={NoPhoneIcon} alt="Keypad" className={styles.noteIcon} />
          <p className={styles.noteText}>{t('smsOnly')}</p>
        </div>
        {FIRST_PAYMENT && (
          <div className={styles.agreements}>
            <Agreements buttonText={t('toPay')} />
          </div>
        )}
        <div className={styles.bottom}>
          <p className={styles.bottomText}>
            {NOT_ENOUGH_YCOINS
              ? t('insufficient_balance_ycoins')
              : t('coinsPayment')}
          </p>
          <div className={styles.actions}>
            <div className={styles.wallet}>
              <img
                src={YcoinsIcon}
                alt="Ycoins"
                className={styles.balanceIcon}
              />
              <div className={styles.balance}>
                <h2 className={styles.balanceTitle}>{t('yourBalance')}</h2>
                <p className={styles.balanceAmount}>
                  {`${profile.ycoins} Ycoins`}
                </p>
              </div>
              <Button
                label={t('main.topup')}
                className={styles.topUp}
                size="small"
                onClick={onTopUp}
                variant={NOT_ENOUGH_YCOINS ? 'plain' : 'default'}
              />
            </div>
            <Button
              label={
                isSecurePayment
                  ? `${t('toPay')} ${
                      selectedPlan?.price?.symbol
                    }${firstPaymentAmount.toFixed(2)} ${
                      selectedPlan?.price?.currency
                    }`
                  : t('activate')
              }
              variant="plain"
              onClick={onPay}
            />
            {isSecurePayment && (
              <p className={styles.firstPayment}>{t('firstPayment')}</p>
            )}
          </div>
        </div>
      </div>
      <PaymentMethodModal
        open={isOpenPaymentMethod}
        onClose={() => setOpenPaymentMethod(false)}
        goToPayment={goToPayment}
        isSecurePayment={isSecurePayment}
      />
      <RegionRestrictionsPopup
        open={isOpenRegionRestrictions}
        onClose={() => setOpenRegionRestrictions(false)}
      />
    </div>
  );
};

export default BuyNumberPage;
