import React, { useEffect } from 'react';
import styles from './Snackbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { dismissSnack } from '../../store/reducers/snackbarReducer/snackbarReducer';
import SuccessIcon from '../../assets/icons/ic_success_white.svg';
import CloseIcon from '../../assets/icons/ic_close_white.svg';

const Snackbar: React.FC = () => {
  const dispatch = useDispatch();
  const { display, message, type } = useSelector(
    (state: RootState) => state.snackbar,
  );

  useEffect(() => {
    if (display) {
      setTimeout(() => dispatch(dismissSnack()), 2750);
    }
  }, [display]);

  if (!display) return null;

  return (
    <div className={`${styles.container} ${type === 'error' && styles.error}`}>
      {type === 'success' && (
        <img src={SuccessIcon} alt="Success" className={styles.successIcon} />
      )}
      <p className={styles.message}>{message}</p>
      <img
        src={CloseIcon}
        alt="Close"
        className={styles.closeIcon}
        onClick={() => dispatch(dismissSnack())}
      />
    </div>
  );
};

export default Snackbar;
