import React from 'react';
import styles from './Checkbox.module.scss';
import CheckedIcon from '../../assets/icons/ic_checked.svg';
import UncheckedIcon from '../../assets/icons/ic_unchecked.svg';
import CheckedCircleIcon from '../../assets/icons/ic_checked_circle.svg';
import UncheckedCircleIcon from '../../assets/icons/ic_unchecked_circle.svg';

type CheckboxProps = {
  checked: boolean;
  onChange: (p: boolean) => void;
  label?: string;
  className?: string;
  variant?: 'default' | 'circle';
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  className,
  variant = 'default',
}) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      onClick={() => onChange(!checked)}
    >
      {variant === 'circle' ? (
        <img
          src={checked ? CheckedCircleIcon : UncheckedCircleIcon}
          alt="Checkbox"
          className={styles.circleIcon}
        />
      ) : (
        <img
          src={checked ? CheckedIcon : UncheckedIcon}
          alt="Checkbox"
          className={styles.icon}
        />
      )}
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export default Checkbox;
