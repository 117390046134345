export const SAVE_NUMBERS_SERVICES = 'SAVE_NUMBERS_SERVICES';

export type Service = {
  icon: string;
  id: string;
  name: string;
};

export interface SaveNumbersServices {
  type: typeof SAVE_NUMBERS_SERVICES;
  payload: Service[];
}

export type NumbersServicesActions = SaveNumbersServices;
