export type LoadingStatus = 'unknown' | 'pending' | 'fulfilled' | 'rejected';

export type PackageOrCountry = 'package' | 'country';

export type Throttling = {
  limit: string;
  limit_unit: string;
  speed: string;
  speed_unit: string;
};

export interface Plan {
  flag: string;
  planCost: string;
  planId: string;
  planName: string;
  planNameTranslate: string;
  totalMb: string;
  validityPeriod: string;
  countries: string[];
  refCodeCost: number | string;
  oldCost: string;
  packageId: string;
  iso: string;
  big_plan: boolean;
  unlimited: boolean;
  ts?: string;
  tdl?: string;
  throttling?: Throttling[];
  price: {
    amount: string;
    currency: string;
    iso2: string;
    iso3: string;
    symbol: string;
  };
}

export interface Country {
  id: string;
  iso: string;
  country: string;
  search: string[];
  popular: string;
  cost_per_gb: string;
  price: {
    amount: string;
    currency: string;
    iso2: string;
    iso3: string;
    symbol: string;
  };
}

export interface CountryInfo {
  iso: string;
  name: string;
  cost?: string | null;
  coins?: string | null;
}

export interface Package {
  package_id: string;
  countries: string[];
  countriesIds: string[];
  name: string;
  global: boolean;
}

export interface CountryPrice {
  country: string;
  iso: string;
  coins: string;
  cost: string;
  search: string[];
  price: {
    amount: string;
    currency: string;
    iso2: string;
    iso3: string;
    symbol: string;
  };
}

export type DelayedPlan = 1 | 0; // 1 if delayed
export type TopupPlan = 1 | 0; //1 if topup
export type RecurringType = 1 | 0; //1 if auto renew

export interface HistoryInvoice {
  order_id: string;
  planId: string;
  name: string;
  date_add: string;
  data: string;
  days: string;
  summ: string;
  total_summ: string;
  package_id?: string;
  iso?: string;
  discount: string;
  delayed: '0' | '1';
  recurring: '0' | '1';
  unlimited?: boolean;
  price: {
    summ: string;
    total_summ: string;
    discount: string;
  };
  sum_currency: string | null;
  sum_amount: string | null;
  payment_id: string | null;
}

interface ProfilePlan {
  totalMb: string;
  planId: string;
  countries: string;
  validityPeriod: string;
  planCost: string;
  iso: string;
  country: string;
}

export interface ProfileResponse {
  email: string;
  willDeleted: number | boolean;
  userId: string;
  firstname: string;
  activePlans: {
    planId: string | null;
    plan: null | [ProfilePlan]; //array with one itam, see below
    period: null | string;
  };
  dataBalance: 0;
  activationDate: null;
  leftPercent: {
    data: number;
    days: number;
  };
  usedDays: number;
  expiresIn: string;
  refferal: string;
  welcomeRef: 0 | 1; // 0 - if not enter refferal code, 1 - if used it
  oldVersion: boolean;
  rating: 1 | 0;
  qrAssigned: boolean; // true | false for resendEsimProfile
  delayedPlan: false | string;
  delayedPlanInfo: string | 0;
  oldProdile?: boolean | 0 | 1;
  countOfPurchases: number;
  count_purchases: string;
  temporary: 0 | 1;
  postpone: { of: number; available: number };
  recurring: number;
  ycoins: number;
  ycoins_step: number;
  payAsYouGo: {
    amountMb: string | null;
    active: boolean;
    wasActive: boolean;
  };
  frendsAttracted: string;
  promo: {
    bonusAmount: string;
    bonusAvailable: boolean;
    bonusFrom: string;
  };
}

export type SuccessPaymentInvoice = {
  planCostTotal: number;
  additional: TopupPlan;
  country: string;
  delayed: DelayedPlan;
  selectedPlan: Plan;
  recurring: RecurringType;
  ycoins: number;
};

export enum PaymentMethods {
  ECP = 'ecp',
  MONETIX = 'monetix',
  BINANCE = 'binance',
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  RAPYD = 'rapyd',
  UNKNOWN = 'unknown',
  MELANGE = 'melange',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
}

export interface PayGoHistoryItem {
  country: string;
  startDate: string;
  endDate: string;
  duration: string;
  dataUsage: string;
}

export type PartnerLinkData = {
  partnerId: string;
  isRetargeting?: string;
  additional?: {
    [key: string]: any;
  };
  partnerCode?: string;
};

export type NetworkOperators = {
  country: string;
  iso: string;
  operators: { name: string }[];
  search: string[];
};

export type CountryItem = {
  country: string;
  iso: string;
  gc: string;
  search: string[];
};
