import axios from 'axios';
import i18next from 'i18next';
import { CountryItem } from '../globalTypes';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';

export const getCountries = (): Promise<CountryItem[]> =>
  axios
    .get('/countries', {
      params: {
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data[0])
    .catch(catchApiMessage);

export const sendUtmLink = async (utm_link: string) =>
  await axios.post('/utm_crawler', null, {
    params: {
      auth_key: getAuthKey(),
      utm_link,
    },
  });

export const getInvoiceId = (payment_id: string): Promise<string> =>
  axios
    .get('/get_invoice_id', {
      params: {
        auth_key: getAuthKey(),
        payment_id,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
