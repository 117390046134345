import {
  Country,
  NumbersCountriesActions,
  SAVE_NUMBERS_COUNTRIES,
} from './numbersCountriesReducer.types';

export default function (
  state: Country[] = [],
  action: NumbersCountriesActions,
) {
  switch (action.type) {
    case SAVE_NUMBERS_COUNTRIES:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveNumbersCountries: (payload: Country[]): NumbersCountriesActions => ({
    type: SAVE_NUMBERS_COUNTRIES,
    payload,
  }),
};
