import {
  ActiveNumber,
  ActiveNumbersActions,
  SAVE_ACTIVE_NUMBERS,
} from './activeNumbersReducer.types';

export default function (
  state: ActiveNumber[] = [],
  action: ActiveNumbersActions,
) {
  switch (action.type) {
    case SAVE_ACTIVE_NUMBERS:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveActiveNumbers: (payload: ActiveNumber[]): ActiveNumbersActions => ({
    type: SAVE_ACTIVE_NUMBERS,
    payload,
  }),
};
