import {
  CountriesPackagesState,
  RESET_COUNTRIES,
  SAVE_COUNTRIES,
  CountriesPackagesActions,
} from './countriesPackagesReducer.types';

const init: CountriesPackagesState = {
  countries: [],
  packages: [],
  globalPackages: [],
  count: 0,
};

export default function (state = init, action: CountriesPackagesActions) {
  switch (action.type) {
    case SAVE_COUNTRIES:
      return {
        ...action.payload,
      };
    case RESET_COUNTRIES:
      return init;
    default:
      return state;
  }
}

export const actions = {
  saveCountriesPackages: (
    payload: CountriesPackagesState,
  ): CountriesPackagesActions => ({
    type: SAVE_COUNTRIES,
    payload,
  }),
  resetCountriesPackages: (): CountriesPackagesActions => ({
    type: RESET_COUNTRIES,
  }),
};
