import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowIcon from '../../assets/icons/ic_arrow_forward.svg';
import NavBar from '../../components/NavBar';
import SearchInput from '../../components/SearchInput';
import { RootState } from '../../store';
import getFlag from '../../utils/getFlag';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './SelectRegionPage.module.scss';

const SelectRegionPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { country, iso } = qs.parse(search) as { [key: string]: string };
  const countries = useSelector((state: RootState) => state.numbersCountries);
  const districts = countries.find((i) => i.iso === iso)?.districts || [];
  const [filter, setFilter] = useState('');

  const list = useMemo(
    () =>
      districts.filter((i) =>
        `${i.name}${i.code}`.toLowerCase().includes(filter.toLowerCase()),
      ),
    [filter, districts],
  );

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'numbers_districts',
        item_list_name: country,
      },
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar title={country} goBack={() => history.push('/numbers')}>
          <SearchInput
            placeholder={t('search')}
            value={filter}
            onChangeText={setFilter}
          />
        </NavBar>
        <ul className={styles.list}>
          {list.map((item, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() => {
                history.push({
                  pathname: '/select-number',
                  search: qs.stringify({
                    districtId: item.id,
                  }),
                });
              }}
            >
              <img src={getFlag(iso)} alt="Flag" className={styles.flag} />
              <div className={styles.wrapper}>
                <p className={styles.text}>{item.name}</p>
                <p className={styles.code}>{item.code}</p>
                <img src={ArrowIcon} alt="Arrow" className={styles.arrowIcon} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectRegionPage;
