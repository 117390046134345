import React, { useEffect, useMemo, useState } from 'react';
import { getCountries } from '../../api/common';
import SearchIcon from '../../assets/icons/ic_search.svg';
import getFlag from '../../utils/getFlag';
import Button from '../Button';
import Checkbox from '../Checkbox';
import styles from './SelectCountryModal.module.scss';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import CloseIcon from '../../assets/icons/ic_close_circle.svg';
import { CountryItem } from '../../globalTypes';
import Loader from '../Loader';
import CardsIcon from '../../assets/icons/ic_cards.svg';

type SelectCountryModalProps = {
  open: boolean;
  onClose: () => void;
  selectedCountry: string | null;
  onSelectCountry: (iso: string) => void;
};

const SelectCountryModal: React.FC<SelectCountryModalProps> = ({
  open,
  onClose,
  selectedCountry,
  onSelectCountry,
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [loading, setLoading] = useLoadingStatus('pending');
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(selectedCountry);

  useEffect(() => {
    async function init() {
      try {
        setLoading('pending');
        const res = await getCountries();
        setCountries(res);
        setChecked(res?.find((i) => i.gc === '1')?.iso || selectedCountry);
        setLoading('fulfilled');
      } catch (error: any) {
        setLoading('rejected');
      }
    }

    if (open) {
      init();
    }
  }, [open]);

  const list = useMemo(
    () =>
      search
        ? countries.filter((i) =>
            (i.country + i.iso + i.search.join())
              .toLowerCase()
              .includes(search.toLowerCase()),
          )
        : countries,
    [search, countries],
  );

  return (
    <Modal
      open={open}
      modalPresentationMobile
      onClose={() => {
        onClose();
        setSearch('');
        setChecked(selectedCountry);
      }}
    >
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('cardIssueTest')}</h2>
            <img src={CardsIcon} alt="Cards" className={styles.cardsIcon} />
          </div>
          <p className={styles.subtitle}>{t('willLoadCorrectPayment')}</p>
        </div>
        <img
          src={CloseIcon}
          alt="Close"
          className={styles.close}
          onClick={() => {
            onClose();
            setSearch('');
            setChecked(selectedCountry);
          }}
        />
        {loading === 'pending' ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.search}>
              <div className={styles.field}>
                <img src={SearchIcon} alt="icon" className={styles.icon} />
                <input
                  placeholder={t('search')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.input}
                />
              </div>
              {search && (
                <span className={styles.cancel} onClick={() => setSearch('')}>
                  {t('cancel')}
                </span>
              )}
            </div>
            <div className={styles.list}>
              {list.map((item, index) => (
                <div
                  key={index}
                  className={styles.item}
                  onClick={() => setChecked(item.iso)}
                >
                  <img
                    src={getFlag(item.iso.toLowerCase())}
                    alt="Flag"
                    className={styles.flag}
                  />
                  <p className={styles.country}>{item.country}</p>
                  <Checkbox
                    checked={
                      String(checked).toLowerCase() === item.iso.toLowerCase()
                    }
                    onChange={() => setChecked(item.iso)}
                    variant="circle"
                  />
                </div>
              ))}
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.button}
                label={t('done')}
                onClick={() => onSelectCountry(checked!)}
                variant="plain"
                disabled={!checked}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SelectCountryModal;
