import React from 'react';
import styles from './SegmentedControl.module.scss';

type SegmentedControl = {
  options: { label: string; value: number; icon?: string }[];
  value: number;
  setValue: (value: number) => void;
  className?: string;
};

const SegmentedControl: React.FC<SegmentedControl> = ({
  options,
  value,
  setValue,
  className,
}) => {
  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      {options.map((item) => (
        <div
          key={item.value}
          className={`${styles.option} ${
            item.value === value && styles.selected
          }`}
          onClick={() => setValue(item.value)}
        >
          {item.label}
          {item.icon && (
            <img src={item.icon} alt="Icon" className={styles.icon} />
          )}
        </div>
      ))}
    </div>
  );
};

export default SegmentedControl;
