import { LoadingStatus } from 'src/globalTypes';

export const SAVE_ESIM_DATA_STATUS = 'esimProfile/SAVE_ESIM_DATA_STATUS';
export const SET_ESIM_PROFILE_LOADING_STATUS =
  'esimProfile/SET_ESIM_PROFILE_LOADING_STATUS';

export interface EsimProfile {
  qrData: string[];
  loadingData: LoadingStatus;
}

export type SaveEsimProfile = {
  payload: EsimProfile;
  type: typeof SAVE_ESIM_DATA_STATUS;
};

export type SetEsimProfileLoadingStatus = {
  payload: LoadingStatus;
  type: typeof SET_ESIM_PROFILE_LOADING_STATUS;
};

export type EsimProfileActions = SaveEsimProfile | SetEsimProfileLoadingStatus;
