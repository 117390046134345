import {
  NumbersServicesActions,
  SAVE_NUMBERS_SERVICES,
  Service,
} from './numbersServicesReducer.types';

export default function (
  state: Service[] = [],
  action: NumbersServicesActions,
) {
  switch (action.type) {
    case SAVE_NUMBERS_SERVICES:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveNumbersServices: (payload: Service[]): NumbersServicesActions => ({
    type: SAVE_NUMBERS_SERVICES,
    payload,
  }),
};
