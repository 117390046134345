import React from 'react';
import styles from './PayAsYouGoActivatedPage.module.scss';
import Image from '../../assets/images/pay_as_you_go_activated.png';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const PayAsYouGoActivatedPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.userProfile);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={Image}
          alt="Pay as You Go Activated"
          className={styles.image}
        />
        <h4 className={styles.title}>{t('internationalEsimPlan')}</h4>
        <h2 className={styles.text}>{t('activated')}</h2>
      </div>
      {!user.esimInstalledInfo.installed ? (
        <Button
          label={t('yesimInstallation')}
          onClick={() => history.push('/installation')}
          variant="plain"
          className={styles.button}
        />
      ) : (
        <Button
          label={t('continue')}
          onClick={() => history.push('/')}
          variant="plain"
          className={styles.button}
        />
      )}
    </div>
  );
};

export default PayAsYouGoActivatedPage;
