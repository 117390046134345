import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/notificationsReducer/notificationsReducer';

export const getNotifications = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.notifications.getNotifications();
    dispatch(actions.saveNotifications(res[0]));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const markNotificationsAsRead = (): AppThunk => async () => {
  try {
    await Api.notifications.markNotificationsAsRead();
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const deleteNotification =
  (notificationId: string): AppThunk =>
  async (dispatch) => {
    try {
      await Api.notifications.deleteNotification(notificationId);
      const res = await Api.notifications.getNotifications();
      dispatch(actions.saveNotifications(res[0]));
      return;
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
