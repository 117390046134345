import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendUtmLink } from './api/common';
import { redeemCode } from './api/ycoins';
import BonusPopup from './components/BonusPopup';
import ExtraBonusPopup from './components/ExtraBonusPopup';
import ReferralPopup from './components/ReferralPopup';
import ZendeskLauncher from './components/ZendeskLauncher';
import AvailableCountriesPage from './pages/AvailableCountriesPage';
import BuyNumberPage from './pages/BuyNumberPage';
import MessagesPage from './pages/MessagesPage';
import NotificationsPage from './pages/NotificationsPage';
import NumberActivatedPage from './pages/NumberActivatedPage';
import NumbersArchivePage from './pages/NumbersArchivePage';
import NumbersPage from './pages/NumbersPage';
import SelectCountryServicePage from './pages/SelectCountryServicePage';
import SelectNumberPage from './pages/SelectNumberPage';
import SelectRegionPage from './pages/SelectRegionPage';
import SendersPage from './pages/SendersPage';
import { getCountriesPackages } from './store/thunks/countriesPackagesThunks';
import { RootState } from './store';
import { checkAuth } from './store/thunks/authThunks';
import useLoadingStatus from './hooks/useLoadingStatus';
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import Snackbar from './components/Snackbar';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import AuthPage from './pages/AuthPage';
import MainPage from './pages/MainPage';
import SettingsPage from './pages/SettingsPage';
import CountriesPage from './pages/CountriesPage';
import PlansPage from './pages/PlansPage';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PurchaseHistoryPage from './pages/PurchaseHistoryPage';
import WalletPage from './pages/WalletPage';
import YcoinsPage from './pages/YcoinsPage';
import PayAsYouGoIntroductionPage from './pages/PayAsYouGoIntroductionPage';
import PayAsYouGoPage from './pages/PayAsYouGoPage';
import CountryPricesPage from './pages/CountryPricesPage';
import PayAsYouGoActivatedPage from './pages/PayAsYouGoActivatedPage';
import InstallationPage from './pages/InstallationPage';
import { checkCurrency, getCurrencies } from './store/thunks/currenciesThunk';
import { getNotifications } from './store/thunks/notificationsThunks';
import { getNumbersCountries } from './store/thunks/numbersCountriesThunks';
import { getNumbersServices } from './store/thunks/numbersServicesThunks';
import * as ProfileThunks from './store/thunks/profileThunks';
import { getProfileData } from './store/thunks/profileThunks';
import { saveLocalPartnerLinkData } from './utils/partnerLinkActions';
import NetworkOperatorsPage from './pages/NetworkOperatorsPage';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthRoute = useRouteMatch(['/login']);
  const isPurchaseRoute = useRouteMatch(['/plans', '/ycoins', '/payment']);
  const profile = useSelector((state: RootState) => state.userProfile);
  const isLoggedIn = profile.id;
  const [loading, setLoading] = useLoadingStatus('pending');
  const { pathname, search } = useLocation();
  const redirectTo = pathname + search;
  const { firstAuth, partner_id, partner_code, screen, promo_code } = qs.parse(
    search,
  ) as { [key: string]: string };
  const [openReferralPopup, setOpenReferralPopup] = useState(false);
  const [openBonusPopup, setOpenBonusPopup] = useState(false);
  const [openExtraBonusPopup, setOpenExtraBonusPopup] = useState(false);
  const EXTRA_BONUS_TIMEOUT = 60000;

  function showExtraBonusPopup() {
    setOpenExtraBonusPopup(true);
    dispatch(getProfileData());
    localStorage.removeItem('extraBonusTimestamp');
  }

  useEffect(() => {
    async function processOneLinkData(isLoggedIn: boolean) {
      if (screen || promo_code) {
        const path = screen?.split('?')[0];
        const parameter = screen?.split('?')[1];
        const key = parameter?.split('=')[0];
        const value = parameter?.split('=')[1];

        if (promo_code || key === 'promo_code') {
          if (isLoggedIn) {
            await redeemCode(promo_code || value);
            await dispatch(ProfileThunks.getProfileData());
          } else {
            localStorage.setItem('promoCode', promo_code || value);
          }
        }

        switch (path) {
          case '/plans_screen':
            history.replace({
              pathname: '/plans',
              search: qs.stringify({
                ...(key === 'package_id'
                  ? { id: value, type: 'package' }
                  : { iso: value, type: 'country' }),
              }),
            });
            break;
          case '/refer_friend':
            history.replace({ pathname: '/wallet' });
            break;
          case '/coins_top_up_screen':
            history.replace({ pathname: '/ycoins' });
            break;
          case '/numbers_providers_screen':
            history.replace({ pathname: '/select-country-service' });
            break;
          case '/directions_list_screen':
            history.replace({ pathname: '/countries' });
            break;
          case '/directions_list_screen/regions':
            history.replace({ pathname: '/regions' });
            break;
          case '/directions_list_screen/global':
            history.replace({ pathname: '/global-packages' });
            break;
          case '/pay_go_screen':
            history.replace({ pathname: '/international-esim' });
            break;
        }
      }
    }

    async function processUtmLink(isLoggedIn: boolean) {
      const link = window.location.href;
      const utmLink = localStorage.getItem('utm_link');

      if (isLoggedIn) {
        if (link.includes('utm_') && !link.includes('utm_medium=organic')) {
          await sendUtmLink(link);

          if (utmLink) {
            localStorage.removeItem('utm_link');
          }
        } else if (utmLink && !utmLink.includes('utm_medium=organic')) {
          await sendUtmLink(utmLink);
          localStorage.removeItem('utm_link');
        }
      } else if (link.includes('utm_')) {
        localStorage.setItem('utm_link', link);
      }
    }

    async function init() {
      try {
        const res = await dispatch(checkAuth());
        await dispatch(checkCurrency());
        await dispatch(getCountriesPackages());
        await dispatch(getNumbersCountries());
        await dispatch(getNumbersServices());
        await dispatch(getCurrencies());
        await processOneLinkData(Boolean(res));
        await processUtmLink(Boolean(res));
        setLoading('fulfilled');
      } catch (error: any) {
        setLoading('rejected');
      }
    }

    init();

    saveLocalPartnerLinkData();
  }, []);

  useEffect(() => {
    if (isLoggedIn && partner_id === '386' && partner_code === 'lndng100') {
      if (firstAuth) {
        setOpenBonusPopup(true);
      } else {
        setOpenReferralPopup(true);
      }
    }
  }, [isLoggedIn, partner_id]);

  useEffect(() => {
    const extraBonusTimestamp = localStorage.getItem('extraBonusTimestamp');

    if (isLoggedIn) {
      dispatch(getNotifications());

      if (extraBonusTimestamp) {
        const timeout = Date.now() - Number(extraBonusTimestamp);

        setTimeout(
          () => {
            showExtraBonusPopup();
          },
          EXTRA_BONUS_TIMEOUT > timeout ? timeout : 0,
        );
      } else if (firstAuth) {
        localStorage.setItem('extraBonusTimestamp', Date.now().toString());
        setTimeout(() => {
          showExtraBonusPopup();
        }, EXTRA_BONUS_TIMEOUT);
      }
    }
  }, [isLoggedIn]);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container">
      <ScrollToTop />
      {!isAuthRoute && <AppHeader />}
      <main>
        <Switch>
          <Route path={['/login']}>
            <AuthPage />
          </Route>
          <Route path={['/countries', '/regions', '/global-packages']}>
            <CountriesPage />
          </Route>
          <Route path="/available-countries">
            <AvailableCountriesPage />
          </Route>
          <Route path="/plans">
            <PlansPage />
          </Route>
          <Route path="/network-operators">
            <NetworkOperatorsPage />
          </Route>
          <Route
            path={[
              '/international-esim-introduction',
              '/pay-as-you-go-introduction',
            ]}
          >
            <PayAsYouGoIntroductionPage />
          </Route>
          {isLoggedIn && (
            <>
              <Route path={['/international-esim', '/pay-as-you-go']}>
                <PayAsYouGoPage />
              </Route>
              <Route path="/payment">
                <PaymentPage />
              </Route>
              <Route path="/payment-success">
                <PaymentSuccessPage />
              </Route>
              <Route path="/history">
                <PurchaseHistoryPage />
              </Route>
              <Route path="/settings">
                <SettingsPage />
              </Route>
              <Route path="/wallet">
                <WalletPage />
              </Route>
              <Route path="/ycoins">
                <YcoinsPage />
              </Route>
              <Route exact path="/">
                <MainPage />
              </Route>
              <Route path="/country-prices">
                <CountryPricesPage />
              </Route>
              <Route
                path={[
                  '/international-esim-activated',
                  '/pay-as-you-go-activated',
                ]}
              >
                <PayAsYouGoActivatedPage />
              </Route>
              <Route path="/installation">
                <InstallationPage />
              </Route>
              <Route path="/numbers">
                <NumbersPage />
              </Route>
              <Route path="/numbers-archive">
                <NumbersArchivePage />
              </Route>
              <Route path="/senders">
                <SendersPage />
              </Route>
              <Route path="/messages">
                <MessagesPage />
              </Route>
              <Route path="/select-country-service">
                <SelectCountryServicePage />
              </Route>
              <Route path="/select-region">
                <SelectRegionPage />
              </Route>
              <Route path="/select-number">
                <SelectNumberPage />
              </Route>
              <Route path="/buy-number">
                <BuyNumberPage />
              </Route>
              <Route path="/number-activated">
                <NumberActivatedPage />
              </Route>
              <Route path="/notifications">
                <NotificationsPage />
              </Route>
            </>
          )}
          <Route
            render={() => (
              <Redirect
                to={
                  isLoggedIn
                    ? '/'
                    : {
                        pathname: '/login',
                        search: qs.stringify({
                          redirectTo,
                        }),
                      }
                }
              />
            )}
          />
        </Switch>
      </main>
      {!isAuthRoute && !isPurchaseRoute && <AppFooter />}
      <BonusPopup
        open={openBonusPopup}
        onClose={() => setOpenBonusPopup(false)}
      />
      <ReferralPopup
        open={openReferralPopup}
        onClose={() => setOpenReferralPopup(false)}
      />
      <ExtraBonusPopup
        open={openExtraBonusPopup}
        onClose={() => setOpenExtraBonusPopup(false)}
      />
      <Snackbar />
      <ZendeskLauncher />
    </div>
  );
}

export default App;
