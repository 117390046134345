import { Country } from '../globalTypes';

function countriesFilter(countries: Country[], search = '') {
  if (!countries?.length) {
    return [];
  }

  return countries.filter((country) => {
    const searchLC = search.toLowerCase();
    return (
      country.iso?.toLocaleLowerCase().includes(searchLC) ||
      country.country?.toLocaleLowerCase().includes(searchLC) ||
      country.search?.join().toLocaleLowerCase().includes(searchLC)
    );
  });
}

export { countriesFilter };
