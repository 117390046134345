export const SAVE_NUMBERS_COUNTRIES = 'SAVE_NUMBERS_COUNTRIES';

export type Country = {
  country: string;
  iso: string;
  districts: {
    id: string;
    name: string;
    code: string;
  }[];
  services: {
    name: string;
    icon: string;
  }[];
};

export interface SaveNumbersCountries {
  type: typeof SAVE_NUMBERS_COUNTRIES;
  payload: Country[];
}

export type NumbersCountriesActions = SaveNumbersCountries;
