import React from 'react';
import styles from './BinancePayButton.module.scss';
import BinancePayImage from '../../assets/images/binance_pay.svg';

type BinancePayButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

const BinancePayButton: React.FC<BinancePayButtonProps> = ({
  onClick,
  disabled,
  className,
}) => {
  return (
    <button
      className={`${styles.container}${className ? ` ${className}` : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={BinancePayImage} alt="Binance Pay" className={styles.image} />
    </button>
  );
};

export default BinancePayButton;
