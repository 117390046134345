import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlusIcon from '../../assets/icons/ic_plus.svg';
import AutoTopUpIcon from '../../assets/icons/ic_auto_top_up_ycoins.svg';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import { RootState } from '../../store';
import styles from './Balance.module.scss';

type BalanceProps = {
  onClick?: () => void;
};

const Balance: React.FC<BalanceProps> = ({ onClick }) => {
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.userProfile);
  const isAutoTopUpEnabled = profile.ycoinsTopUpInfo.autoTopUpAmount > 0;

  return (
    <button
      className={styles.container}
      onClick={() => {
        history.push('/wallet');
        onClick?.();
      }}
      id="header-nav-wallet"
    >
      <img src={YcoinsIcon} alt="Ycoins" className={styles.ycoinsIcon} />
      <span className={styles.balance}>
        {String(profile.ycoins).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      </span>
      <img
        src={isAutoTopUpEnabled ? AutoTopUpIcon : PlusIcon}
        alt="Icon"
        className={styles.icon}
        onClick={(e) => {
          e.stopPropagation();
          history.push('/ycoins');
          onClick?.();
        }}
        id="header-nav-top-up-wallet"
      />
    </button>
  );
};

export default Balance;
