import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import formatPrice from '../../utils/formatPrice';
import Button from '../Button';
import Modal from '../Modal';
import styles from './AutoTopUpSettings.module.scss';

type AutoTopUpSettingsProps = {
  open: boolean;
  onClose: () => void;
  onDisableAutoTopUp: () => void;
};

const AutoTopUpSettings: React.FC<AutoTopUpSettingsProps> = ({
  open,
  onClose,
  onDisableAutoTopUp,
}) => {
  const { t } = useTranslation();
  const { autoTopUpAmount, minAuto } = useSelector(
    (state: RootState) => state.userProfile.ycoinsTopUpInfo,
  );
  const paymentCurrency = useSelector(
    (state: RootState) => state.userProfile.paymentCurrency,
  );

  return (
    <Modal open={open} modalPresentationMobile onClose={onClose}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('autoTopUpSettings')}</h2>
        <p className={styles.text}>
          {t('autoTopUpCoinsDesc', { min: minAuto })}
        </p>
        <ul className={styles.actions}>
          <li className={styles.item}>
            <p className={styles.label}>{t('topUpAmount')}</p>
            <p className={styles.amount}>
              {`${autoTopUpAmount} YC / ${formatPrice(
                +paymentCurrency.rate * (autoTopUpAmount / 100),
              )} ${paymentCurrency.code}`}
            </p>
          </li>
          <li className={styles.item} onClick={onDisableAutoTopUp}>
            <p className={styles.disable}>{t('disableAutoTopUp')}</p>
          </li>
        </ul>
        <Button label={t('back')} onClick={onClose} variant="secondary" />
      </div>
    </Modal>
  );
};

export default AutoTopUpSettings;
