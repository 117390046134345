import React, { useEffect, useRef, useState } from 'react';

type LongPressButtonProps = {
  onPress: () => void;
  children: React.ReactNode;
};

const LongPressButton: React.FC<LongPressButtonProps> = ({
  onPress,
  children,
}) => {
  const interval = useRef<NodeJS.Timeout>();
  const timeout = useRef<NodeJS.Timeout>();
  const [isTriggered, setTriggered] = useState(false);

  useEffect(() => {
    if (isTriggered) {
      interval.current = setInterval(() => onPress(), 100);
    } else {
      clearInterval(interval.current);
    }
  }, [isTriggered]);

  function onClick() {
    !isTriggered && onPress();
  }

  function onStart() {
    timeout.current = setTimeout(() => setTriggered(true), 100);
  }

  function onClear() {
    clearTimeout(timeout.current);
    setTriggered(false);
  }

  return (
    <button
      onClick={onClick}
      onMouseDown={onStart}
      onMouseUp={onClear}
      onMouseLeave={onClear}
      onTouchStart={onStart}
      onTouchEnd={onClear}
    >
      {children}
    </button>
  );
};

export default LongPressButton;
