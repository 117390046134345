import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/activeNumbersReducer/activeNumbersReducer';

export const getActiveNumbers = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.numbers.getActiveNumbers();
    dispatch(actions.saveActiveNumbers(res['list']));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
