import {
  DISMISS_SNACK,
  SET_SNACK,
  Snackbar,
  SnackActions,
  SnackBarPayload,
} from './snackbarReducer.types';

const init: Snackbar = {
  type: 'error',
  message: '',
  display: false,
};

export default function snackBarReducer(
  state = init,
  action: SnackActions,
): Snackbar {
  switch (action.type) {
    case SET_SNACK:
      return { ...action.payload, display: true };
    case DISMISS_SNACK:
      return {
        ...state,
        display: false,
        timer: undefined,
      };
    default:
      return state;
  }
}

export const setSnack = (snack: SnackBarPayload): SnackActions => ({
  type: SET_SNACK,
  payload: snack,
});
export const dismissSnack = (): SnackActions => ({
  type: DISMISS_SNACK,
});
