import React from 'react';
import AppStoreBadge from '../AppStoreBadge';
import GooglePlayBadge from '../GooglePlayBadge';
import styles from './DownloadApp.module.scss';
import QrCode from '../../assets/images/download_app_qr_code.png';
import { useTranslation } from 'react-i18next';

const DownloadApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles.text}>{t('stayConnectedAnywhere')}</p>
      <div className={styles.wrapper}>
        <img src={QrCode} alt="Device" className={styles.qrCode} />
        <div className={styles.download}>
          <AppStoreBadge className={styles.badge} />
          <GooglePlayBadge className={styles.badge} />
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
