import React, { useEffect, useState } from 'react';
import { isMobile } from '../../utils/detectDevice';
import styles from './InstallationPage.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import WarnIcon from '../../assets/images/warn.svg';
import SegmentedControl from '../../components/SegmentedControl';
import Collapsible from 'src/components/Collapsible';
import { useSnackbar } from 'src/hooks/useSnackbar';
import ios_settings from '../../assets/images/installation/ios_settings.png';
import ios_settings_add from '../../assets/images/installation/ios_settings_add.png';
import ios_choose_qr from '../../assets/images/installation/ios_choose_qr.png';
import ios_scan_qr from '../../assets/images/installation/ios_scan_qr.png';
import ios_label_change from '../../assets/images/installation/ios_label_change.png';
import ios_label from '../../assets/images/installation/ios_label.png';
import ios_default_data from '../../assets/images/installation/ios_default_data.png';
import ios_roaming from '../../assets/images/installation/ios_roaming.png';
import ios_manual_1 from '../../assets/images/installation/ios_manual_1.png';
import ios_manual_2 from '../../assets/images/installation/ios_manual_2.png';
import andr_step1 from '../../assets/images/installation/andr_step1.png';
import andr_step2 from '../../assets/images/installation/andr_step2.png';
import andr_step3 from '../../assets/images/installation/andr_step3.png';
import andr_step4 from '../../assets/images/installation/andr_step4.png';
import andr_step5 from '../../assets/images/installation/andr_step5.png';
import andr_step6 from '../../assets/images/installation/andr_step6.png';
import android_data_roaming from '../../assets/images/installation/android_data_roaming.png';
import ClipboardIcon from '../../assets/icons/ic_clipboard.svg';
import InstallationCarousel from '../../components/InstallationCarousel';
import AppStoreBadge from '../../components/AppStoreBadge';
import GooglePlayBadge from '../../components/GooglePlayBadge';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Api } from 'src/api';
import { useMountedState } from 'src/hooks/useMountedState';

const InstallationPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const user = useSelector((state: RootState) => state.userProfile);
  const [segment, setSegment] = useState(isMobile ? 1 : 0);

  const [qr, setQr] = useState<[string, string, string, string]>([
    '',
    '',
    '',
    '',
  ]);
  const isMount = useMountedState();

  const onCopy = (data: string) => {
    navigator.clipboard.writeText(data);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  useEffect(() => {
    async function init() {
      try {
        const qr = await Api.profile.showQrs();
        if (isMount()) {
          setQr(qr);
        }
      } catch (e: any) {
        if (isMount()) {
          setSnackbar({
            message: e.message,
            type: 'error',
          });
        }
      }
    }

    init();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.warn}>
          <img src={WarnIcon} alt="Warn" className={styles.icon} />
          <h4 className={styles.text}>
            <Trans
              i18nKey="dontRemove"
              components={{
                link1: (
                  <a
                    href={`https://yesimsupport.zendesk.com/hc/${
                      i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
                    }/articles/360006062037`}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </h4>
        </div>
        <span className={styles.status}>
          {`${t('esimStatus')}: `}
          {user.esimInstalledInfo.installed ? (
            <span className={styles.installed}>{t('qrInstalled')}</span>
          ) : (
            <span className={styles.notInstalled}>{t('qrNotInstalled')}</span>
          )}
        </span>
        <div className={styles.segmentedControl}>
          <SegmentedControl
            options={[
              { label: t('installWithQr'), value: 0 },
              { label: t('manual'), value: 1 },
            ]}
            value={segment}
            setValue={setSegment}
          />
        </div>
        <div className={styles.instructions}>
          <Collapsible label="Apple">
            {segment === 0 && (
              <>
                <img src={qr[0]} alt="QR code" className={styles.qr} />
                <div className={styles.step}>
                  <span className={styles.number}>1</span>
                  <div>
                    <p className={styles.text}>{t('setUpPlanByScanningQR')}</p>
                    <p className={styles.details}>
                      {t('iosInstallEsimSettingsPath')}
                    </p>
                  </div>
                </div>
                <InstallationCarousel
                  images={[
                    ios_settings,
                    ios_settings_add,
                    ios_choose_qr,
                    ios_scan_qr,
                  ]}
                />
                <div className={styles.step}>
                  <span className={styles.number}>2</span>
                  <p className={styles.text}>
                    {t('installTheLabelForTheNewCellularPlan')}
                  </p>
                </div>
                <InstallationCarousel images={[ios_label_change, ios_label]} />
                <div className={styles.step}>
                  <span className={styles.number}>3</span>
                  <p className={styles.text}>{t('iosCellularYesim')}</p>
                </div>
                <img
                  src={ios_default_data}
                  alt="Image"
                  className={styles.image}
                />
                <div className={styles.step}>
                  <span className={styles.number}>4</span>
                  <p className={styles.text}>{t('turnOnRoaming')}</p>
                </div>
                <img src={ios_roaming} alt="Image" className={styles.image} />
              </>
            )}
            {segment === 1 && (
              <>
                <div className={styles.step}>
                  <span className={styles.number}>1</span>
                  <div>
                    <p className={styles.text}>
                      {t('installThePlanByEnteringTheActivationCode_ios')}
                    </p>
                    <p className={styles.details}>
                      {t('iosInstallEsimSettingsPath')}
                    </p>
                  </div>
                </div>
                <InstallationCarousel
                  images={[
                    ios_settings,
                    ios_settings_add,
                    ios_choose_qr,
                    ios_manual_1,
                  ]}
                />
                <img src={ios_manual_2} alt="Image" className={styles.image} />
                <div className={styles.form}>
                  <button
                    onClick={() => onCopy(qr[2] || '-')}
                    className={styles.field}
                  >
                    <div>
                      <span className={styles.label}>{t('smdpAddress')}</span>
                      <span className={styles.input}>{qr[2] || '-'}</span>
                    </div>
                    <img
                      src={ClipboardIcon}
                      alt="Copy"
                      className={styles.icon}
                    />
                  </button>
                  <div className={styles.divider} />
                  <button
                    onClick={() => onCopy(qr[1] || '-')}
                    className={styles.field}
                  >
                    <div>
                      <span className={styles.label}>
                        {t('activationCode')}
                      </span>
                      <span className={styles.input}>{qr[1] || '-'}</span>
                    </div>
                    <img
                      src={ClipboardIcon}
                      alt="Copy"
                      className={styles.icon}
                    />
                  </button>
                </div>
                <div className={styles.step}>
                  <span className={styles.number}>2</span>
                  <p className={styles.text}>
                    {t('installTheLabelForTheNewCellularPlan')}
                  </p>
                </div>
                <InstallationCarousel images={[ios_label_change, ios_label]} />
                <div className={styles.step}>
                  <span className={styles.number}>3</span>
                  <div>
                    <p className={styles.text}>{t('iosCellularYesim')}</p>
                  </div>
                </div>
                <img
                  src={ios_default_data}
                  alt="Image"
                  className={styles.image}
                />
                <div className={styles.step}>
                  <span className={styles.number}>4</span>
                  <p className={styles.text}>{t('turnOnRoaming')}</p>
                </div>
                <img src={ios_roaming} alt="Image" className={styles.image} />
              </>
            )}
            <p className={styles.note}>
              {t('ifThereIsAProblemWithTheNetworkConnection')}
            </p>
          </Collapsible>
          <Collapsible label="Android (Samsung)">
            {segment === 0 && (
              <>
                <img src={qr[0]} alt="QR code" className={styles.qr} />
                <div className={styles.step}>
                  <span className={styles.number}>1</span>
                  <div>
                    <p className={styles.text}>
                      {t('goToTheSettingsToInstallTheEsimProfile')}
                    </p>
                    <p className={styles.details}>
                      {t('esimInstallPathSettings')}
                    </p>
                  </div>
                </div>
                <div className={styles.step}>
                  <span className={styles.number}>2</span>
                  <p className={styles.text}>
                    {t('installTheLabelForTheNewCellularPlan')}
                  </p>
                </div>
                <div className={styles.step}>
                  <span className={styles.number}>3</span>
                  <p className={styles.text}>{t('turnOnRoaming')}</p>
                </div>
                <InstallationCarousel
                  images={[andr_step1, android_data_roaming]}
                />
              </>
            )}
            {segment === 1 && (
              <>
                <div className={styles.step}>
                  <span className={styles.number}>1</span>
                  <div>
                    <p className={styles.text}>
                      {t('goToTheSettingsToInstallTheEsimProfile')}
                    </p>
                    <p className={styles.details}>
                      {t('esimInstallPathSettings')}
                    </p>
                  </div>
                </div>
                <InstallationCarousel
                  images={[andr_step1, andr_step2, andr_step3]}
                />
                <div className={styles.step}>
                  <span className={styles.number}>2</span>
                  <p className={styles.text}>
                    {t('installThePlanByEnteringTheActivationCode')}
                  </p>
                </div>
                <InstallationCarousel
                  images={[andr_step4, andr_step5, andr_step6]}
                />
                <div className={styles.form}>
                  <button
                    onClick={() => {
                      onCopy(`LPA:1$${qr[2]}$${qr[1]}`);
                    }}
                    className={styles.field}
                  >
                    <div>
                      <span className={styles.label}>
                        {t('activationCode')}
                      </span>
                      <span className={styles.input}>
                        {`LPA:1$${qr[2]}$${qr[1]}`}
                      </span>
                    </div>
                    <img
                      src={ClipboardIcon}
                      alt="Copy"
                      className={styles.icon}
                    />
                  </button>
                </div>
                <div className={styles.step}>
                  <span className={styles.number}>3</span>
                  <p className={styles.text}>
                    {t('installTheLabelForTheNewCellularPlan')}
                  </p>
                </div>
                <div className={styles.step}>
                  <span className={styles.number}>4</span>
                  <p className={styles.text}>{t('turnOnRoaming')}</p>
                </div>
                <InstallationCarousel
                  images={[andr_step1, android_data_roaming]}
                />
              </>
            )}
            <p className={styles.note}>
              {t('ifThereIsAProblemWithTheNetworkConnection')}
            </p>
          </Collapsible>
        </div>
        <div className={styles.download}>
          <span className={styles.text}>{t('getYesimApp')}</span>
          <div className={styles.badges}>
            <AppStoreBadge link="https://yesim.onelink.me/W9Wz/100386?af_android_url=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230" />
            <GooglePlayBadge link="https://yesim.onelink.me/W9Wz/100386?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile" />
          </div>
        </div>
        <p className={styles.help}>
          <Trans
            i18nKey="visitOurHelpCenter"
            components={{
              help: (
                <a
                  href={`https://yesimsupport.zendesk.com/hc/${
                    i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
                  }`}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              contact: (
                <span
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.zE('messenger', 'open');
                  }}
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default InstallationPage;
