import { useState } from 'react';

export type Pagination = {
  limit: 10;
  page: number;
  total: number;
};

export const usePagination = () =>
  useState<Pagination>({ limit: 10, page: 1, total: 10 });
