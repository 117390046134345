import React from 'react';
import { useHistory } from 'react-router-dom';
import BackIcon from '../../assets/icons/ic_back_arrow.svg';
import styles from './NavBar.module.scss';

type NavBarProps = {
  title?: string;
  goBack?: () => void;
  children?: React.ReactNode;
};

const NavBar: React.FC<NavBarProps> = ({ title, goBack, children }) => {
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <img
          src={BackIcon}
          alt="Back"
          className={styles.backIcon}
          onClick={() => {
            history.length > 2 ? history.goBack() : goBack?.();
          }}
        />
        <h2 className={styles.title}>{title}</h2>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </>
  );
};

export default NavBar;
