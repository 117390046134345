import qs from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import styles from './NumberActivatedPage.module.scss';
import SuccessIcon from '../../assets/icons/ic_success_light.svg';

const NumberActivatedPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { number, code } = qs.parse(search) as { [key: string]: any };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={SuccessIcon} alt="Success" className={styles.icon} />
        <h2 className={styles.text}>{formatPhoneNumber(number, code)}</h2>
        <p className={styles.text}>{t('activated')}</p>
      </div>
      <Button
        label={t('continue')}
        onClick={() => history.replace('/numbers')}
        variant="secondary"
      />
    </div>
  );
};

export default NumberActivatedPage;
