import {
  EsimProfile,
  EsimProfileActions,
  SAVE_ESIM_DATA_STATUS,
  SET_ESIM_PROFILE_LOADING_STATUS,
} from './esimProfileReducer.types';
import { LoadingStatus } from 'src/globalTypes';

const init: EsimProfile = {
  qrData: ['', '', '', ''],
  loadingData: 'unknown',
};

export default function infoAlertReducer(
  state = init,
  action: EsimProfileActions,
): EsimProfile {
  switch (action.type) {
    case SET_ESIM_PROFILE_LOADING_STATUS:
      return { ...state, loadingData: action.payload };
    case SAVE_ESIM_DATA_STATUS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export const setEsimProfileLoadingStatus = (
  loadingStatus: LoadingStatus,
): EsimProfileActions => ({
  type: SET_ESIM_PROFILE_LOADING_STATUS,
  payload: loadingStatus,
});

export const saveEsimProfileData = (
  payload: EsimProfile,
): EsimProfileActions => ({
  type: SAVE_ESIM_DATA_STATUS,
  payload: payload,
});
