import React, { useState } from 'react';
import styles from './RedeemCode.module.scss';
import { useTranslation } from 'react-i18next';
import InputBase from '../InputBase';
import Button from '../Button';
import { useMountedState } from 'src/hooks/useMountedState';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { Api } from 'src/api';
import { ProfileThunks } from 'src/store/thunks';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';

type RedeemCodeProps = {
  alternate?: boolean;
};

const RedeemCode: React.FC<RedeemCodeProps> = ({ alternate }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const isMount = useMountedState();
  const [code, setCode] = useState('');
  const [loadingStatus, setLoadingStatus] = useLoadingStatus('unknown');
  const { setSnackbar } = useSnackbar();

  async function redeemCode() {
    try {
      if (!code) return;
      setLoadingStatus('pending');
      const res = (await Api.ycoins.redeemCode(code))[0];
      await dispatch(ProfileThunks.getProfileData());
      switch (res) {
        case 'success':
          setSnackbar({ message: t('success'), type: 'success' });
          setCode('');
          setLoadingStatus('fulfilled');
          break;
        case 'err_not_exist':
          setSnackbar({ message: t('codeErrNotExists'), type: 'error' });
          break;
        case 'err_used':
          setSnackbar({ message: t('codeErrUsed'), type: 'error' });
          break;
        case 'err_first_buy':
          setSnackbar({ message: t('codeErrFirstBuy'), type: 'error' });
          break;
        case 'codeErrCantBeUed':
          setSnackbar({ message: t('codeErrCantBeUed'), type: 'error' });
          break;
        default:
          setSnackbar({
            message: res || t('somethingWentWrong'),
            type: 'error',
          });
      }
      if (isMount()) setLoadingStatus('unknown');
    } catch (e: any) {
      setSnackbar({ message: e.message, type: 'error' });
    } finally {
      if (isMount()) setLoadingStatus('unknown');
    }
  }

  return (
    <div className={alternate ? styles.alternateContainer : styles.container}>
      <h2 className={styles.label}>{t('redeemCode')}</h2>
      <div className={styles.wrapper}>
        <InputBase
          placeholder={t('purchase.enterRefCode')}
          value={code}
          onChangeText={setCode}
          className={styles.input}
        />
        <Button
          onClick={redeemCode}
          label={t('applyBonus')}
          variant={alternate ? 'default' : 'secondary'}
          size="small"
          disabled={loadingStatus === 'pending'}
          className={styles.button}
        />
      </div>
    </div>
  );
};

export default RedeemCode;
