import React from 'react';
import styles from './ThrottlingBlock.module.scss';
import InfoIcon from '../../assets/icons/ic_info_blue.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import formatMegabytes from '../../utils/formatMegabytes';

const ThrottlingBlock: React.FC = () => {
  const { t } = useTranslation();
  const throttling = useSelector(
    (state: RootState) => state.userProfile.throttling,
  );

  return (
    <div className={styles.container}>
      <img src={InfoIcon} alt="Info" className={styles.icon} />
      <p className={styles.text}>
        {t('throttlingTraffic', {
          dataAmount: formatMegabytes(throttling.limit),
          limit: throttling.speed,
        })}
      </p>
    </div>
  );
};

export default ThrottlingBlock;
