import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseIcon from '../../assets/icons/ic_close_popup.svg';
import CopyIcon from '../../assets/icons/ic_copy.svg';
import { useSnackbar } from '../../hooks/useSnackbar';
import { RootState } from '../../store';
import Button from '../Button';
import styles from './ReferralPopup.module.scss';

type ReferralPopupProps = {
  open: boolean;
  onClose: () => void;
};

const ReferralPopup: React.FC<ReferralPopupProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const { setSnackbar } = useSnackbar();
  const referralLink = `${window.location.origin}/marketplace/?referral=${profile.referralCode}`;

  const onCopyReferralCode = () => {
    navigator.clipboard.writeText(profile.referralCode);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  const onCopyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  if (!open) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={CloseIcon}
          alt="Close"
          className={styles.closeIcon}
          onClick={onClose}
          id="referral-popup-close-icon"
        />
        <h2 className={styles.title}>{t('youHaveBeenRegisteredBefore')}</h2>
        <p className={styles.text}>{t('referAFriendToReceiveYcoins')}</p>
        <div className={styles.wrapper}>
          <div className={styles.field}>
            <h4 className={styles.label}>{t('referralCode')}</h4>
            <div
              id="referral-popup-copy-code-icon"
              className={styles.input}
              onClick={onCopyReferralCode}
            >
              <p className={styles.value}>{profile.referralCode}</p>
              <img src={CopyIcon} alt="Copy" className={styles.copyIcon} />
            </div>
          </div>
          <div className={styles.field}>
            <h4 className={styles.label}>{t('referralLink')}</h4>
            <div
              id="referral-popup-copy-link-icon"
              className={styles.input}
              onClick={onCopyReferralLink}
            >
              <p className={styles.value}>{referralLink}</p>
              <img src={CopyIcon} alt="Copy" className={styles.copyIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            label={t('copyCode')}
            onClick={() => {
              onCopyReferralCode();
              onClose();
            }}
            className={styles.button}
            variant="secondary"
            id="referral-popup-copy-code-button"
          />
          <Button
            label={t('copyLink')}
            onClick={() => {
              onCopyReferralLink();
              onClose();
            }}
            className={styles.button}
            variant="plain"
            id="referral-popup-copy-link-button"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralPopup;
