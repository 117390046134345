import {
  CurrenciesActions,
  Currency,
  SAVE_CURRENCIES,
} from './currenciesReducer.types';

export default function (state: Currency[] = [], action: CurrenciesActions) {
  switch (action.type) {
    case SAVE_CURRENCIES:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveCurrencies: (payload: Currency[]): CurrenciesActions => ({
    type: SAVE_CURRENCIES,
    payload,
  }),
};
