import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import styles from './CountryPricesPage.module.scss';
import SearchInput from '../../components/SearchInput';
import { getPayAsYouGoCountries } from 'src/api/plans';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import { useMountedState } from 'src/hooks/useMountedState';
import { useSnackbar } from 'src/hooks/useSnackbar';
import Loader from 'src/components/Loader';
import { CountryPrice } from 'src/globalTypes';
import getFlag from 'src/utils/getFlag';
import BackIcon from 'src/assets/icons/ic_chevron_left.svg';
import { useHistory } from 'react-router-dom';

const CountryPricesPage: React.FC = () => {
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.userProfile);
  const [countries, setCountries] = useState<CountryPrice[]>([]);
  const [loading, setLoading] = useLoadingStatus('pending');
  const [search, setSearch] = useState('');
  const { setSnackbar } = useSnackbar();
  const isMount = useMountedState();

  useEffect(() => {
    async function init() {
      try {
        const res = await getPayAsYouGoCountries();
        if (isMount()) {
          setCountries(res);
          setLoading('fulfilled');
        }
      } catch (error: any) {
        if (isMount()) {
          setSnackbar({ message: error.message, type: 'error' });
          setLoading('rejected');
        }
      }
    }

    init();
  }, [profile.paymentCurrency.code]);

  const list = useMemo(
    () =>
      search
        ? countries.filter((i) =>
            (i.country + i.iso + i.search.join())
              .toLowerCase()
              .includes(search.toLowerCase()),
          )
        : countries,
    [search, countries?.[0]?.price?.currency],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img
        src={BackIcon}
        alt="Back"
        className={styles.back}
        onClick={() => {
          history.length > 2
            ? history.goBack()
            : history.push('/international-esim');
        }}
      />
      <SearchInput onChangeText={setSearch} value={search} />
      <ul className={styles.list}>
        {list.map((item, index) => (
          <li key={index} className={styles.item}>
            <img
              src={getFlag(item.iso)}
              alt="Country"
              className={styles.icon}
            />
            <span className={styles.country}>{item.country}</span>
            <div className={styles.price}>
              <span className={styles.coins}>{`${item.coins}YC / 1GB`}</span>
              <span className={styles.cost}>
                <strong>{`${item.price.amount}${item.price.symbol}`}</strong>
                {` / 1GB`}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CountryPricesPage;
