export const SET_SNACK = 'snackbar/SET_SNACK';
export const DISMISS_SNACK = 'snackbar/DISMISS_SNACK';

export interface Snackbar {
  type: 'success' | 'error' | 'default' | 'loading';
  message: string;
  display: boolean;
  timer?: number;
}

export type SnackBarPayload = Omit<Snackbar, 'display'>;

export type SetSnack = {
  payload: SnackBarPayload;
  type: typeof SET_SNACK;
};

export type DismissSnack = {
  type: typeof DISMISS_SNACK;
};

export type SnackActions = SetSnack | DismissSnack;
