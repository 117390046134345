import qs from 'query-string';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { Country } from '../../globalTypes';
import { RootState } from '../../store';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import CollapsibleFooterSection from '../CollapsibleFooterSection';
import styles from './AppFooter.module.scss';
import '../LanguageSwitcher/LanguageSwitcher.scss';
import SwissMade from '../../assets/images/swiss_made.svg';
import AppStoreBadge from '../AppStoreBadge';
import GooglePlayBadge from '../GooglePlayBadge';
import LinkedInIcon from '../../assets/icons/ic_linkedin.svg';
import LinkedInHoverIcon from '../../assets/icons/ic_linkedin_hover.svg';
import FacebookIcon from '../../assets/icons/ic_facebook.svg';
import FacebookHoverIcon from '../../assets/icons/ic_facebook_hover.svg';
import InstagramIcon from '../../assets/icons/ic_instagram.svg';
import InstagramHoverIcon from '../../assets/icons/ic_instagram_hover.svg';
import XIcon from '../../assets/icons/ic_x.svg';
import XHoverIcon from '../../assets/icons/ic_x_hover.svg';
import YouTubeIcon from '../../assets/icons/ic_youtube.svg';
import YouTubeHoverIcon from '../../assets/icons/ic_youtube_hover.svg';
import ApplePayImage from '../../assets/images/apple_pay.png';
import BinancePayImage from '../../assets/images/binance_pay.svg';
import GooglePayImage from '../../assets/images/google_pay.png';
import MastercardImage from '../../assets/images/mastercard.png';
import PayPalImage from '../../assets/images/paypal.svg';
import VisaImage from '../../assets/images/visa.png';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const POPULAR_COUNTRIES_ISO = ['tr', 'es', 'ae', 'us', 'it'];

const AppFooter: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { partner_id } = qs.parse(search);
  const countries = useSelector(
    (state: RootState) => state.countriesPackages.countries,
  );

  const popularCountries = useMemo(
    () =>
      POPULAR_COUNTRIES_ISO.reduce((accumulator: Country[], currentValue) => {
        const country = countries.find((i) => i.iso === currentValue);
        return country ? accumulator.concat(country) : accumulator;
      }, []),
    [countries],
  );

  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <div className={styles.column}>
          <img src={Logo} className={styles.logo} alt="Yesim" />
          <div className={styles.badges}>
            <AppStoreBadge
              className={styles.badge}
              link="https://yesim.onelink.me/W9Wz/100386?af_android_url=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1458505230"
            />
            <GooglePlayBadge
              className={styles.badge}
              link="https://yesim.onelink.me/W9Wz/100386?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yesimmobile"
            />
          </div>
          <div className={styles.social}>
            <a
              href="https://www.facebook.com/yesim.travel/"
              target="_blank"
              rel="noreferrer"
              id="footer-facebook"
            >
              <img
                src={FacebookIcon}
                alt="Facebook"
                className={styles.icon}
                onMouseOver={(e) => (e.currentTarget.src = FacebookHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = FacebookIcon)}
              />
            </a>
            <a
              href="https://www.instagram.com/yesim.travel/?hl=en"
              target="_blank"
              rel="noreferrer"
              id="footer-instagram"
            >
              <img
                src={InstagramIcon}
                alt="Instagram"
                className={styles.icon}
                onMouseOver={(e) => (e.currentTarget.src = InstagramHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = InstagramIcon)}
              />
            </a>
            <a
              href="https://twitter.com/Yesim_app"
              target="_blank"
              rel="noreferrer"
              id="footer-x"
            >
              <img
                src={XIcon}
                alt="X"
                className={styles.icon}
                onMouseOver={(e) => (e.currentTarget.src = XHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = XIcon)}
              />
            </a>
            <a
              href="https://linkedin.com/company/yesimbusiness"
              target="_blank"
              rel="noreferrer"
              id="footer-linkedin"
            >
              <img
                src={LinkedInIcon}
                alt="LinkedIn"
                className={styles.icon}
                onMouseOver={(e) => (e.currentTarget.src = LinkedInHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = LinkedInIcon)}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCzl5P2jv1SppsnDv4Kqnhqg"
              target="_blank"
              rel="noreferrer"
              id="footer-youtube"
            >
              <img
                src={YouTubeIcon}
                alt="YouTube"
                className={styles.icon}
                onMouseOver={(e) => (e.currentTarget.src = YouTubeHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = YouTubeIcon)}
              />
            </a>
          </div>
        </div>
        <CollapsibleFooterSection title={t('aboutProducts')}>
          <li
            className={styles.item}
            onClick={() => {
              history.push('/countries');
              window.scrollTo(0, 0);
            }}
          >
            {t('regions')}
          </li>
          <a
            href="https://yesim.app/payment/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('payment')}
          </a>
          <a
            href="https://yesim.app/compatible-devices/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('supportingDevices')}
          </a>
          <li
            className={styles.item}
            onClick={() => {
              history.push('/numbers');
              window.scrollTo(0, 0);
            }}
          >
            {t('virtualNumbers')}
          </li>
          <li
            className={styles.item}
            onClick={() => {
              history.push('/wallet');
              window.scrollTo(0, 0);
            }}
          >
            {t('referralSystem')}
          </li>
        </CollapsibleFooterSection>
        <CollapsibleFooterSection title={t('popularDestinations')}>
          {popularCountries.map((item) => (
            <li
              key={item.id}
              className={styles.item}
              onClick={() => {
                history.push({
                  pathname: '/plans',
                  search: qs.stringify({
                    name: item.country,
                    id: item.id,
                    type: 'country',
                    iso: item.iso,
                    partner_id,
                  }),
                });
                pushDataLayerEvent({
                  event: 'select_item',
                  ecommerce: {
                    item_list_id: 'countries',
                    item_list_name: 'Countries',
                    items: [
                      {
                        item_id: item.id,
                        item_name: item.country,
                        item_list_id: 'countries',
                        item_list_name: 'Countries',
                      },
                    ],
                  },
                });
                window.scrollTo(0, 0);
              }}
            >
              {item.country}
            </li>
          ))}
        </CollapsibleFooterSection>
        <CollapsibleFooterSection title={t('settings.help')}>
          <a
            href={`https://yesimsupport.zendesk.com/hc/${
              i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
            }`}
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {'FAQ'}
          </a>
          <li
            className={styles.item}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.zE('messenger', 'show');
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.zE('messenger', 'open');
            }}
          >
            {t('support')}
          </li>
          <a
            href={
              i18n.languages[0] === 'ru'
                ? 'https://yesimsupport.zendesk.com/hc/ru/sections/360001787877-%D0%9D%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82%D0%BE%D0%BC-%D0%BE%D1%82-Yesim'
                : 'https://yesimsupport.zendesk.com/hc/en-us/sections/360001787877-Getting-Started-with-Mobile-Data'
            }
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('yesimInstallation')}
          </a>
        </CollapsibleFooterSection>
        <CollapsibleFooterSection title={t('moreInfo')}>
          <a
            href="https://yesim.app/about-us/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('aboutUs')}
          </a>
          <a
            href="https://yesim.app/contacts/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('contacts')}
          </a>
          <a
            href="https://yesim.app/refund-policy/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('refundPolicy')}
          </a>
          <a
            href="https://yesim.app/blog/"
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            {t('blog')}
          </a>
        </CollapsibleFooterSection>
      </div>
      <div className={styles.middleSection}>
        <div className={styles.paymentMethods}>
          <img
            src={VisaImage}
            alt="Visa"
            className={styles.paymentMethodImage}
          />
          <img
            src={MastercardImage}
            alt="Mastercard"
            className={styles.paymentMethodImage}
          />
          <img
            src={PayPalImage}
            alt="PayPal"
            className={styles.paymentMethodImage}
          />
          <img
            src={ApplePayImage}
            alt="Apple Pay"
            className={styles.paymentMethodImage}
          />
          <img
            src={GooglePayImage}
            alt="Google Pay"
            className={styles.paymentMethodImage}
          />
          <img
            src={BinancePayImage}
            alt="Binance Pay"
            className={styles.paymentMethodImage}
          />
        </div>
        <div className={styles.languageSwitcherWrapper}>
          <LanguageSwitcher className={'footer-language-switcher'} />
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.terms}>
          <a
            href="https://yesim.app/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {t('settings.privacyPolicy')}
          </a>
          <a
            href="https://yesim.app/terms-of-service/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {t('settings.termsOfService')}
          </a>
          <a
            href="https://yesim.app/cof/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {t('cof')}
          </a>
        </div>
        <div className={styles.copyright}>
          <p className={styles.text}>{'2023 © Yesim'}</p>
          <p className={styles.text}>
            <a
              className={styles.copyrightLink}
              href="https://genesisgroup.ch"
              target="_blank"
              rel="nofollow"
            >
              {'Genesis Group AG'}
            </a>
            {'; Chamerstrasse 172, CH-6300 Zug; REG NO: CHE-135.623.633'}
          </p>
          <img
            src={SwissMade}
            alt="Swiss Made"
            className={styles.swissMadeImage}
          />
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
