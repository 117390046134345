import qs from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ChangePaymentMethodIcon from '../../assets/icons/ic_change_payment_method.svg';
import PayPalImage from '../../assets/images/paypal.svg';
import { PaymentMethods } from '../../globalTypes';
import { RootState } from '../../store';
import PaymentMethodModal from '../PaymentMethodModal';
import PlanReplaceModal from '../PlanReplaceModal';
import styles from './PaymentMethod.module.scss';

type PaymentMethodProps = {
  onPay: (paymentMethod: PaymentMethods, cardIso?: string) => void;
  amount: string | number;
  confirmation?: boolean;
  isAutoTopUpChecked?: boolean;
  disabled?: boolean;
  className?: string;
  isSecurePayment?: boolean;
};

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  onPay,
  amount,
  confirmation,
  isAutoTopUpChecked,
  disabled,
  className,
  isSecurePayment,
}) => {
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const { lastPaymentMethod, ycoinsTopUpInfo, paymentCurrency } = useSelector(
    (state: RootState) => state.userProfile,
  );
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { partner_id } = qs.parse(search) as {
    [key: string]: string;
  };
  const paymentFail = sessionStorage.getItem('paymentFail');
  const redirectTo = pathname + search;
  const isZeroAmount = Number(amount) === 0;
  const [isOpenPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [openContinueConfirmation, setOpenContinueConfirmation] =
    useState(false);
  const [openChangeConfirmation, setOpenChangeConfirmation] = useState(false);
  const isAutoTopUpDisabled = ycoinsTopUpInfo.autoTopUpAmount === 0;

  function redirectToLogin() {
    history.replace({
      pathname: '/login',
      search: qs.stringify({ redirectTo, partner_id }),
    });
  }

  function onChange() {
    setOpenPaymentMethod(true);
  }

  function onContinue() {
    if (
      (isAutoTopUpChecked && isAutoTopUpDisabled) ||
      lastPaymentMethod.system === PaymentMethods.UNKNOWN ||
      paymentFail
    ) {
      onPay(PaymentMethods.RAPYD);
    } else if (isZeroAmount) {
      onPay(PaymentMethods.ECP);
    } else {
      onPay(lastPaymentMethod.system, lastPaymentMethod.iso);
    }
  }

  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      {!disabled && (
        <div className={styles.top}>
          {!isZeroAmount && (
            <p className={styles.toPay}>
              {t('toPay')}
              <span className={styles.amount}>
                {`${paymentCurrency.symbol}${amount} ${paymentCurrency.code}`}
              </span>
            </p>
          )}
          {!isZeroAmount &&
            lastPaymentMethod.system !== PaymentMethods.UNKNOWN &&
            !(isAutoTopUpChecked && isAutoTopUpDisabled) && (
              <button
                className={styles.changeMethod}
                onClick={() => {
                  if (!profile.id) {
                    redirectToLogin();
                  } else if (confirmation) {
                    setOpenChangeConfirmation(true);
                  } else {
                    onChange();
                  }
                }}
              >
                <p className={styles.text}>{t('change')}</p>
                <img
                  src={ChangePaymentMethodIcon}
                  alt="Change payment method"
                  className={styles.icon}
                />
              </button>
            )}
        </div>
      )}
      <button
        className={styles.button}
        onClick={() => {
          if (!profile.id) {
            redirectToLogin();
          } else if (confirmation) {
            setOpenContinueConfirmation(true);
          } else {
            onContinue();
          }
        }}
        disabled={disabled}
      >
        {disabled ? (
          <span className={styles.label}>{t('choosePlan')}</span>
        ) : isZeroAmount ? (
          <span className={styles.label}>{t('activate')}</span>
        ) : paymentFail ? (
          <span className={styles.label}>{t('payWithCard')}</span>
        ) : (
          <>
            {lastPaymentMethod.system === PaymentMethods.PAYPAL && (
              <>
                <span className={styles.label}>{t('payWith')}</span>
                <img
                  src={PayPalImage}
                  alt="Payment system"
                  className={styles.image}
                />
              </>
            )}
            {lastPaymentMethod.system === PaymentMethods.BINANCE && (
              <span className={styles.label}>
                {isAutoTopUpChecked ? t('payWithCard') : t('payViaBinance')}
              </span>
            )}
            {[
              PaymentMethods.ECP,
              PaymentMethods.STRIPE,
              PaymentMethods.MONETIX,
              PaymentMethods.RAPYD,
              PaymentMethods.UNKNOWN,
              PaymentMethods.MELANGE,
            ].includes(lastPaymentMethod.system) && (
              <span className={styles.label}>{t('payWithCard')}</span>
            )}
          </>
        )}
      </button>
      <PaymentMethodModal
        open={isOpenPaymentMethod}
        onClose={() => setOpenPaymentMethod(false)}
        goToPayment={onPay}
        isAutoTopUpChecked={isAutoTopUpChecked}
        isSecurePayment={isSecurePayment}
      />
      <PlanReplaceModal
        open={openContinueConfirmation}
        onClose={() => setOpenContinueConfirmation(false)}
        onContinue={onContinue}
      />
      <PlanReplaceModal
        open={openChangeConfirmation}
        onClose={() => setOpenChangeConfirmation(false)}
        onContinue={onChange}
      />
    </div>
  );
};

export default PaymentMethod;
