import React, { useEffect, useMemo, useState } from 'react';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { getHistory } from '../../store/thunks/ycoinsThunks';
import styles from './Challenges.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useSnackbar } from '../../hooks/useSnackbar';
import Progress from '../Progress';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import CompleteIcon from '../../assets/icons/ic_complete.svg';
import Modal from '../Modal';
import CloseIcon from '../../assets/icons/ic_close_light.svg';
import CopyIcon from '../../assets/icons/ic_copy.svg';
import ChallengeImage from '../../assets/images/rewards/refer_a_friend_program.png';
import invite_5 from '../../assets/images/rewards/invite_5.svg';
import invite_10 from '../../assets/images/rewards/invite_10.svg';
import invite_20 from '../../assets/images/rewards/invite_20.svg';
import invite_30 from '../../assets/images/rewards/invite_30.svg';
import invite_40 from '../../assets/images/rewards/invite_40.svg';
import invite_50 from '../../assets/images/rewards/invite_50.svg';

const Challenges: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { setSnackbar } = useSnackbar();
  const profile = useSelector((state: RootState) => state.userProfile);
  const history = useSelector((state: RootState) => state.ycoins.history);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getHistory());
  }, []);

  const received = useMemo(
    () =>
      history
        .filter((i) => i.type === 'TASK_COMPLETED')
        .reduce(
          (previousValue, currentValue) => previousValue + +currentValue.coins,
          0,
        ),
    [history],
  );

  const onCopyReferralCode = () => {
    navigator.clipboard.writeText(profile.referralCode);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  const tasks = [
    {
      count: 5,
      title: t('achievement_n_friend', { count: 5 }),
      ycoins: 300,
      image: invite_5,
      completed: +profile.friendsAttracted >= 5,
    },
    {
      count: 10,
      title: t('achievement_n_friend', { count: 10 }),
      ycoins: 600,
      image: invite_10,
      completed: +profile.friendsAttracted >= 10,
    },
    {
      count: 20,
      title: t('achievement_n_friend', { count: 20 }),
      ycoins: 900,
      image: invite_20,
      completed: +profile.friendsAttracted >= 20,
    },
    {
      count: 30,
      title: t('achievement_n_friend', { count: 30 }),
      ycoins: 1200,
      image: invite_30,
      completed: +profile.friendsAttracted >= 30,
    },
    {
      count: 40,
      title: t('achievement_n_friend', { count: 40 }),
      ycoins: 1500,
      image: invite_40,
      completed: +profile.friendsAttracted >= 40,
    },
    {
      count: 50,
      title: t('achievement_n_friend', { count: 50 }),
      ycoins: 2000,
      image: invite_50,
      completed: +profile.friendsAttracted >= 50,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.challenges}>
        <h2>{t('challenges')}</h2>
        <div className={styles.challenge} onClick={() => setOpen(true)}>
          <div>
            <h2 className={styles.title}>{t('giftCoins')}</h2>
            <p className={styles.description}>{t('inviteFriendsDesc')}</p>
            <Progress
              value={+profile.friendsAttracted}
              max={50}
              label={
                <>
                  <span className={styles.progressLabel}>
                    {t('invited', { count: +profile.friendsAttracted })}
                  </span>
                  <span className={styles.progressLabel}>
                    {`· ${t('received')} ${received}`}
                    <img
                      src={YcoinsIcon}
                      alt="Ycoins"
                      className={styles.icon}
                    />
                  </span>
                </>
              }
            />
          </div>
          <img src={ChallengeImage} alt="Challenge" className={styles.image} />
        </div>
        <Modal open={open} modalPresentationMobile>
          <div className={styles.modal}>
            <div className={styles.modalTop}>
              <img
                src={ChallengeImage}
                alt="Challenge"
                className={styles.image}
              />
              <div className={styles.wrapper}>
                <h1 className={styles.title}>{t('giftCoins')}</h1>
                <img
                  src={CloseIcon}
                  alt="Close"
                  className={styles.close}
                  onClick={() => setOpen(false)}
                />
                <p className={styles.description}>{t('inviteFriendsDesc')}</p>
                <div className={styles.progress}>
                  <div className={styles.progressItem}>
                    <h6 className={styles.label}>{t('nowYouReferred')}</h6>
                    <p className={styles.data}>
                      {t('nFriends', { count: +profile.friendsAttracted })}
                    </p>
                  </div>
                  <div className={styles.progressItem}>
                    <h6 className={styles.label}>{t('yourReferralBonus')}</h6>
                    <span className={styles.data}>
                      {received}
                      <img
                        src={YcoinsIcon}
                        alt="Ycoins"
                        className={styles.icon}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.tasks}>
                {tasks.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${
                      item.completed && styles.complete
                    }`}
                  >
                    <img src={item.image} alt="Task" className={styles.image} />
                    <div className={styles.content}>
                      <p className={styles.text}>{item.title}</p>
                      <div className={styles.details}>
                        <p className={styles.ycoins}>{item.ycoins}</p>
                        <img
                          src={YcoinsIcon}
                          alt="Ycoins"
                          className={styles.ycoinsIcon}
                        />
                        {item.completed && (
                          <img
                            src={CompleteIcon}
                            alt="Complete"
                            className={styles.completeIcon}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className={styles.button} onClick={onCopyReferralCode}>
                <span className={styles.label}>
                  {t('shareYourCode')}
                  <span className={styles.code}>{profile.referralCode}</span>
                </span>
                <img src={CopyIcon} alt="Copy" className={styles.icon} />
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Challenges;
