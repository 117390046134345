import React, { useEffect, useMemo, useState } from 'react';
import CellIcon from '../../assets/icons/ic_cell.svg';
import Agreements from '../../components/Agreements';
import BinancePayButton from '../../components/BinancePayButton';
import IncorrectCountryPopup from '../../components/IncorrectCountryPopup';
import PaymentMethod from '../../components/PaymentMethod';
import RedeemCode from '../../components/RedeemCode';
import SegmentedControl from '../../components/SegmentedControl';
import formatPrice from '../../utils/formatPrice';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './PlansPage.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import SecureIcon from '../../assets/icons/ic_secure.svg';
import BackIcon from '../../assets/icons/ic_back.svg';
import getFlag from '../../utils/getFlag';
import Plans from '../../components/Plans';
import { Country, PaymentMethods, Plan } from 'src/globalTypes';
import {
  checkPaymentStatus,
  getCountryTarifs,
  getExtraPlan,
  getNetworkOperators,
  getPackageTarifs,
  plansForTopup,
  plansFromHistory,
} from 'src/api/plans';
import YcoinsIcon from '../../assets/images/ycoins.svg';
import Button from '../../components/Button';
import ToggleOption from '../../components/ToggleOption';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from '../../components/Tooltip';
import formatMegabytes from '../../utils/formatMegabytes';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import getPackageIcon from '../../utils/getPackageIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import qs from 'query-string';
import Modal from '../../components/Modal';
import InfoIcon from '../../assets/icons/ic_tooltip.svg';
import { useSnackbar } from 'src/hooks/useSnackbar';
import PostponeIcon from '../../assets/icons/ic_postpone.svg';
import GlobeIcon from '../../assets/icons/ic_globe.svg';
import NoPhoneIcon from '../../assets/icons/ic_no_phone.svg';
import InfoGrayIcon from '../../assets/icons/ic_info_gray.svg';
import NetworkOperatorsIcon from '../../assets/icons/ic_network_operators.svg';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import UnlimitedIcon from '../../assets/icons/ic_unlimited.png';

const PlansPage: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const {
    type,
    name,
    id,
    iso,
    isTopup,
    isRepeat,
    orderId,
    planId,
    totalMb,
    partner_id,
    paymentFail,
    payment_id,
  } = qs.parse(search) as {
    [key: string]: string;
  };
  const redirectTo = pathname + search;
  const { t, i18n } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const isLoggedIn = profile.id;
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [delayed, setDelayed] = useState(false);
  const [isYcoinsApplied, setYcoinsApplied] = useState(false);
  const rate = +profile.paymentCurrency.rate;
  const selectedPlanCost = Number(selectedPlan?.price.amount);
  const maxYcoins =
    (selectedPlanCost / rate) * 100 > profile.ycoins
      ? profile.ycoins
      : Math.round((selectedPlanCost / rate) * 100);
  const ycoins = selectedPlan?.planId ? maxYcoins : profile.ycoins;
  const firstPaymentAmount = rate * 0.5;
  const isSecurePayment =
    profile.countOfPurchases === 0 &&
    (selectedPlanCost / rate - maxYcoins / 100) * rate === 0;
  const totalPlanCost = formatPrice(
    isYcoinsApplied
      ? isSecurePayment
        ? firstPaymentAmount
        : (selectedPlanCost / rate - maxYcoins / 100) * rate
      : selectedPlanCost,
  );
  const [loading, setLoading] = useLoadingStatus('unknown');
  const classicPlan = useSelector(
    (state: RootState) => state.userProfile.classicPlan,
  );
  const haveActivePlan = useSelector(
    (state: RootState) => !!state.userProfile.classicPlan.id,
  );
  const [isOpenDestinations, setOpenDestinations] = useState(false);
  const countries = useSelector(
    (state: RootState) => state.countriesPackages.countries,
  );
  const { setSnackbar } = useSnackbar();
  const PARTNER_LINK_USER_FLOW =
    partner_id &&
    partner_id !== '53' &&
    partner_id !== '182' &&
    partner_id !== '557' &&
    partner_id !== '577';
  const [operators, setOperators] = useState<{ name: string }[]>([]);
  const [segment, setSegment] = useState(0);
  const fixedPlans = useMemo(() => plans.filter((i) => !i.unlimited), [plans]);
  const unlimitedPlans = useMemo(
    () => plans.filter((i) => i.unlimited),
    [plans],
  );
  const [openIncorrectCountryPopup, setOpenIncorrectCountryPopup] =
    useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [profile.id]);

  const destinations = useMemo(
    () =>
      countries.filter((country: Country) =>
        plans?.[0]?.countries.includes(country.id),
      ),
    [plans?.[0]?.planId],
  );

  useEffect(() => {
    if (paymentFail) {
      setSnackbar({
        message: t('somethingWentWrong'),
        type: 'error',
      });
      if (payment_id) {
        checkPaymentStatus({ paymentId: payment_id }).then((res) => {
          if (res?.status === 'decline_ru') {
            setOpenIncorrectCountryPopup(true);
          }
        });
      }
      sessionStorage.setItem('paymentFail', paymentFail);
    }
  }, []);

  useEffect(() => {
    setLoading('pending');

    if (PARTNER_LINK_USER_FLOW && !profile.id) {
      history.replace({
        pathname: '/login',
        search: qs.stringify({ redirectTo, partner_id }),
      });
      return;
    }

    try {
      if (isTopup) {
        plansForTopup().then((res) => {
          const plans = res.plans;
          const plan =
            plans.find((i) => i.planId === classicPlan.id) || plans[2];
          setPlans(plans);
          setSelectedPlan(plan);
          if (!plan?.unlimited) {
            setSegment(1);
          }
          history.push({
            search: qs.stringify({
              type: res.type,
              name: res.name,
              id: res.id,
              isTopup: 1,
            }),
          });
          setLoading('fulfilled');
          pushDataLayerEvent({
            event: 'view_item',
            ecommerce: {
              items: [
                {
                  item_id: res.id,
                  item_name: res.name,
                },
              ],
            },
          });
        });
      } else if (isRepeat) {
        plansFromHistory(orderId).then((res) => {
          const plans = res.plans;
          const plan = plans.find((i) => i.planId === planId) || plans[2];
          setPlans(plans);
          setSelectedPlan(plan);
          if (!plan?.unlimited) {
            setSegment(1);
          }
          history.push({
            search: qs.stringify({
              type: res.type,
              name: res.country,
              isRepeat: true,
              orderId,
              planId,
            }),
          });
          setLoading('fulfilled');
          pushDataLayerEvent({
            event: 'view_item',
            ecommerce: {
              items: [
                {
                  item_id: res.id,
                  item_name: res.country,
                },
              ],
            },
          });
        });
      } else if (type === 'country') {
        getCountryTarifs({
          ...(name ? { country: name } : {}),
          ...(iso ? { iso: iso } : {}),
          currency: profile.paymentCurrency.code,
        }).then((plans) => {
          const plan =
            plans.find((i) => i.planId === planId) ||
            plans.find(
              (i) =>
                i.totalMb === totalMb ||
                (i.totalMb === 'Unlim' && totalMb === '-1'),
            ) ||
            plans[2];
          setPlans(plans);
          if (
            (PARTNER_LINK_USER_FLOW &&
              partner_id !== '53' &&
              partner_id !== '182' &&
              partner_id !== '557' &&
              partner_id !== '577') ||
            planId ||
            totalMb
          ) {
            setSelectedPlan(plan);
            if (!plan?.unlimited) {
              setSegment(1);
            }
          }
          if (!plans.find((i) => i.unlimited)) {
            setSegment(1);
          }
          getNetworkOperators([iso]).then((res) => {
            setOperators(res?.[0]?.operators || []);
            setLoading('fulfilled');
          });
          history.push({
            search: qs.stringify({
              ...qs.parse(search),
              name: plan?.planNameTranslate || name,
            }),
          });
          pushDataLayerEvent({
            event: 'view_item',
            ecommerce: {
              items: [
                {
                  item_id: id,
                  item_name: plan?.planNameTranslate || name,
                },
              ],
            },
          });
        });
      } else if (type === 'package' || type === 'global-package') {
        getPackageTarifs({ id, currency: profile.paymentCurrency.code }).then(
          (plans) => {
            const plan =
              plans?.find((i) => i.planId === planId) ||
              plans.find(
                (i) =>
                  i.totalMb === totalMb ||
                  (i.totalMb === 'Unlim' && totalMb === '-1'),
              ) ||
              plans?.[2];
            setPlans(plans);
            if (
              (PARTNER_LINK_USER_FLOW &&
                partner_id !== '53' &&
                partner_id !== '182' &&
                partner_id !== '557' &&
                partner_id !== '577') ||
              planId ||
              totalMb
            ) {
              setSelectedPlan(plan);
              if (!plan?.unlimited) {
                setSegment(1);
              }
            }
            if (!plans.find((i) => i.unlimited)) {
              setSegment(1);
            }
            history.push({
              search: qs.stringify({
                ...qs.parse(search),
                name: plan?.planNameTranslate || name,
              }),
            });
            setLoading('fulfilled');
            pushDataLayerEvent({
              event: 'view_item',
              ecommerce: {
                items: [
                  {
                    item_id: id,
                    item_name: plan?.planNameTranslate || name,
                  },
                ],
              },
            });
          },
        );
      } else if (type === 'offer') {
        if (!isLoggedIn || profile.countOfPurchases === 0) {
          getExtraPlan()
            .then((res) => {
              const plan = res['plans'] as Plan;
              setPlans([plan]);
              setSelectedPlan(plan);
              if (!plan?.unlimited) {
                setSegment(1);
              }
              setLoading('fulfilled');
              pushDataLayerEvent({
                event: 'view_item',
                ecommerce: {
                  items: [
                    {
                      item_id: res.id,
                      item_name: res.name,
                    },
                  ],
                },
              });
            })
            .catch(() => history.replace('/countries'));
        } else {
          history.replace('/countries');
        }
      }
    } catch (err: any) {
      setSnackbar({
        message: err?.message || t('somethingWentWrong'),
        type: 'error',
      });
      history.replace('/countries');
      setLoading('rejected');
    }
  }, [id, i18n.languages[0], profile.paymentCurrency.code]);

  function goToPayment(paymentMethod: PaymentMethods, cardIso?: string) {
    history.push({
      pathname: '/payment',
      search: qs.stringify({
        ycoins: isYcoinsApplied ? maxYcoins : 0,
        planId: selectedPlan!.planId,
        additional: isTopup ? 1 : 0,
        delayed: delayed ? 1 : 0,
        country: name,
        totalMb: selectedPlan!.totalMb,
        period: selectedPlan!.validityPeriod,
        planCost: selectedPlan!.planCost,
        totalPlanCost: totalPlanCost,
        prev: window.location.href,
        type: 'plan',
        paymentMethod,
        cardIso,
        ...(type === 'country' ? { iso } : { packageId: id }),
        currency: profile.paymentCurrency.code,
        category:
          {
            country: 'plan-country',
            package: 'region',
            'global-package': 'global',
          }[type] || 'plan-country',
        variant: selectedPlan!.unlimited ? 'unlimited' : 'standard',
      }),
    });
    if (Number(totalPlanCost) !== 0) {
      pushDataLayerEvent({
        event: 'begin_checkout',
        ecommerce: {
          currency: selectedPlan?.price.currency,
          value: totalPlanCost,
          items: [
            {
              item_id: selectedPlan!.planId,
              item_category: name,
              item_name: selectedPlan!.planName,
              discount: (isYcoinsApplied ? maxYcoins : 0 / 100)
                .toFixed(2)
                .toString(),
              price: selectedPlan!.planCost,
              currency: selectedPlan?.price.currency,
              item_variant: `${selectedPlan!.validityPeriod}days`,
              quantity: 1,
            },
          ],
        },
      });
    }
  }

  function onSelectPlan(plan: Plan) {
    if (plan.planId !== selectedPlan?.planId) {
      setSelectedPlan(plan);
      history.push({
        search: qs.stringify({
          ...qs.parse(search),
          totalMb: plan.totalMb,
          planId: plan.planId,
        }),
      });
    }
  }

  function goBack() {
    if (type === 'package') {
      history.push({ pathname: '/regions' });
    } else if (type === 'global-package') {
      history.push({ pathname: '/global-packages' });
    } else {
      history.push({ pathname: '/countries' });
    }
  }

  if (loading !== 'fulfilled') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  if (plans.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.back} onClick={goBack}>
          <img src={BackIcon} alt="Back" />
          <span>{t('back')}</span>
        </div>
        <p className={styles.noPlans}>{t('purchase.noPlans')}</p>
      </div>
    );
  }

  if (PARTNER_LINK_USER_FLOW) {
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.back} onClick={goBack}>
            <img src={BackIcon} alt="Back" />
            <span>{t('back')}</span>
          </div>
          <div className={styles.secure}>
            <img src={SecureIcon} alt="Secure" />
            <span>{t('secureCheckout')}</span>
          </div>
        </div>
        <div className={styles.alternateContent}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.titleText}>{t('internationalEsimFor')}</h2>
            <img
              src={type === 'country' ? getFlag(iso!) : getPackageIcon(id!)}
              alt="Icon"
              className={styles.flagIcon}
            />
            <h2 className={styles.titleText}>{name}</h2>
          </div>
          <div className={styles.alternatePlanWrapper}>
            <div className={styles.alternatePlan}>
              <p className={styles.planData}>
                {formatMegabytes(selectedPlan!.totalMb)}
              </p>
              <p className={styles.planPeriod}>
                {t('day', {
                  days: Number(selectedPlan?.validityPeriod),
                  count: Number(selectedPlan?.validityPeriod),
                })}
              </p>
              {isYcoinsApplied ? (
                <p className={styles.planDiscount}>
                  {Number(selectedPlan?.price.amount)}
                  <span className={styles.planCost}>
                    {`${totalPlanCost}${selectedPlan?.price.symbol}`}
                  </span>
                </p>
              ) : (
                <p className={styles.planCost}>
                  {`${totalPlanCost}${selectedPlan?.price.symbol}`}
                </p>
              )}
            </div>
          </div>
          {Number(selectedPlan?.tdl) > 0 && Number(selectedPlan?.ts) > 0 && (
            <div className={styles.alternateLimit}>
              <img src={InfoGrayIcon} alt="Info" className={styles.icon} />
              <p className="f-14 t-second">
                {t('throttlingInfo', {
                  dataAmount: formatMegabytes(Number(selectedPlan?.tdl)),
                  limit: selectedPlan?.ts,
                })}
              </p>
            </div>
          )}
          <div className={styles.paymentMethods}>
            <BinancePayButton
              onClick={() => goToPayment(PaymentMethods.BINANCE)}
            />
            <Button
              label={t('payWithCard')}
              variant="plain"
              className={styles.paymentButton}
              onClick={() => goToPayment(PaymentMethods.RAPYD)}
            />
          </div>
          <div className={styles.alternateRedeemCode}>
            <RedeemCode alternate />
          </div>
        </div>
        <div className={styles.options}>
          {profile.ycoins > 0 && (
            <ToggleOption
              label={
                <div>
                  <h2 className={styles.label}>Ycoins</h2>
                  <div className={styles.wrapper}>
                    <p className={styles.text}>
                      {`${t('youPay')} ${maxYcoins}`}
                    </p>
                    <img
                      src={YcoinsIcon}
                      alt="Ycoins"
                      className={styles.smallIcon}
                    />
                  </div>
                </div>
              }
              checked={isYcoinsApplied}
              onChange={setYcoinsApplied}
              hint={t('referYourFriends')}
              icon={YcoinsIcon}
            />
          )}
          {type !== 'offer' && !isTopup && !profile.postponePlan.available && (
            <ToggleOption
              label={t('purchase.activeLater')}
              checked={delayed}
              onChange={setDelayed}
              hint={t('activeLaterText')}
              icon={PostponeIcon}
            />
          )}
        </div>
        <div className={styles.info}>
          {type === 'country' ? (
            <div className={styles.networkOperators}>
              <h2 className={styles.networkOperatorsLabel}>
                {t('cellOperators')}
              </h2>
              <div className={styles.networkOperatorsWrapper}>
                <img src={CellIcon} alt="Cell" className={styles.cellIcon} />
                <p className={styles.networkOperatorsList}>
                  {operators.map((i) => i.name).join(', ')}
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.items}>
              <div
                className={styles.item}
                onClick={() => {
                  history.push({
                    pathname: '/network-operators',
                    search: qs.stringify({
                      countries: destinations.map((i) => i.iso),
                    }),
                  });
                }}
              >
                <img
                  src={NetworkOperatorsIcon}
                  alt="Icon"
                  className={styles.icon}
                />
                <p className={styles.text}>{t('cellOperators')}</p>
                <img
                  src={ChevronIcon}
                  alt="Chevron"
                  className={styles.chevron}
                />
              </div>
            </div>
          )}
          <div className={styles.noPhone}>
            <h2 className={styles.noPhoneLabel}>{t('important')}</h2>
            <div className={styles.noPhoneWrapper}>
              <img
                src={NoPhoneIcon}
                alt="Data only eSIM"
                className={styles.noPhoneIcon}
              />
              <p className={styles.noPhoneText}>{t('noPhone')}</p>
            </div>
          </div>
        </div>
        <div className={styles.agreements}>
          <p className={styles.important}>
            <Trans
              i18nKey="checkThatYourDeviceIsCompatible"
              components={{
                a: (
                  <a
                    href="https://yesimsupport.zendesk.com/hc/articles/360006059318"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </p>
          <Agreements />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.back} onClick={goBack}>
          <img src={BackIcon} alt="Back" />
          <span>{t('back')}</span>
        </div>
        <div className={styles.secure}>
          <img src={SecureIcon} alt="Secure" />
          <span>{t('secureCheckout')}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div
          className={styles.title}
          onClick={() => {
            if (
              type === 'package' ||
              type === 'global-package' ||
              type === 'offer'
            ) {
              setOpenDestinations(true);
            }
          }}
        >
          {!isRepeat &&
            (!isTopup || type === 'package' || type === 'global-package') && (
              <img
                src={
                  type === 'offer'
                    ? GlobeIcon
                    : type === 'country'
                    ? getFlag(iso!)
                    : getPackageIcon(id!)
                }
                alt="Icon"
                className={styles.icon}
              />
            )}
          <div className={styles.name}>
            <h2>{name}</h2>
            {(type === 'package' || type === 'global-package') && (
              <h4>
                {`${destinations.length} ${t('purchase.countries', {
                  count: destinations.length,
                })}`}
              </h4>
            )}
            {type === 'offer' && (
              <h4>{`120+ ${t('purchase.countries', { count: 120 })}`}</h4>
            )}
          </div>
          {(type === 'package' ||
            type === 'global-package' ||
            type === 'offer') && (
            <img src={InfoIcon} alt="Info" className={styles.infoIcon} />
          )}
        </div>
        <div className={styles.wrapper}>
          {type === 'offer' ? (
            <div className={styles.globalDataPlan}>
              <h2 className={styles.planName}>{t('globalData')}</h2>
              <h4 className={styles.planDetails}>
                {`${formatMegabytes(selectedPlan!.totalMb)} / ${t('day', {
                  days: Number(selectedPlan?.validityPeriod),
                  count: Number(selectedPlan?.validityPeriod),
                })}`}
              </h4>
              <p className={styles.note}>
                {`${t('onlyForNewUsers')}. ${t('topUpIsNotAvailable')}`}
              </p>
            </div>
          ) : (
            <div>
              {unlimitedPlans.length > 0 && fixedPlans.length > 0 && (
                <SegmentedControl
                  options={[
                    {
                      label: t('unlimPlanCategory'),
                      value: 0,
                      icon: UnlimitedIcon,
                    },
                    { label: t('fixedPlanCategory'), value: 1 },
                  ]}
                  value={segment}
                  setValue={setSegment}
                  className={styles.segmentedControl}
                />
              )}
              <Plans
                plans={segment === 0 ? unlimitedPlans : fixedPlans}
                selectedPlanId={selectedPlan?.planId}
                onSelect={(plan) => {
                  onSelectPlan(plan);
                }}
                isYcoinsApplied={isYcoinsApplied}
                operators={operators}
              />
            </div>
          )}
          <div className={styles.details}>
            <h4>{t('freeVPN')}</h4>
            {selectedPlan?.planId && (
              <>
                <div>
                  <h3>{t('purchase.internetAmount')}</h3>
                  <h2>{formatMegabytes(selectedPlan!.totalMb)}</h2>
                </div>
                <div>
                  <h3>{t('purchase.usagePeriod')}</h3>
                  <h2>
                    {t('day', {
                      days: Number(selectedPlan?.validityPeriod),
                      count: Number(selectedPlan?.validityPeriod),
                    })}
                  </h2>
                </div>
                <div>
                  <h3>{t('totalPrice')}</h3>
                  {isYcoinsApplied ? (
                    <h2 className={styles.discount}>
                      {selectedPlan?.price.amount}
                      <span>{`${selectedPlan?.price.symbol}${totalPlanCost}`}</span>
                    </h2>
                  ) : (
                    <h2>{`${selectedPlan?.price.symbol}${formatPrice(
                      Number(selectedPlan?.price.amount),
                    )}`}</h2>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {selectedPlan?.planId &&
          Number(selectedPlan?.tdl) > 0 &&
          Number(selectedPlan?.ts) > 0 && (
            <div className={styles.limit}>
              <img src={InfoGrayIcon} alt="Info" className={styles.icon} />
              <p className="f-12 t-second">
                {t('throttlingInfo', {
                  dataAmount: formatMegabytes(Number(selectedPlan?.tdl)),
                  limit: selectedPlan?.ts,
                })}
              </p>
            </div>
          )}
        <PaymentMethod
          onPay={goToPayment}
          amount={totalPlanCost!}
          confirmation={!delayed && !isTopup && haveActivePlan}
          disabled={!selectedPlan?.planId}
          className={styles.paymentMethod}
          isSecurePayment={isSecurePayment}
        />
        <div className={styles.secureMobile}>
          <img src={SecureIcon} alt="Secure" className={styles.secureIcon} />
          <span className={styles.secureText}>{t('secureCheckout')}</span>
        </div>
        <div className={styles.ycoins}>
          <img src={YcoinsIcon} alt="Ycoins" className={styles.largeIcon} />
          <h4 className={styles.label}>
            {isLoggedIn
              ? `${t('yourBalance')}: ${ycoins} Ycoins`
              : t('signUpToUseYcoins')}
          </h4>
          <Tooltip
            content={t('referYourFriends')}
            position={isLoggedIn ? 'top' : 'left'}
            size="small"
          />
        </div>
        {isLoggedIn && (
          <div className={styles.redeemCode}>
            <RedeemCode alternate />
          </div>
        )}
      </div>
      {isLoggedIn && (
        <>
          <div className={styles.options}>
            {profile.ycoins > 0 && (
              <ToggleOption
                label={
                  <div>
                    <h2 className={styles.label}>Ycoins</h2>
                    <div className={styles.wrapper}>
                      <p className={styles.text}>
                        {`${t('youPay')} ${ycoins}`}
                      </p>
                      <img
                        src={YcoinsIcon}
                        alt="Ycoins"
                        className={styles.smallIcon}
                      />
                    </div>
                  </div>
                }
                checked={isYcoinsApplied}
                onChange={setYcoinsApplied}
                hint={t('referYourFriends')}
                icon={YcoinsIcon}
              />
            )}
            {type !== 'offer' &&
              !isTopup &&
              !profile.postponePlan.available && (
                <ToggleOption
                  label={t('purchase.activeLater')}
                  checked={delayed}
                  onChange={setDelayed}
                  hint={t('activeLaterText')}
                  icon={PostponeIcon}
                />
              )}
            <span className={styles.include}>{t('freeVPN')}</span>
          </div>
          <div className={styles.info}>
            {type === 'country' ? (
              <div className={styles.networkOperators}>
                <h2 className={styles.networkOperatorsLabel}>
                  {t('cellOperators')}
                </h2>
                <div className={styles.networkOperatorsWrapper}>
                  <img src={CellIcon} alt="Cell" className={styles.cellIcon} />
                  <p className={styles.networkOperatorsList}>
                    {operators.map((i) => i.name).join(', ')}
                  </p>
                </div>
              </div>
            ) : (
              <div className={styles.items}>
                <div
                  className={styles.item}
                  onClick={() => {
                    history.push({
                      pathname: '/network-operators',
                      search: qs.stringify({
                        countries: destinations.map((i) => i.iso),
                      }),
                    });
                  }}
                >
                  <img
                    src={NetworkOperatorsIcon}
                    alt="Icon"
                    className={styles.icon}
                  />
                  <p className={styles.text}>{t('cellOperators')}</p>
                  <img
                    src={ChevronIcon}
                    alt="Chevron"
                    className={styles.chevron}
                  />
                </div>
              </div>
            )}
            <div className={styles.noPhone}>
              <h2 className={styles.noPhoneLabel}>{t('important')}</h2>
              <div className={styles.noPhoneWrapper}>
                <img
                  src={NoPhoneIcon}
                  alt="Data only eSIM"
                  className={styles.noPhoneIcon}
                />
                <p className={styles.noPhoneText}>{t('noPhone')}</p>
              </div>
            </div>
          </div>
          <div className={styles.agreements}>
            <p className={styles.important}>
              <Trans
                i18nKey="checkThatYourDeviceIsCompatible"
                components={{
                  a: (
                    <a
                      href="https://yesimsupport.zendesk.com/hc/articles/360006059318"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </p>
            <Agreements />
          </div>
        </>
      )}
      <Modal
        open={isOpenDestinations}
        onClose={() => setOpenDestinations(false)}
        modalPresentationMobile
      >
        <div className={styles.destinations}>
          <h2>
            {`${destinations.length} ${t('purchase.countries', {
              count: destinations.length,
            })}`}
          </h2>
          <div className={styles.list}>
            {destinations.map((item, index) => (
              <div key={index} className={styles.item}>
                <img src={getFlag(item.iso)} alt="Flag" />
                <span>{item.country}</span>
              </div>
            ))}
          </div>
          <Button
            label={t('continue')}
            variant="secondary"
            onClick={() => setOpenDestinations(false)}
          />
        </div>
      </Modal>
      <IncorrectCountryPopup
        open={openIncorrectCountryPopup}
        onClose={() => setOpenIncorrectCountryPopup(false)}
        onSelectCountry={(iso) => goToPayment(PaymentMethods.STRIPE, iso)}
      />
    </div>
  );
};

export default PlansPage;
