import React from 'react';
import styles from './PaymentMethodModal.module.scss';
import Modal from '../Modal';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import PaymentMethodsImage from '../../assets/images/payment_methods.png';
import BinancePayButton from '../BinancePayButton';
import { PaymentMethods } from '../../globalTypes';
import ApplePayImage from '../../assets/images/apple_pay.svg';
import GooglePayImage from '../../assets/images/google_pay.svg';
import PayPalImage from '../../assets/images/paypal.svg';

type PaymentMethodModalProps = {
  open: boolean;
  onClose: () => void;
  goToPayment: (paymentMethod: PaymentMethods, cardIso?: string) => void;
  isAutoTopUpChecked?: boolean;
  isSecurePayment?: boolean;
};

const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({
  open,
  onClose,
  goToPayment,
  isAutoTopUpChecked,
  isSecurePayment,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} modalPresentationMobile onClose={onClose}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('purchase.paymentMethod')}</h2>
        <div className={styles.methods}>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {window.ApplePaySession && (
            <button
              className={styles.applePay}
              onClick={() => goToPayment(PaymentMethods.APPLE_PAY)}
            >
              <img
                src={ApplePayImage}
                alt="Apple Pay"
                className={styles.image}
              />
            </button>
          )}
          <button
            className={styles.googlePay}
            onClick={() => goToPayment(PaymentMethods.GOOGLE_PAY)}
          >
            <img
              src={GooglePayImage}
              alt="Google Pay"
              className={styles.image}
            />
          </button>
          <Button
            label={t('payWithCard')}
            variant="plain"
            className={styles.button}
            onClick={() => goToPayment(PaymentMethods.RAPYD)}
          />
          {!isSecurePayment && (
            <button
              className={styles.paypal}
              onClick={() => goToPayment(PaymentMethods.PAYPAL)}
            >
              <img src={PayPalImage} alt="PayPal" className={styles.image} />
            </button>
          )}
          {!isAutoTopUpChecked && (
            <BinancePayButton
              className={styles.button}
              onClick={() => goToPayment(PaymentMethods.BINANCE)}
            />
          )}
        </div>
        <img
          src={PaymentMethodsImage}
          alt="Payment methods"
          className={styles.paymentMethodsImage}
        />
      </div>
    </Modal>
  );
};

export default PaymentMethodModal;
