import React, { useEffect, useState } from 'react';
import { checkPaymentStatus } from '../../api/plans';
import Agreements from '../../components/Agreements';
import AutoTopUp from '../../components/AutoTopUp';
import Chip from '../../components/Chip';
import IncorrectCountryPopup from '../../components/IncorrectCountryPopup';
import LongPressButton from '../../components/LongPressButton';
import PaymentMethod from '../../components/PaymentMethod';
import { useSnackbar } from '../../hooks/useSnackbar';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './YcoinsPage.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackIcon from '../../assets/icons/ic_back.svg';
import SecureIcon from '../../assets/icons/ic_secure.svg';
import YcoinsIcon from '../../assets/images/ycoins.svg';
import MinusIcon from '../../assets/icons/ic_minus_sign_large.svg';
import PlusIcon from '../../assets/icons/ic_plus_sign_large.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import qs from 'query-string';
import GiftIcon from '../../assets/icons/ic_gift.svg';
import GiftWhiteIcon from '../../assets/icons/ic_gift_white.svg';
import { PaymentMethods } from '../../globalTypes';
import NumberIcon from '../../assets/icons/ic_number.svg';
import GlobeIcon from '../../assets/icons/ic_globe.svg';

const STEP = 100;

const YcoinsPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const {
    payAsYouGo,
    buyNumber,
    number,
    code,
    country,
    district,
    services,
    numberPlanId,
    numberPlanCost,
    paymentFail,
    notEnoughYcoins,
    payment_id,
    prevYcoins,
    prevIsAutoTopUpChecked,
  } = qs.parse(search) as {
    [key: string]: string;
  };
  const { t } = useTranslation();
  const {
    ycoinsTopUpInfo,
    ycoinsBonusProgram,
    lastPaymentMethod,
    paymentCurrency,
  } = useSelector((state: RootState) => state.userProfile);
  const [ycoins, setYcoins] = useState(
    paymentFail ? Number(prevYcoins) : ycoinsTopUpInfo.start,
  );
  const { setSnackbar } = useSnackbar();
  const [isAutoTopUpChecked, setAutoTopUpChecked] = useState(
    paymentFail
      ? prevIsAutoTopUpChecked === 'true'
      : ycoinsTopUpInfo.autoTopUpAmount === 0,
  );
  const [minAmountPresses, setMinAmountPresses] = useState(0);
  const [openIncorrectCountryPopup, setOpenIncorrectCountryPopup] =
    useState(false);

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: 'ycoins',
            item_name: 'Ycoins',
          },
        ],
      },
    });
  }, []);

  function onPay(paymentMethod: PaymentMethods, cardIso?: string) {
    history.push({
      pathname: '/payment',
      search: qs.stringify({
        ycoins,
        bonusAmount: ycoinsBonusProgram.available
          ? ycoinsBonusProgram.bonusAmount
          : 0,
        type: 'ycoins',
        payAsYouGo,
        buyNumber,
        number,
        code,
        country,
        district,
        services,
        numberPlanId,
        numberPlanCost,
        prev: window.location.href,
        paymentMethod,
        cardIso,
        autoTopUp: isAutoTopUpChecked,
      }),
    });
    pushDataLayerEvent({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: (Number(ycoins) / 100).toFixed(2),
        items: [
          {
            item_id: 'ycoins',
            item_category: 'Ycoins',
            item_name: 'Ycoins',
            price: (Number(ycoins) / 100).toFixed(2),
            currency: 'EUR',
            quantity: 1,
          },
        ],
      },
    });
  }

  function onSubtract() {
    setYcoins((ycoins) => {
      if (ycoins <= ycoinsTopUpInfo.min) {
        setMinAmountPresses((minAmountPresses) => minAmountPresses + 1);
      }

      return ycoins <= ycoinsTopUpInfo.min
        ? ycoinsTopUpInfo.min
        : ycoins - STEP;
    });
  }

  function onAdd() {
    setYcoins((ycoins) =>
      ycoins < ycoinsTopUpInfo.max ? ycoins + STEP : ycoinsTopUpInfo.max,
    );
    if (minAmountPresses > 0) {
      setMinAmountPresses(0);
    }
  }

  useEffect(() => {
    if (paymentFail) {
      setSnackbar({
        message: t('somethingWentWrong'),
        type: 'error',
      });
      if (payment_id) {
        checkPaymentStatus({ paymentId: payment_id }).then((res) => {
          if (res?.status === 'decline_ru') {
            setOpenIncorrectCountryPopup(true);
          }
        });
      }
      sessionStorage.setItem('paymentFail', paymentFail);
      history.replace({ pathname: '/ycoins' });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.back} onClick={() => history.push('/wallet')}>
          <img src={BackIcon} alt="Back" />
          <span>{t('back')}</span>
        </div>
        <div className={styles.secure}>
          <img src={SecureIcon} alt="Secure" />
          <span>{t('secureCheckout')}</span>
        </div>
      </div>
      <h2 className={styles.title}>{t('topUpWallet')}</h2>
      <div className={styles.content}>
        {buyNumber && notEnoughYcoins && (
          <Chip
            icon={NumberIcon}
            text={formatPhoneNumber(number, code)}
            className={styles.number}
          />
        )}
        {payAsYouGo && notEnoughYcoins && (
          <Chip
            icon={GlobeIcon}
            text={t('internationalEsimPlan')}
            className={styles.payAsYouGo}
          />
        )}
        <h2 className={styles.label}>
          {buyNumber && notEnoughYcoins
            ? t('chooseAmountVn')
            : payAsYouGo && notEnoughYcoins
            ? t('chooseAmountPsg')
            : t('chooseAmount')}
        </h2>
        <div className={styles.ycoins}>
          <LongPressButton onPress={onSubtract}>
            <img
              src={MinusIcon}
              alt="Minus"
              draggable="false"
              className={styles.sign}
            />
          </LongPressButton>
          <div>
            <div className={styles.wrapper}>
              <img src={YcoinsIcon} alt="Ycoins" className={styles.icon} />
              <span
                className={`${styles.input} ${ycoins > 0 && styles.active}`}
              >
                {ycoins}
              </span>
            </div>
            {ycoinsBonusProgram.available &&
              ycoins >= ycoinsBonusProgram.bonusFrom && (
                <p className={styles.bonus}>
                  {`+${ycoinsBonusProgram.bonusAmount} ${t('asGift')}`}
                </p>
              )}
          </div>
          <LongPressButton onPress={onAdd}>
            <img
              src={PlusIcon}
              alt="Plus"
              draggable="false"
              className={styles.sign}
            />
          </LongPressButton>
        </div>
        <ul className={styles.amounts}>
          {ycoinsTopUpInfo.preset.map((item) => (
            <li
              key={item}
              className={`${styles.item} ${ycoins === item && styles.selected}`}
              onClick={() => {
                setYcoins(item);
                if (minAmountPresses > 0) {
                  setMinAmountPresses(0);
                }
              }}
            >
              {item}
              {ycoinsBonusProgram.available &&
                item >= ycoinsBonusProgram.bonusFrom && (
                  <img
                    src={ycoins === item ? GiftWhiteIcon : GiftIcon}
                    alt="Bonus"
                    className={styles.gift}
                  />
                )}
            </li>
          ))}
        </ul>
        {minAmountPresses >= 1 && (
          <p className={styles.error}>
            {t('minimumTopUpAmount', { amount: ycoinsTopUpInfo.min })}
          </p>
        )}
        <p className={styles.hint}>
          {`100 Ycoins = ${paymentCurrency.symbol}${paymentCurrency.rate} ${paymentCurrency.code}`}
        </p>
        <div className={styles.paymentMethod}>
          <PaymentMethod
            onPay={onPay}
            amount={((Number(paymentCurrency.rate) * ycoins) / 100).toFixed(2)}
            isAutoTopUpChecked={isAutoTopUpChecked}
          />
        </div>
        <AutoTopUp
          checked={isAutoTopUpChecked}
          setChecked={setAutoTopUpChecked}
          description={
            isAutoTopUpChecked
              ? buyNumber
                ? t('autoTopUpCoinsDescVN', {
                    min: ycoinsTopUpInfo.minAuto,
                    amount: ycoins,
                  })
                : payAsYouGo
                ? t('autoTopUpCoinsDescPsg', {
                    min: ycoinsTopUpInfo.minAuto,
                    amount: ycoins,
                  })
                : t('autoTopUpCoinsDescCommon', {
                    min: ycoinsTopUpInfo.minAuto,
                    amount: ycoins,
                  })
              : t('autoTopUpCoinsDesc', {
                  min: ycoinsTopUpInfo.minAuto,
                })
          }
        />
      </div>
      <div className={styles.agreements}>
        <Agreements buttonText={t('payWithCard')} />
      </div>
      <IncorrectCountryPopup
        open={openIncorrectCountryPopup}
        onClose={() => setOpenIncorrectCountryPopup(false)}
        onSelectCountry={(cardIso) => onPay(lastPaymentMethod.system, cardIso)}
      />
    </div>
  );
};

export default YcoinsPage;
