export default (megabytes: any) => {
  try {
    if (megabytes === 'Unlim' || megabytes === 'Unlimited') {
      return '∞ GB';
    } else if (+megabytes < 1024) {
      return `${
        +megabytes % 1 != 0 ? Number(megabytes).toFixed(1) : megabytes
      } MB`;
    } else {
      let gigabytes = +megabytes * 0.000976563;
      gigabytes = parseFloat(gigabytes.toFixed(1));
      return `${gigabytes} GB`;
    }
  } catch (error: any) {
    return '0 MB';
  }
};
