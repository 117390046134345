import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'plain'
    | 'outlined';
  size?: 'large' | 'medium' | 'small';
  className?: string;
  id?: string;
};

const Button: React.FC<ButtonProps> = ({
  label,
  variant = 'default',
  size = 'large',
  onClick,
  disabled,
  className,
  id,
}) => {
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${styles[size]} ${className}`}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      <span>{label}</span>
    </button>
  );
};

export default Button;
