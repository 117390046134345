import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AutoTopUpIcon from '../../assets/icons/ic_auto_top_up.svg';
import QuestionMarkIcon from '../../assets/icons/ic_question_mark.svg';
import { RootState } from '../../store';
import formatPrice from '../../utils/formatPrice';
import AutoTopUpModal from '../AutoTopUpModal';
import AutoTopUpSettings from '../AutoTopUpSettings';
import styles from './AutoTopUp.module.scss';
import Switch from '../Switch';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';

type AutoTopUpProps = {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  description: string;
};

const AutoTopUp: React.FC<AutoTopUpProps> = ({
  checked,
  setChecked,
  description,
}) => {
  const { t } = useTranslation();
  const { autoTopUpAmount } = useSelector(
    (state: RootState) => state.userProfile.ycoinsTopUpInfo,
  );
  const paymentCurrency = useSelector(
    (state: RootState) => state.userProfile.paymentCurrency,
  );
  const [isOpenAutoTopUp, setOpenAutoTopUp] = useState(false);
  const [isOpenAutoTopUpConfirmation, setOpenAutoTopUpConfirmation] =
    useState(false);
  const [openAutoTopUpSettings, setOpenAutoTopUpSettings] = useState(false);
  const isAutoTopUpEnabled = autoTopUpAmount > 0;

  return (
    <div
      className={`${styles.container}${
        isAutoTopUpEnabled ? ` ${styles.enabled}` : ''
      }`}
      onClick={() => {
        if (isAutoTopUpEnabled) {
          setOpenAutoTopUpSettings(true);
        }
      }}
    >
      <div className={styles.content}>
        <img src={AutoTopUpIcon} alt="Auto top-up" className={styles.icon} />
        {isAutoTopUpEnabled ? (
          <>
            <div className={styles.wrapper}>
              <p className={styles.label}>{t('autoTopUpActive')}</p>
              <p className={styles.amount}>
                {`${autoTopUpAmount} YC / ${formatPrice(
                  +paymentCurrency.rate * (autoTopUpAmount / 100),
                )} ${paymentCurrency.code}`}
              </p>
            </div>
            <img src={ChevronIcon} alt="Arrow" className={styles.arrowIcon} />
          </>
        ) : (
          <>
            <h4 className={styles.title}>{t('autoTopUp')}</h4>
            <img
              src={QuestionMarkIcon}
              alt="Question mark"
              className={styles.questionMarkIcon}
              onClick={() => setOpenAutoTopUp(true)}
            />
            <Switch
              checked={checked}
              onChange={(checked) => {
                if (checked) {
                  setChecked(checked);
                } else {
                  setOpenAutoTopUpConfirmation(true);
                }
              }}
            />
          </>
        )}
      </div>
      {!isAutoTopUpEnabled && <p className={styles.text}>{description}</p>}
      <AutoTopUpModal
        open={isOpenAutoTopUp}
        onClose={() => setOpenAutoTopUp(false)}
      />
      <AutoTopUpModal
        open={isOpenAutoTopUpConfirmation}
        onClose={() => setOpenAutoTopUpConfirmation(false)}
        confirmation={isAutoTopUpEnabled || checked}
        setChecked={setChecked}
      />
      <AutoTopUpSettings
        open={openAutoTopUpSettings}
        onClose={() => setOpenAutoTopUpSettings(false)}
        onDisableAutoTopUp={() => {
          setOpenAutoTopUpSettings(false);
          setOpenAutoTopUpConfirmation(true);
        }}
      />
    </div>
  );
};

export default AutoTopUp;
