import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppIcon from '../../assets/icons/ic_app.svg';
import CloseIcon from '../../assets/icons/ic_close_dark.svg';
import { isAndroid } from '../../utils/detectDevice';
import Button from '../Button';
import styles from './AppBanner.module.scss';

const AppBanner: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  if (!open) return null;

  return (
    <div className={styles.container} id="mobile-app-banner">
      <img
        src={CloseIcon}
        alt="Close"
        className={styles.closeIcon}
        onClick={() => setOpen(false)}
      />
      <img src={AppIcon} alt="App" className={styles.appIcon} />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{'Yesim: eSim'}</h2>
        <p className={styles.text}>
          {isAndroid ? t('getOnTheGooglePlay') : t('getOnTheAppStore')}
        </p>
      </div>
      <Button
        label={t('download')}
        onClick={() =>
          window.open(
            isAndroid
              ? 'https://play.google.com/store/apps/details?id=com.yesimmobile'
              : 'https://apps.apple.com/us/app/yesim-esim-internet-app-vpn/id1458505230',
            '_blank',
          )
        }
        size="medium"
        variant="plain"
        id="mobile-app-banner-download-button"
      />
    </div>
  );
};

export default AppBanner;
