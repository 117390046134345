import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import { HistoryItem } from '../store/reducers/ycoinsReducer/ycoinsReducer.types';
import i18next from 'i18next';
import { PaymentMethods } from '../globalTypes';

export const getHistory = (): Promise<HistoryItem[]> =>
  axios
    .get('/ycoins_history', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const redeemCode = (code: string): Promise<any> =>
  axios
    .get('/code_apply', {
      params: {
        auth_key: getAuthKey(),
        ref_code: code,
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const buyYcoins = (
  data: any,
  paymentMethod: string,
  altPayment?: boolean,
  coins_auto_top_up?: boolean,
) =>
  axios
    .post('/ycbuy', null, {
      params: {
        auth_key: getAuthKey(),
        data,
        // test: 1,
        lang: i18next.languages[0],
        payment_system:
          paymentMethod === PaymentMethods.ECP
            ? altPayment
              ? 1
              : 0
            : paymentMethod,
        coins_auto_top_up,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const disableCoinsAutoTopUp = () =>
  axios
    .post('/coins_auto_top_up_disable', null, {
      params: {
        auth_key: getAuthKey(),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const changeCoinsAutoTopUpAmount = (amount: number) =>
  axios
    .post('/coins_auto_top_up_amount', null, {
      params: {
        auth_key: getAuthKey(),
        amount,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
