import { CountryInfo, PaymentMethods } from 'src/globalTypes';
import { Currency } from '../currenciesReducer/currenciesReducer.types';
import { LogOut } from '../sharedActionTypes';

export const SAVE_PROFILE = 'userProfile/SAVE_PROFILE';
export const UPDATE_EMAIL = 'userProfile/UPDATE_EMAIL';
export const SET_RATING = 'userProfile/SET_RATING';
export const CHANGE_CURRENCY = 'userProfile/CHANGE_CURRENCY';
export const CHANGE_CURRENCY_CODE = 'userProfile/CHANGE_CURRENCY_CODE';

export interface ClassicPlan {
  id: string;
  name: string;
  totalMb: string;
  availableMb: string;
  duration: string;
  package: boolean;
  packageId: string;
  countries: CountryInfo[];
  daysPercentLeft: number;
  dataPercentLeft: number;
  active: boolean;
  iso: string;
  dateEnd: string;
  unlimited: boolean;
  canTopUp: boolean;
}

export interface PostponePlan {
  id: string;
  name: string;
  validityPeriod: string;
  totalMb: string;
  iso: string;
  packageId: string;
  available: boolean;
}

export interface YcoinsBonusProgram {
  bonusFrom: number;
  bonusAmount: number;
  available: boolean;
}

export interface EsimStatus {
  active: boolean;
  iso: string;
  country: string;
}

export interface PayGoPlan {
  active: boolean;
  wasActive: boolean;
  amountMb: string;
}

export interface EsimInstalledInfo {
  installed: boolean;
}

export interface UserProfile {
  email: string;
  id: string;
  countOfPurchases: number;
  ycoinsCountPurchases: number;
  activationsCount: number;
  haveEsimProfile: boolean;
  showFree500: boolean;
  ycoins: number;
  friendsAttracted: number;
  referralCode: string;
  classicPlan: ClassicPlan;
  postponePlan: PostponePlan;
  ycoinsBonusProgram: YcoinsBonusProgram;
  esimStatus: EsimStatus;
  payGoPlan: PayGoPlan;
  esimInstalledInfo: EsimInstalledInfo;
  coinsFromReferralFriends: number;
  throttling: {
    limit: number | null;
    speed: number | null;
  };
  ycoinsTopUpInfo: {
    start: number;
    max: number;
    min: number;
    preset: number[];
    minAuto: number;
    autoTopUpAmount: number;
  };
  lastPaymentMethod: {
    iso: string;
    system: PaymentMethods;
    type: string;
  };
  lang: string;
  lastPurchasedIso: string[];
  paymentCurrency: Currency;
  did_show: boolean;
  isoIp: string;
}

export interface SaveProfile {
  type: typeof SAVE_PROFILE;
  payload: UserProfile;
}

export interface UpdateEmail {
  type: typeof UPDATE_EMAIL;
  payload: string;
}
export interface SetRating {
  type: typeof SET_RATING;
  payload: 1 | 0;
}

export interface ChangeCurrency {
  type: typeof CHANGE_CURRENCY;
  payload: Currency;
}

export interface ChangeCurrencyCode {
  type: typeof CHANGE_CURRENCY_CODE;
  payload: string;
}

export type ProfileActions =
  | SaveProfile
  | UpdateEmail
  | SetRating
  | ChangeCurrency
  | ChangeCurrencyCode
  | LogOut;
