import React from 'react';
import CopyIcon from '../../assets/icons/ic_copy.svg';
import styles from './WalletBalance.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import YcoinsIcon from '../../assets/images/ycoins.svg';
import Button from '../Button';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useHistory } from 'react-router-dom';

const WalletBalance: React.FC = () => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const profile = useSelector((state: RootState) => state.userProfile);
  const history = useHistory();

  const onCopyReferralCode = () => {
    navigator.clipboard.writeText(profile.referralCode);
    setSnackbar({
      message: t('copied'),
      type: 'success',
    });
  };

  return (
    <div className={styles.container}>
      <h2>{t('yourBalance')}</h2>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.balance}>
            <img src={YcoinsIcon} alt="Ycoins" className={styles.icon} />
            <span className={styles.ycoins}>{profile.ycoins}</span>
          </div>
          <p className={styles.text}>{t('referYourFriends')}</p>
        </div>
        <div className={styles.referralCode}>
          <p className={styles.label}>{t('referralCode')}</p>
          <span className={styles.input}>{profile.referralCode}</span>
          <img
            src={CopyIcon}
            alt="Copy"
            className={styles.copyIcon}
            onClick={onCopyReferralCode}
            title={t('copy')}
          />
        </div>
        <Button
          label={t('topUpWallet')}
          variant="plain"
          size="small"
          className={styles.button}
          onClick={() => history.push('/ycoins')}
        />
      </div>
    </div>
  );
};

export default WalletBalance;
