import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/currenciesReducer/currenciesReducer';
import { Currency } from '../reducers/currenciesReducer/currenciesReducer.types';
import { actions as UserProfileActions } from '../reducers/userProfileReducer/userProfileReducer';

export const getCurrencies = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.currencies.getCurrencies();
    dispatch(actions.saveCurrencies(res));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const changeCurrency =
  (currency: Currency): AppThunk =>
  async (dispatch, getState) => {
    try {
      const isLoggedIn = getState().userProfile.id;
      if (isLoggedIn) {
        await Api.currencies.changeCurrency(currency.code);
      }
      dispatch(UserProfileActions.changeCurrency(currency));
      localStorage.setItem('currency', currency.code);
      return;
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

export const checkCurrency = (): AppThunk => async (dispatch) => {
  try {
    let currency;
    const data = localStorage.getItem('currency');

    try {
      currency = JSON.parse(data || '');
    } catch (e) {
      currency = data;
    }

    if (currency) {
      dispatch(
        UserProfileActions.changeCurrencyCode(
          currency.code ? currency.code : currency,
        ),
      );
    }
    return;
  } catch (error) {}
};

export const updateCurrency = (): AppThunk => async () => {
  try {
    let currency;
    const data = localStorage.getItem('currency');

    try {
      currency = JSON.parse(data || '');
    } catch (e) {
      currency = data;
    }

    if (currency) {
      await Api.currencies.changeCurrency(
        currency.code ? currency.code : currency,
      );
    }

    return;
  } catch (error) {}
};
