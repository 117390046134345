import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import Button from '../Button';
import styles from './InternationalEsim.module.scss';
import WorldImage from '../../assets/images/world.png';

const InternationalEsim: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const profile = useSelector((state: RootState) => state.userProfile);

  function onClick(e: any) {
    e.stopPropagation();
    history.push({
      pathname: profile.payGoPlan.wasActive
        ? '/international-esim'
        : '/international-esim-introduction',
      search,
    });
    pushDataLayerEvent({
      event: 'select_item',
      ecommerce: {
        item_list_id: 'countries',
        item_list_name: 'Countries',
        items: [
          {
            item_id: 'pay_as_you_go',
            item_name: 'Pay as You Go',
            item_list_id: 'countries',
            item_list_name: 'Countries',
          },
        ],
      },
    });
  }

  return (
    <div className={styles.container} onClick={onClick}>
      <h2 className={styles.title}>{t('internationalEsimPlan')}</h2>
      <p className={styles.description}>{t('unlimitedData')}</p>
      <Button
        label={t('allCountriesInOneEsim')}
        variant="plain"
        className={styles.button}
        onClick={onClick}
      />
      <img src={WorldImage} alt="International eSIM" className={styles.image} />
    </div>
  );
};

export default InternationalEsim;
