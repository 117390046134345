import { Country, Package } from '../../../globalTypes';

export const SAVE_COUNTRIES = 'profile/SAVE_COUNTRIES';
export const RESET_COUNTRIES = 'profile/RESET_COUNTRIES';

export interface CountriesPackagesState {
  countries: Country[];
  packages: Package[];
  globalPackages: Package[];
  count: number;
}

export interface SaveCountriesPackages {
  type: typeof SAVE_COUNTRIES;
  payload: CountriesPackagesState;
}
export interface ResetCountriesPackages {
  type: typeof RESET_COUNTRIES;
}

export type CountriesPackagesActions =
  | SaveCountriesPackages
  | ResetCountriesPackages;
