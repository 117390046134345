import React from 'react';
import BadgeImage from '../../assets/images/google_play_badge.svg';
import BadgeAlternateImage from '../../assets/images/google_play_badge_alternate.svg';

type GooglePlayBadgeProps = {
  alternate?: boolean;
  link?: string;
  className?: string;
};

const GooglePlayBadge: React.FC<GooglePlayBadgeProps> = ({
  alternate,
  link,
  className,
}) => {
  return (
    <a
      href={
        link || 'https://play.google.com/store/apps/details?id=com.yesimmobile'
      }
      target="_blank"
      rel="noreferrer"
      id="google-play-badge"
    >
      <img
        src={alternate ? BadgeAlternateImage : BadgeImage}
        alt="Google Play"
        className={className}
      />
    </a>
  );
};

export default GooglePlayBadge;
