import React from 'react';
import styles from './Collapsible.module.scss';
import ChevronDownIcon from '../../assets/icons/ic_chevron_down_hover.svg';
import ChevronUpIcon from '../../assets/icons/ic_chevron_up_hover.svg';

type CollapsibleProps = {
  label: string;
  children: React.ReactNode;
};

const Collapsible: React.FC<CollapsibleProps> = ({ label, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <button className={styles.collapsible} onClick={() => setOpen(!isOpen)}>
        <span className={styles.label}>{label}</span>
        <img
          src={isOpen ? ChevronUpIcon : ChevronDownIcon}
          alt="Chevron"
          className={styles.icon}
        />
      </button>
      {isOpen && <div className={styles.content}>{children}</div>}
    </>
  );
};

export default Collapsible;
