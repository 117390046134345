import { Token } from '../reducers/tokenReducer/tokenReducer.types';
import * as AuthAPI from '../../api/auth';
import { updateCurrency } from './currenciesThunk';
import * as ProfileThunks from './profileThunks';
import { actions as TokenActions } from '../reducers/tokenReducer/tokenReducer';
import { AppThunk } from '..';
import { GoogleAuthService } from 'src/services/google_auth_service';
import { AppleAuthService } from 'src/services/apple_auth_service';
import { UserProfile } from '../reducers/userProfileReducer/userProfileReducer.types';
import { AxiosError } from 'axios';
import { sendPartnerLinkData } from '../../utils/partnerLinkActions';

export interface AuthResponse {
  firstAuth?: boolean;
  profile: UserProfile;
}

export const checkAuth =
  (): AppThunk<Promise<UserProfile | undefined>> => async (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        dispatch(TokenActions.saveToken({ sessionId: token }));
        return await dispatch(ProfileThunks.getProfileData());
      } catch (e: any) {}
    }
  };

export const googleAuth =
  (): AppThunk<Promise<boolean | undefined>> => async (dispatch) => {
    let serverToken: Token;
    const googleAuthService = new GoogleAuthService();

    const accessToken = await googleAuthService.getTokens();

    try {
      serverToken = await AuthAPI.sideAuth({
        provider: 'google',
        accessToken,
      });
      dispatch(TokenActions.saveToken(serverToken));
    } catch (error: any) {
      googleAuthService.logOut()?.catch(console.log);
      return Promise.reject(error);
    }

    localStorage.setItem('token', serverToken.sessionId);
    return serverToken.firstAuth;
  };

export const appleAuth =
  (): AppThunk<Promise<boolean | undefined>> => async (dispatch) => {
    const appleAuthService = new AppleAuthService();
    const credential = await appleAuthService.getTokens();
    let token: Token;
    try {
      token = await AuthAPI.sideAuth({
        provider: 'apple',
        accessToken: credential.accessToken,
        givenName: credential.givenName,
        familyName: credential.familyName,
      });
      localStorage.setItem('token', token.sessionId);
      await dispatch(TokenActions.saveToken(token));
    } catch (error: any) {
      return Promise.reject(error);
    }

    return token.firstAuth;
  };

export const logOut = (): AppThunk => async (dispatch) => {
  try {
    await localStorage.removeItem('token');
    dispatch({ type: 'LOG_OUT' });

    new GoogleAuthService().logOut()?.catch(console.log);
    sessionStorage.clear();
    return true;
  } catch (error: any) {
    //
  }
};

export const authWithCodeThunk =
  (
    email: string,
    code: string,
    firstAuth: boolean,
  ): AppThunk<Promise<UserProfile>> =>
  async (dispatch) => {
    let token: Token;
    try {
      token = await AuthAPI.checkEmailCode(email, code);
      dispatch(TokenActions.saveToken(token));
      await sendPartnerLinkData({ firstAuth });
      await localStorage.setItem('token', token.sessionId);
      await dispatch(updateCurrency());
      return await dispatch(ProfileThunks.getProfileData());
    } catch (error: any) {
      const e = error as AxiosError;
      if (e.isAxiosError && e.response?.data?.['attemps']) {
        return Promise.reject({
          badCode: true,
          attemps: e.response?.data?.['attemps'],
        });
      } else {
        return Promise.reject(error);
      }
    }
  };
