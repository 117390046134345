import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './Agreements.module.scss';

type AgreementsProps = {
  buttonText?: string;
};

const Agreements: React.FC<AgreementsProps> = ({ buttonText }) => {
  const { t } = useTranslation();

  return (
    <p className={styles.content} id="agreements">
      <Trans
        i18nKey="termsPrivacy"
        values={{ button: buttonText || t('continue') }}
        components={{
          link1: (
            <a
              href="https://yesim.app/terms_of_service_yesim.pdf"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            />
          ),
          link2: (
            <a
              href="https://yesim.app/privacy_policy_yesim.pdf"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            />
          ),
          link3: (
            <a
              href="https://yesim.app/cof_yesim.pdf"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            />
          ),
        }}
      />
      <span className={styles.address}>{t('purchase.credsAddress')}</span>
    </p>
  );
};

export default Agreements;
