import qs from 'query-string';
import React from 'react';
import styles from './PayAsYouGoIntroductionPage.module.scss';
import BackIcon from '../../assets/icons/ic_back_white.svg';
import PayAsYouGoImage from '../../assets/images/pay_as_you_go.svg';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const PayAsYouGoIntroductionPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const isLoggedIn = profile.id;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={BackIcon}
          alt="Back"
          className={styles.back}
          onClick={() => history.push('/countries')}
        />
        <img
          src={PayAsYouGoImage}
          alt="Pay as You Go"
          className={styles.image}
        />
        <h2 className={styles.subtitle}>{t('unlimDataPlan')}</h2>
        <h1 className={styles.title}>{t('internationalEsimPlan')}</h1>
        <p className={styles.text}>{t('seamlessInternet')}</p>
      </div>
      <Button
        label={t('continue')}
        variant="secondary"
        onClick={() => {
          if (isLoggedIn) {
            history.push('/international-esim');
          } else {
            history.push({
              pathname: '/login',
              search: qs.stringify({
                redirectTo: '/international-esim',
              }),
            });
          }
        }}
      />
    </div>
  );
};

export default PayAsYouGoIntroductionPage;
