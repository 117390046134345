import { Api } from '../api';
import { CountryInfo, PaymentMethods } from '../globalTypes';
import {
  ClassicPlan,
  EsimInstalledInfo,
  EsimStatus,
  PayGoPlan,
  PostponePlan,
  UserProfile,
  YcoinsBonusProgram,
} from '../store/reducers/userProfileReducer/userProfileReducer.types';
import moment from 'moment';

const kFriendsBonuses: { [key: number]: number } = {
  5: 300, // приглшено 5 друзей - бонус +300. итого за 5 друзей 5*200 + 300 = 1300
  10: 600,
  20: 900,
  30: 1200,
  40: 1500,
  50: 2000,
};

export class ProfileService {
  async getProfile(): Promise<UserProfile> {
    const response = await Api.auth.getProfile();

    return this._parseProfile(response);
  }

  private _parseProfile(json: any): UserProfile {
    const friendsAttracted = +json['friends_attracted'];
    let _coinsFromReferralFriends = friendsAttracted * 200;

    for (const key in kFriendsBonuses) {
      if (friendsAttracted >= +key) {
        _coinsFromReferralFriends += kFriendsBonuses[key];
      }
    }
    const coinsFromReferralFriends = _coinsFromReferralFriends;

    const payGoPlan = this._parsePayGoPlan(json['pay_go_plan']);
    const countOfPurchases = +json['count_of_purchases'];
    const ycoinsCountPurchases = +json['ycoins_count_purchases'];

    const showFree500 =
      countOfPurchases - ycoinsCountPurchases == 0 && !payGoPlan.wasActive;

    return {
      id: json['id'],
      email: json['email'],
      activationsCount: +json['activations_count'],
      countOfPurchases: countOfPurchases,
      ycoinsCountPurchases: ycoinsCountPurchases,
      classicPlan: this._parseClassicPlan(json),
      esimStatus: this._parseEsimStatus(json),
      friendsAttracted: friendsAttracted,
      haveEsimProfile: json['have_esim_profile'],
      postponePlan: this._parsePostponePlan(json),
      referralCode: json['referral_code'],
      ycoins: +json['ycoins'],
      ycoinsBonusProgram: this._parseYcoinsBonusProgram(json),
      payGoPlan: payGoPlan,
      esimInstalledInfo: this._parseEsimInstalledInfo(json),
      showFree500: showFree500,
      coinsFromReferralFriends: coinsFromReferralFriends,
      throttling: {
        limit: json.throttling?.limit,
        speed: json.throttling?.speed,
      },
      ycoinsTopUpInfo: {
        start: Number(json.ycoins_top_up_info?.start),
        max: Number(json.ycoins_top_up_info?.max),
        min: Number(json.ycoins_top_up_info?.min),
        preset: json.ycoins_top_up_info?.preset?.map((i: string) => Number(i)),
        minAuto: Number(json.ycoins_top_up_info?.min_auto),
        autoTopUpAmount: Number(json.ycoins_top_up_info?.auto_top_up),
      },
      lastPaymentMethod: {
        iso: (json.last_payment_method?.iso ?? '').toLowerCase(),
        system: (
          json.last_payment_method?.system || PaymentMethods.UNKNOWN
        ).toLowerCase(),
        type: (json.last_payment_method?.type ?? '').toLowerCase(),
      },
      lang: json.lang,
      lastPurchasedIso: json.last_purchased_iso || [],
      paymentCurrency: json.payment_currency,
      did_show: json.did_show,
      isoIp: json.iso_ip,
    };
  }

  _parsePayGoPlan(json: any): PayGoPlan {
    return {
      active: json['active'],
      wasActive: json['was_active'],
      amountMb: json['amount_mb'],
    };
  }

  _parseClassicPlan(json: any): ClassicPlan {
    const data = json['classic_plan'];

    if (!data['active']) {
      return {
        id: '',
        countries: [],
        dataPercentLeft: 0,
        daysPercentLeft: 0,
        duration: '0',
        package: false,
        totalMb: '0',
        active: false,
        availableMb: '0',
        name: '',
        iso: '',
        dateEnd: '',
        packageId: '',
        unlimited: false,
        canTopUp: false,
      };
    }

    const planId = data['id'];
    const isPackage = data['package'];
    const availableMb = data['available_mb'];
    const totalMb = data['total_mb'];
    const name = data['name'];
    const duration = data['duration'];
    const countries = (data['countries'] as Array<any>).map(
      (data: any): CountryInfo => ({
        name: data['country'],
        iso: data['iso'],
        cost: data['cost'],
        coins: data['coins'],
      }),
    );
    const daysPercentLeft = +data['days_percent_left'];
    const dataPercentLeft = +data['data_percent_left'];
    const dateEnd = moment(data['end_date']).format('DD MMMM');
    const canTopUp = data['can_top_up'];

    if (isPackage) {
      return {
        id: planId,
        countries: countries,
        dataPercentLeft: dataPercentLeft,
        daysPercentLeft: daysPercentLeft,
        duration: duration,
        package: true,
        totalMb: totalMb,
        active: true,
        availableMb: availableMb,
        name: name,
        iso: '',
        dateEnd: dateEnd,
        packageId: data['package_id'] ?? '',
        unlimited: data['unlimited'],
        canTopUp,
      };
    } else {
      return {
        id: planId,
        countries: [],
        dataPercentLeft: dataPercentLeft,
        daysPercentLeft: daysPercentLeft,
        duration: duration,
        package: false,
        totalMb: totalMb,
        active: true,
        availableMb: availableMb,
        name: name,
        iso: countries[0].iso,
        dateEnd: dateEnd,
        packageId: data['package_id'] ?? '',
        unlimited: data['unlimited'],
        canTopUp,
      };
    }
  }

  _parseEsimStatus(json: any): EsimStatus {
    const data = json['esim_status'];
    return {
      active: data['active'],
      iso: data['iso'] ?? '',
      country: data['country'] ?? '',
    };
  }

  _parsePostponePlan(json: any): PostponePlan {
    const empty: PostponePlan = {
      available: false,
      id: '',
      name: '',
      totalMb: '',
      validityPeriod: '',
      iso: '',
      packageId: '',
    };
    try {
      const data = json['postpone_plan'];
      const available = data['available'];
      if (available) {
        return {
          id: data['id'] ?? '',
          name: data['name'] ?? '',
          iso: data['iso'] ?? '',
          packageId: data['package_id'] ?? '',
          available: true,
          validityPeriod: data['period'] ?? '',
          totalMb: data['total_mb'] ?? '',
        };
      }
      return empty;
    } catch (e) {
      return empty;
    }
  }

  _parseYcoinsBonusProgram(json: any): YcoinsBonusProgram {
    const data = json['ycoins_bonus_program'];
    return {
      available: data['available'],
      bonusAmount: +data['bonus_amount'],
      bonusFrom: +data['bonus_from'],
    };
  }

  _parseEsimInstalledInfo(json: any): EsimInstalledInfo {
    const data = json['esim_installed_info'];
    return {
      installed: data['status'],
      // showAfterInstallInfo: data['showAfterInstallInfo'],
    };
  }
}
