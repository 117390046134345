import React, { useMemo, useState } from 'react';
import styles from './CreateInvoiceModal.module.scss';
import Modal from '../Modal';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import { getInvoiceId } from '../../api/common';
import InputBase from '../InputBase';
import CloseIcon from '../../assets/icons/ic_close_circle.svg';
import Loader from '../Loader';
import useLoadingStatus from '../../hooks/useLoadingStatus';

type CreateInvoiceModalProps = {
  open: boolean;
  onClose: () => void;
  currency: string;
  paymentId: string;
  planName: string;
  purchaseDate: string;
  purchaseAmount: string;
};

const countries = [
  { name: 'Afghanistan', iso: 'AF' },
  { name: 'Albania', iso: 'AL' },
  { name: 'Algeria', iso: 'DZ' },
  { name: 'Andorra', iso: 'AD' },
  { name: 'Angola', iso: 'AO' },
  { name: 'Antigua and Barbuda', iso: 'AG' },
  { name: 'Argentina', iso: 'AR' },
  { name: 'Armenia', iso: 'AM' },
  { name: 'Australia', iso: 'AU' },
  { name: 'Austria', iso: 'AT' },
  { name: 'Azerbaijan', iso: 'AZ' },
  { name: 'Bahamas', iso: 'BS' },
  { name: 'Bahrain', iso: 'BH' },
  { name: 'Bangladesh', iso: 'BD' },
  { name: 'Barbados', iso: 'BB' },
  { name: 'Belarus', iso: 'BY' },
  { name: 'Belgium', iso: 'BE' },
  { name: 'Belize', iso: 'BZ' },
  { name: 'Benin', iso: 'BJ' },
  { name: 'Bhutan', iso: 'BT' },
  { name: 'Bolivia', iso: 'BO' },
  { name: 'Bosnia and Herzegovina', iso: 'BA' },
  { name: 'Botswana', iso: 'BW' },
  { name: 'Brazil', iso: 'BR' },
  { name: 'Brunei Darussalam', iso: 'BN' },
  { name: 'Bulgaria', iso: 'BG' },
  { name: 'Burkina Faso', iso: 'BF' },
  { name: 'Burundi', iso: 'BI' },
  { name: 'Cabo Verde', iso: 'CV' },
  { name: 'Cambodia', iso: 'KH' },
  { name: 'Cameroon', iso: 'CM' },
  { name: 'Canada', iso: 'CA' },
  { name: 'Central African Republic', iso: 'CF' },
  { name: 'Chad', iso: 'TD' },
  { name: 'Chile', iso: 'CL' },
  { name: 'China', iso: 'CN' },
  { name: 'Colombia', iso: 'CO' },
  { name: 'Comoros', iso: 'KM' },
  { name: 'Congo (Congo-Brazzaville)', iso: 'CG' },
  { name: 'Costa Rica', iso: 'CR' },
  { name: 'Croatia', iso: 'HR' },
  { name: 'Cuba', iso: 'CU' },
  { name: 'Cyprus', iso: 'CY' },
  { name: 'Czechia (Czech Republic)', iso: 'CZ' },
  { name: 'Democratic Republic of the Congo', iso: 'CD' },
  { name: 'Denmark', iso: 'DK' },
  { name: 'Djibouti', iso: 'DJ' },
  { name: 'Dominica', iso: 'DM' },
  { name: 'Dominican Republic', iso: 'DO' },
  { name: 'Ecuador', iso: 'EC' },
  { name: 'Egypt', iso: 'EG' },
  { name: 'El Salvador', iso: 'SV' },
  { name: 'Equatorial Guinea', iso: 'GQ' },
  { name: 'Eritrea', iso: 'ER' },
  { name: 'Estonia', iso: 'EE' },
  { name: 'Eswatini (fmr. Swaziland)', iso: 'SZ' },
  { name: 'Ethiopia', iso: 'ET' },
  { name: 'Fiji', iso: 'FJ' },
  { name: 'Finland', iso: 'FI' },
  { name: 'France', iso: 'FR' },
  { name: 'Gabon', iso: 'GA' },
  { name: 'Gambia', iso: 'GM' },
  { name: 'Georgia', iso: 'GE' },
  { name: 'Germany', iso: 'DE' },
  { name: 'Ghana', iso: 'GH' },
  { name: 'Greece', iso: 'GR' },
  { name: 'Grenada', iso: 'GD' },
  { name: 'Guatemala', iso: 'GT' },
  { name: 'Guinea', iso: 'GN' },
  { name: 'Guinea-Bissau', iso: 'GW' },
  { name: 'Guyana', iso: 'GY' },
  { name: 'Haiti', iso: 'HT' },
  { name: 'Honduras', iso: 'HN' },
  { name: 'Hungary', iso: 'HU' },
  { name: 'Iceland', iso: 'IS' },
  { name: 'India', iso: 'IN' },
  { name: 'Indonesia', iso: 'ID' },
  { name: 'Iran', iso: 'IR' },
  { name: 'Iraq', iso: 'IQ' },
  { name: 'Ireland', iso: 'IE' },
  { name: 'Israel', iso: 'IL' },
  { name: 'Italy', iso: 'IT' },
  { name: 'Jamaica', iso: 'JM' },
  { name: 'Japan', iso: 'JP' },
  { name: 'Jordan', iso: 'JO' },
  { name: 'Kazakhstan', iso: 'KZ' },
  { name: 'Kenya', iso: 'KE' },
  { name: 'Kiribati', iso: 'KI' },
  { name: 'Kuwait', iso: 'KW' },
  { name: 'Kyrgyzstan', iso: 'KG' },
  { name: 'Laos', iso: 'LA' },
  { name: 'Latvia', iso: 'LV' },
  { name: 'Lebanon', iso: 'LB' },
  { name: 'Lesotho', iso: 'LS' },
  { name: 'Liberia', iso: 'LR' },
  { name: 'Libya', iso: 'LY' },
  { name: 'Liechtenstein', iso: 'LI' },
  { name: 'Lithuania', iso: 'LT' },
  { name: 'Luxembourg', iso: 'LU' },
  { name: 'Madagascar', iso: 'MG' },
  { name: 'Malawi', iso: 'MW' },
  { name: 'Malaysia', iso: 'MY' },
  { name: 'Maldives', iso: 'MV' },
  { name: 'Mali', iso: 'ML' },
  { name: 'Malta', iso: 'MT' },
  { name: 'Marshall Islands', iso: 'MH' },
  { name: 'Mauritania', iso: 'MR' },
  { name: 'Mauritius', iso: 'MU' },
  { name: 'Mexico', iso: 'MX' },
  { name: 'Micronesia', iso: 'FM' },
  { name: 'Moldova', iso: 'MD' },
  { name: 'Monaco', iso: 'MC' },
  { name: 'Mongolia', iso: 'MN' },
  { name: 'Montenegro', iso: 'ME' },
  { name: 'Morocco', iso: 'MA' },
  { name: 'Mozambique', iso: 'MZ' },
  { name: 'Myanmar', iso: 'MM' },
  { name: 'Namibia', iso: 'NA' },
  { name: 'Nauru', iso: 'NR' },
  { name: 'Nepal', iso: 'NP' },
  { name: 'Netherlands', iso: 'NL' },
  { name: 'New Zealand', iso: 'NZ' },
  { name: 'Nicaragua', iso: 'NI' },
  { name: 'Niger', iso: 'NE' },
  { name: 'Nigeria', iso: 'NG' },
  { name: 'North Korea', iso: 'KP' },
  { name: 'North Macedonia', iso: 'MK' },
  { name: 'Norway', iso: 'NO' },
  { name: 'Oman', iso: 'OM' },
  { name: 'Pakistan', iso: 'PK' },
  { name: 'Palau', iso: 'PW' },
  { name: 'Palestine', iso: 'PS' },
  { name: 'Panama', iso: 'PA' },
  { name: 'Papua New Guinea', iso: 'PG' },
  { name: 'Paraguay', iso: 'PY' },
  { name: 'Peru', iso: 'PE' },
  { name: 'Philippines', iso: 'PH' },
  { name: 'Poland', iso: 'PL' },
  { name: 'Portugal', iso: 'PT' },
  { name: 'Qatar', iso: 'QA' },
  { name: 'Romania', iso: 'RO' },
  { name: 'Russia', iso: 'RU' },
  { name: 'Rwanda', iso: 'RW' },
  { name: 'Saint Kitts and Nevis', iso: 'KN' },
  { name: 'Saint Lucia', iso: 'LC' },
  { name: 'Saint Vincent and the Grenadines', iso: 'VC' },
  { name: 'Samoa', iso: 'WS' },
  { name: 'San Marino', iso: 'SM' },
  { name: 'Sao Tome and Principe', iso: 'ST' },
  { name: 'Saudi Arabia', iso: 'SA' },
  { name: 'Senegal', iso: 'SN' },
  { name: 'Serbia', iso: 'RS' },
  { name: 'Seychelles', iso: 'SC' },
  { name: 'Sierra Leone', iso: 'SL' },
  { name: 'Singapore', iso: 'SG' },
  { name: 'Slovakia', iso: 'SK' },
  { name: 'Slovenia', iso: 'SI' },
  { name: 'Solomon Islands', iso: 'SB' },
  { name: 'Somalia', iso: 'SO' },
  { name: 'South Africa', iso: 'ZA' },
  { name: 'South Korea', iso: 'KR' },
  { name: 'South Sudan', iso: 'SS' },
  { name: 'Spain', iso: 'ES' },
  { name: 'Sri Lanka', iso: 'LK' },
  { name: 'Sudan', iso: 'SD' },
  { name: 'Suriname', iso: 'SR' },
  { name: 'Sweden', iso: 'SE' },
  { name: 'Switzerland', iso: 'CH' },
  { name: 'Syria', iso: 'SY' },
  { name: 'Tajikistan', iso: 'TJ' },
  { name: 'Tanzania', iso: 'TZ' },
  { name: 'Thailand', iso: 'TH' },
  { name: 'Timor-Leste', iso: 'TL' },
  { name: 'Togo', iso: 'TG' },
  { name: 'Tonga', iso: 'TO' },
  { name: 'Trinidad and Tobago', iso: 'TT' },
  { name: 'Tunisia', iso: 'TN' },
  { name: 'Turkey', iso: 'TR' },
  { name: 'Turkmenistan', iso: 'TM' },
  { name: 'Tuvalu', iso: 'TV' },
  { name: 'Uganda', iso: 'UG' },
  { name: 'Ukraine', iso: 'UA' },
  { name: 'United Arab Emirates', iso: 'AE' },
  { name: 'United Kingdom', iso: 'GB' },
  { name: 'United States of America', iso: 'US' },
  { name: 'Uruguay', iso: 'UY' },
  { name: 'Uzbekistan', iso: 'UZ' },
  { name: 'Vanuatu', iso: 'VU' },
  { name: 'Venezuela', iso: 'VE' },
  { name: 'Vietnam', iso: 'VN' },
  { name: 'Yemen', iso: 'YE' },
  { name: 'Zambia', iso: 'ZM' },
  { name: 'Zimbabwe', iso: 'ZW' },
];

const CreateInvoiceModal: React.FC<CreateInvoiceModalProps> = ({
  open,
  onClose,
  currency,
  paymentId,
  planName,
  purchaseDate,
  purchaseAmount,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState(false);
  const [country, setCountry] = useState('');
  const [countryIso, setCountryIso] = useState('');
  const [countryError, setCountryError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [zipCodeError, setZipCodeError] = useState(false);
  const [vatNumber, setVatNumber] = useState('');
  const [vatNumberError, setVatNumberError] = useState(false);
  const [countryInputFocus, setCountryInputFocus] = useState(false);

  const countriesList = useMemo(
    () =>
      countries.filter((i) =>
        i.name.toLowerCase().includes(country.toLowerCase()),
      ),
    [country],
  );

  function numberToWords(value: number): string {
    const belowTwenty = [
      'Zero',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ];
    const tens = [
      '',
      '',
      'Twenty',
      'Thirty',
      'Forty',
      'Fifty',
      'Sixty',
      'Seventy',
      'Eighty',
      'Ninety',
    ];
    if (value < 20) {
      return belowTwenty[value];
    }
    if (value < 100) {
      return (
        tens[Math.floor(value / 10)] +
        (value % 10 === 0 ? '' : ' ' + belowTwenty[value % 10])
      );
    }
    if (value < 1000) {
      return (
        belowTwenty[Math.floor(value / 100)] +
        ' Hundred' +
        (value % 100 === 0 ? '' : ' ' + numberToWords(value % 100))
      );
    }
    return value.toString();
  }

  function convertAmountToWords(value: string) {
    const parts = value.replace(',', '.').split('.');
    const amount = parseInt(parts[0], 10);
    const cents = parts[1] || '0';
    const amountInWords = numberToWords(amount);
    return `Balance DUE: ${amountInWords} ${currency} ${cents} cents`;
  }

  async function generateInvoice() {
    if (
      companyName &&
      vatNumber &&
      country &&
      countryIso &&
      address &&
      zipCode
    ) {
      try {
        setLoading('pending');
        const invoiceId = await getInvoiceId(paymentId);
        const invoiceNumber = 'MP-' + invoiceId.toString().padStart(6, '0');
        const customerName =
          companyName + (vatNumber ? ` VAT: ${vatNumber}` : '');
        const companyAddress =
          address +
          (zipCode ? `, ${zipCode}` : '') +
          (country ? `, ${country}` : '');
        const doc = new jsPDF({
          putOnlyUsedFonts: true,
          orientation: 'portrait',
        });
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(13);
        doc.text('Genesis Group AG', 105, 30, { align: 'center' });
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        doc.text('Chamerstrasse 172, 6300 ZUG, Switzerland', 105, 36, {
          align: 'center',
        });
        doc.setFontSize(10);
        doc.text('invoice@dialoq.com', 105, 42, { align: 'center' });
        doc.setFontSize(12);
        doc.text('Customer Invoice #:', 10, 60);
        doc.text(invoiceNumber, 52, 60);
        doc.text('Date:', 10, 66);
        doc.text(moment(new Date()).format('DD.MM.YYYY'), 52, 66);
        doc.text('Payment Due:', 10, 72);
        doc.text(`${purchaseDate}`, 52, 72);
        doc.text('Customer Name:', 10, 80);
        doc.setFont('helvetica', 'bold');
        doc.text(customerName, 52, 80, { maxWidth: 144 });
        doc.setFont('helvetica', 'normal');
        const addressTextY =
          customerName.length > 50 ? (customerName.length > 100 ? 96 : 90) : 86;
        doc.text('Address:', 10, addressTextY);
        doc.text(companyAddress, 52, addressTextY, { maxWidth: 144 });
        const hasVat = countryIso === 'CH';
        const netAmount = hasVat
          ? (Number(purchaseAmount) / (1 + 8.1 / 100)).toFixed(2)
          : purchaseAmount;
        const vatAmount = (Number(purchaseAmount) - Number(netAmount)).toFixed(
          2,
        );
        const data = [
          {
            description: `eSIM plan ${planName}`,
            qty: '1',
            rate: netAmount,
            amount: netAmount,
          },
        ];
        const headers = [
          {
            id: 'description',
            name: 'description',
            prompt: 'Service Description',
            width: 140,
            align: 'center',
            padding: 0,
          },
          {
            id: 'qty',
            name: 'qty',
            prompt: 'Qty',
            width: 40,
            align: 'center',
            padding: 0,
          },
          {
            id: 'rate',
            name: 'rate',
            prompt: 'Rate',
            width: 40,
            align: 'center',
            padding: 0,
          },
          {
            id: 'amount',
            name: 'amount',
            prompt: 'Amount',
            width: 40,
            align: 'center',
            padding: 0,
          },
        ];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        doc.table(7.5, 114, data, headers, {
          fontSize: 10,
          headerBackgroundColor: '#FFFFFF',
        });
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`TOTAL (${currency}):`, 174, 155, { align: 'right' });
        doc.text(netAmount, 176, 155);
        if (hasVat) {
          doc.text(`VAT (${currency}):`, 174, 160, { align: 'right' });
          doc.text(vatAmount, 176, 160);
        }
        const debtTextY = hasVat ? 165 : 160;
        doc.text(`DEBT (${currency}):`, 174, debtTextY, { align: 'right' });
        doc.text('', 176, debtTextY);
        const balanceDueTextY = hasVat ? 170 : 165;
        doc.text(`Balance DUE (${currency}):`, 174, balanceDueTextY, {
          align: 'right',
        });
        doc.text(purchaseAmount, 176, balanceDueTextY);
        doc.text(
          convertAmountToWords(purchaseAmount),
          105,
          balanceDueTextY + 20,
          { align: 'center' },
        );
        doc.text('Beneficiary:', 10, 210);
        doc.setFont('helvetica', 'normal');
        doc.text(
          'Genesis Group AG, CHE-135.623.633 VAT: CHE-135.623.633 MWST',
          52,
          215,
        );
        doc.text('Chamerstrasse 172, 6300 ZUG, Switzerland', 52, 220);
        doc.text(
          'Bank: PostFinance AG, Mingerstrasse 20, 3030 Bern, Switzerland',
          52,
          225,
        );
        doc.text(
          `IBAN: ${
            currency === 'USD'
              ? 'CH0809000000916429521'
              : 'CH64 0900 0000 9176 3684 2'
          }`,
          52,
          230,
        );
        doc.text('Swift Code: POFICHBEXXX', 52, 235);
        doc.save(`Invoice_${invoiceNumber}.pdf`);
      } catch (e) {
        //
      } finally {
        setLoading('fulfilled');
        onClose();
      }
    } else {
      if (!companyName) {
        setCompanyNameError(true);
      }
      if (!vatNumber) {
        setVatNumberError(true);
      }
      if (!address) {
        setAddressError(true);
      }
      if (!zipCode) {
        setZipCodeError(true);
      }
      if (!country || !countryIso) {
        setCountryError(true);
      }
    }
  }

  function formatInputValue(value: string) {
    return value.replace(/[^A-Za-z\s0-9&+*/().,!?-]/g, '');
  }

  return (
    <Modal open={open} modalPresentationMobile onClose={onClose}>
      <div className={styles.content}>
        {loading === 'pending' ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <h2 className={styles.title}>{t('createInvoice')}</h2>
            <img
              src={CloseIcon}
              alt="Close"
              className={styles.close}
              onClick={onClose}
            />
            <InputBase value={planName} disabled />
            <InputBase value={purchaseDate} disabled />
            <InputBase value={`${purchaseAmount} ${currency}`} disabled />
            <InputBase
              placeholder={t('companyName')}
              value={companyName}
              onChangeText={(value) => {
                if (companyNameError) {
                  setCompanyNameError(false);
                }
                setCompanyName(formatInputValue(value));
              }}
              maxLength={100}
              isError={companyNameError}
            />
            <InputBase
              placeholder={t('vatNumberGstin')}
              value={vatNumber}
              onChangeText={(value) => {
                if (vatNumberError) {
                  setVatNumberError(false);
                }
                setVatNumber(formatInputValue(value));
              }}
              maxLength={100}
              isError={vatNumberError}
            />
            <p>{`${t('companyAddress')}:`}</p>
            <div className={styles.field}>
              <InputBase
                placeholder={t('purchase.country')}
                value={country}
                onChangeText={(value) => {
                  if (countryError) {
                    setCountryError(false);
                  }
                  setCountry(formatInputValue(value));
                  if (value.length === 0) {
                    setCountryIso('');
                  }
                }}
                maxLength={60}
                isError={countryError}
                onFocus={() => setCountryInputFocus(true)}
                onBlur={() => {
                  setTimeout(() => setCountryInputFocus(false), 100);
                  if (
                    countriesList.length === 1 &&
                    countriesList[0].name
                      .toLowerCase()
                      .includes(country.toLowerCase())
                  ) {
                    setCountry(countriesList[0].name);
                    setCountryIso(countriesList[0].iso);
                  }
                }}
              />
              {countryInputFocus && (
                <ul className={styles.dropdown}>
                  {countriesList.length > 0 ? (
                    countriesList.map((i) => (
                      <li
                        key={i.iso}
                        onClick={() => {
                          setCountry(i.name);
                          setCountryIso(i.iso);
                        }}
                        className={styles.item}
                      >
                        {i.name}
                      </li>
                    ))
                  ) : (
                    <p className={styles.noResults}>{t('noResults')}</p>
                  )}
                </ul>
              )}
            </div>
            <InputBase
              placeholder={t('address')}
              value={address}
              onChangeText={(value) => {
                if (addressError) {
                  setAddressError(false);
                }
                setAddress(formatInputValue(value));
              }}
              maxLength={100}
              isError={addressError}
            />
            <InputBase
              placeholder={t('zip')}
              value={zipCode}
              onChangeText={(value) => {
                if (zipCodeError) {
                  setAddressError(false);
                }
                setZipCode(formatInputValue(value));
              }}
              maxLength={20}
              isError={zipCodeError}
            />
            <Button
              label={t('continue')}
              onClick={generateInvoice}
              variant="plain"
              className={styles.button}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default CreateInvoiceModal;
