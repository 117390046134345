import React from 'react';
import styles from './Badge.module.scss';

type BadgeProps = {
  content: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

const Badge: React.FC<BadgeProps> = ({ children, content, className }) => {
  return (
    <div className={styles.container}>
      {children}
      {content !== '0' && content !== 0 && (
        <span className={`${styles.badge} ${className}`}>{content}</span>
      )}
    </div>
  );
};

export default Badge;
