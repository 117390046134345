import React from 'react';
import styles from './Chip.module.scss';

type ChipProps = {
  text: string;
  icon?: string;
  className?: string;
};

const Chip: React.FC<ChipProps> = ({ text, icon, className }) => {
  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      <img src={icon} alt="Icon" className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Chip;
