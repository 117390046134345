import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CopyIcon from '../../assets/icons/ic_copy.svg';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import NumberSettings from '../../components/NumberSettings';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import { ActiveNumber } from '../../store/reducers/activeNumbersReducer/activeNumbersReducer.types';
import { getActiveNumbers } from '../../store/thunks/activeNumbersThunks';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import getFlag from '../../utils/getFlag';
import styles from './NumbersPage.module.scss';
import ServicesIcon from '../../assets/icons/ic_services.svg';
import PrivacyIcon from '../../assets/icons/ic_privacy.svg';
import NoIdentityIcon from '../../assets/icons/ic_no_identity.svg';
import FlexiblePaymentIcon from '../../assets/icons/ic_flexible_payment.svg';
import TimeIcon from '../../assets/icons/ic_time.svg';
import MessagesIcon from '../../assets/icons/ic_number_messages.svg';
import SettingsIcon from '../../assets/icons/ic_number_settings.svg';
import LinkIcon from '../../assets/icons/ic_external_url.svg';

const NumbersPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useThunkDispatch();
  const activeNumbers = useSelector((state: RootState) => state.activeNumbers);
  const [loading, setLoading] = useLoadingStatus('pending');
  const [selectedNumber, setSelectedNumber] = useState<ActiveNumber | null>(
    null,
  );
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    async function inti() {
      await dispatch(getActiveNumbers());
      setLoading('fulfilled');
    }

    inti();
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {activeNumbers.length > 0 ? (
        <div className={styles.content}>
          <div className={styles.top}>
            <h2 className={styles.title}>{t('activeNumbers')}</h2>
            <button
              className={styles.archive}
              onClick={() => history.push('/numbers-archive')}
            >
              {t('archive')}
            </button>
          </div>
          <ul className={styles.list}>
            {activeNumbers.map((item, index) => (
              <li
                key={index}
                className={styles.item}
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/senders',
                    search: qs.stringify({
                      number: item.number,
                    }),
                  });
                }}
              >
                <div className={styles.wrapper}>
                  <img
                    src={getFlag(item.iso)}
                    alt="Flag"
                    className={styles.flag}
                  />
                  <div className={styles.data}>
                    <div
                      className={styles.numberWrapper}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                          formatPhoneNumber(item.number, item.code),
                        );
                        setSnackbar({
                          message: t('copied'),
                          type: 'success',
                        });
                      }}
                    >
                      <p className={styles.number}>
                        {formatPhoneNumber(item.number, item.code)}
                      </p>
                      <img
                        src={CopyIcon}
                        alt="Copy"
                        className={styles.copyIcon}
                        title={t('copy')}
                      />
                    </div>
                    <p className={styles.text}>
                      {item.grace_period
                        ? `${t('activeUntil')} ${moment(
                            item.grace_period,
                          ).format('D MMMM, YYYY')}`
                        : item.subscription
                        ? t('nextCharge', {
                            date: moment(item.active_untill).format(
                              'D MMMM, YYYY',
                            ),
                          })
                        : `${t('activeUntil')} ${moment(
                            item.active_untill,
                          ).format('D MMMM, YYYY')}`}
                    </p>
                  </div>
                  <Badge content={item.unread_sms}>
                    <img
                      src={MessagesIcon}
                      alt="Messages"
                      className={styles.icon}
                    />
                  </Badge>
                  <img
                    src={SettingsIcon}
                    alt="Settings"
                    className={styles.icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedNumber(item);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.button}>
            <Button
              label={t('buyNumber')}
              onClick={() => history.push('/select-country-service')}
              variant="plain"
            />
          </div>
        </div>
      ) : (
        <div className={styles.banner}>
          <div className={styles.top}>
            <h2 className={styles.title}>{t('virtualNumbers')}</h2>
            <button
              className={styles.archive}
              onClick={() => history.push('/numbers-archive')}
            >
              {t('archive')}
            </button>
          </div>
          <ul className={styles.description}>
            <li className={styles.item}>
              <img src={ServicesIcon} alt="Icon" className={styles.icon} />
              <p className={styles.text}>{t('numbersDesc1')}</p>
            </li>
            <li className={styles.item}>
              <img src={PrivacyIcon} alt="Icon" className={styles.icon} />
              <p className={styles.text}>{t('numbersDesc2')}</p>
            </li>
            <li className={styles.item}>
              <img src={NoIdentityIcon} alt="Icon" className={styles.icon} />
              <p className={styles.text}>{t('numbersDesc3')}</p>
            </li>
            <li className={styles.item}>
              <img
                src={FlexiblePaymentIcon}
                alt="Icon"
                className={styles.icon}
              />
              <p className={styles.text}>{t('numbersDesc4')}</p>
            </li>
            <li className={styles.item}>
              <img src={TimeIcon} alt="Icon" className={styles.icon} />
              <p className={styles.text}>{t('numbersDesc5')}</p>
            </li>
          </ul>
          <Button
            label={t('buyNumber')}
            onClick={() => history.push('/select-country-service')}
            variant="secondary"
          />
          <a
            href="https://yesimsupport.zendesk.com/hc/sections/12303545229341"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <span className={styles.linkText}>
              {t('learnMoreAtOurHelpCenter')}
            </span>
            <img src={LinkIcon} alt="Link" className={styles.linkIcon} />
          </a>
        </div>
      )}
      <NumberSettings
        onClose={() => setSelectedNumber(null)}
        selectedNumber={selectedNumber}
      />
    </div>
  );
};

export default NumbersPage;
