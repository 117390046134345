import React from 'react';
import styles from './CollapsibleFooterSection.module.scss';
import ChevronRightIcon from '../../assets/icons/ic_chevron_right.svg';
import ChevronRightHoverIcon from '../../assets/icons/ic_chevron_right_hover.svg';
import ChevronDownIcon from '../../assets/icons/ic_chevron_down.svg';
import ChevronDownHoverIcon from '../../assets/icons/ic_chevron_down_hover.svg';

type CollapsibleFooterSectionProps = {
  title: string;
  children: React.ReactNode;
};

const CollapsibleFooterSection: React.FC<CollapsibleFooterSectionProps> = ({
  title,
  children,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <div className={styles.container}>
      <button className={styles.collapsible} onClick={() => setOpen(!isOpen)}>
        <h2 className={styles.title}>{title}</h2>
        <img
          src={isOpen ? ChevronDownIcon : ChevronRightIcon}
          alt="Chevron"
          className={styles.chevronIcon}
        />
        <img
          src={isOpen ? ChevronDownHoverIcon : ChevronRightHoverIcon}
          alt="Chevron"
          className={styles.chevronHoverIcon}
        />
      </button>
      <ul className={`${styles.content}${isOpen ? ` ${styles.open}` : ''}`}>
        {children}
      </ul>
    </div>
  );
};

export default CollapsibleFooterSection;
