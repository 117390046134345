import moment from 'moment/moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../api';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import QuestionMarkImage from '../../assets/images/question_mark.svg';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { ActiveNumber } from '../../store/reducers/activeNumbersReducer/activeNumbersReducer.types';
import { getActiveNumbers } from '../../store/thunks/activeNumbersThunks';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import Button from '../Button';
import Loader from '../Loader';
import Modal from '../Modal';
import styles from './NumberSettings.module.scss';

type NumberSettingsProps = {
  onClose: () => void;
  selectedNumber: ActiveNumber | null;
};

const NumberSettings: React.FC<NumberSettingsProps> = ({
  onClose,
  selectedNumber,
}) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const { setSnackbar } = useSnackbar();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  async function disableSubscription() {
    try {
      setLoading('pending');
      await Api.numbers.disableNumberSubscription(selectedNumber!.number);
      await dispatch(getActiveNumbers());
      onClose();
      setLoading('fulfilled');
      setSnackbar({ message: t('success'), type: 'success' });
    } catch (e: any) {
      setLoading('rejected');
      setSnackbar({ message: e.message, type: 'error' });
    }
  }

  async function enableSubscription() {
    try {
      setLoading('pending');
      await Api.numbers.enableNumberSubscription(selectedNumber!.number);
      await dispatch(getActiveNumbers());
      onClose();
      setLoading('fulfilled');
      setSnackbar({ message: t('success'), type: 'success' });
    } catch (e: any) {
      setLoading('rejected');
      setSnackbar({ message: e.message, type: 'error' });
    }
  }

  return (
    <Modal
      open={Boolean(selectedNumber)}
      modalPresentationMobile
      onClose={() => {
        onClose();
      }}
    >
      <div className={styles.content}>
        {loading === 'pending' ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <h2 className={styles.title}>{t('settings.settings')}</h2>
            <div className={styles.wrapper}>
              <p className={styles.text}>
                {formatPhoneNumber(
                  selectedNumber?.number,
                  selectedNumber?.code,
                )}
              </p>
              <div className={styles.plan}>
                <p className={styles.text}>{selectedNumber?.plan.cost}</p>
                <img
                  src={YcoinsIcon}
                  alt="Ycoins"
                  className={styles.ycoinsIcon}
                />
                <p className={styles.text}>
                  {`/ ${selectedNumber?.plan.title}`}
                </p>
              </div>
            </div>
            <ul className={styles.actions}>
              {selectedNumber?.subscription ? (
                <li
                  className={styles.item}
                  onClick={() => setOpenConfirmation(true)}
                >
                  <p className={styles.label}>{t('turnOffSubscription')}</p>
                </li>
              ) : (
                <li className={styles.item} onClick={enableSubscription}>
                  <p className={styles.label}>{t('turnOnSubscription')}</p>
                </li>
              )}
            </ul>
            <Button label={t('back')} onClick={onClose} variant="secondary" />
          </>
        )}
      </div>
      <Modal
        open={openConfirmation}
        modalPresentationMobile
        onClose={() => setOpenConfirmation(false)}
      >
        <div className={styles.confirmationModal}>
          <img
            src={QuestionMarkImage}
            alt="Question mark"
            className={styles.image}
          />
          <h2 className={styles.confirmationTitle}>
            {t('cancelSubscriptionQuestion')}
          </h2>
          <p className={styles.confirmationText}>
            {t('afterCancellationNumber', {
              date: moment(selectedNumber?.active_untill).format(
                'D MMMM, YYYY',
              ),
            })}
          </p>
          <div className={styles.confirmationActions}>
            <Button
              label={t('turnOffSubscription')}
              variant="secondary"
              className={styles.button}
              onClick={() => {
                setOpenConfirmation(false);
                disableSubscription();
              }}
            />
            <Button
              label={t('back')}
              variant="plain"
              className={styles.button}
              onClick={() => {
                setOpenConfirmation(false);
                onClose();
              }}
            />
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default NumberSettings;
