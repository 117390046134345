import React from 'react';
import styles from './WalletPage.module.scss';
import WalletBalance from '../../components/WalletBalance';
import RedeemCode from '../../components/RedeemCode';
import Challenges from '../../components/Challenges';

const WalletPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <WalletBalance />
      <RedeemCode />
      <Challenges />
    </div>
  );
};

export default WalletPage;
