export const SAVE_TASKS = 'ycoins/SaveTasks';
export const SAVE_CATEGORIES = 'ycoins/SaveCategories';
export const SAVE_HISTORY = 'ycoins/SaveHistory';

export interface Task {
  id: string;
  text: {
    [key: string]: string;
  };
  ycoins: number;
  isCompleted: boolean | string;
  image: string;
}

export interface Category {
  id: string;
  title: {
    [key: string]: string;
  };
  decription: {
    [key: string]: string;
  };
  image: string;
  details: {
    title: {
      [key: string]: string;
    };
    description: {
      [key: string]: string;
    };
  };
  progress: {
    value: string;
    max: string;
  };
}

export interface HistoryItem {
  id: string;
  type:
    | 'TASK_COMPLETED'
    | 'PLAN_PURCHASED'
    | 'YC_DEBIT'
    | 'YCOINS_BURNED'
    | 'BUY_YCOINS'
    | 'PROMO_BONUS_1'
    | 'PROMO_REFERRAL'
    | 'FRIEND_REFERRAL'
    | 'PAY_AS_YOU_GO'
    | 'DIRECT TOPUP';
  title: string;
  withdrawal: boolean;
  description: string;
  coins: string;
  date: string;
  text: {
    [key: string]: string;
  };
  image?: string;
  iso?: string | null;
  packsge_id?: string | null;
  key?: string;
  referral?: string | null;
  plan_country?: string | null;
}

export interface YcoinsState {
  categories: Category[];
  tasks: Task[];
  history: HistoryItem[];
}

export interface SaveTasks {
  type: typeof SAVE_TASKS;
  payload: Task[];
}

export interface SaveCategories {
  type: typeof SAVE_CATEGORIES;
  payload: Category[];
}

export interface SaveHistory {
  type: typeof SAVE_HISTORY;
  payload: HistoryItem[];
}

export type YcoinsActions = SaveTasks | SaveCategories | SaveHistory;
