import React, { useEffect, useMemo, useState } from 'react';
import styles from './NetworkOperatorsPage.module.scss';
import { NetworkOperators } from '../../globalTypes';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { getNetworkOperators } from '../../api/plans';
import BackIcon from '../../assets/icons/ic_chevron_left.svg';
import SearchInput from '../../components/SearchInput';
import getFlag from '../../utils/getFlag';
import Loader from '../../components/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useMountedState } from '../../hooks/useMountedState';
import CellIcon from '../../assets/icons/ic_cell.svg';

const NetworkOperatorsPage: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<NetworkOperators[]>([]);
  const [loading, setLoading] = useLoadingStatus('pending');
  const [filter, setFilter] = useState('');
  const { setSnackbar } = useSnackbar();
  const isMount = useMountedState();
  const { search } = useLocation();
  const { countries } = qs.parse(search) as { [key: string]: string };

  useEffect(() => {
    async function init() {
      try {
        const res = await getNetworkOperators();
        if (isMount() && res) {
          setData(
            countries
              ? res.filter((i: NetworkOperators) => countries.includes(i.iso))
              : res,
          );
          setLoading('fulfilled');
        }
      } catch (error: any) {
        if (isMount()) {
          setSnackbar({ message: error.message, type: 'error' });
          setLoading('rejected');
        }
      }
    }

    init();
  }, []);

  const list = useMemo(
    () =>
      filter
        ? data.filter((i) =>
            (i.country + i.iso + i.search.join())
              .toLowerCase()
              .includes(filter.toLowerCase()),
          )
        : data,
    [filter, data],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img
        src={BackIcon}
        alt="Back"
        className={styles.back}
        onClick={() => {
          history.length > 2 ? history.goBack() : history.push('/');
        }}
      />
      {(!filter ? data.length > 1 : true) && (
        <SearchInput onChangeText={setFilter} value={filter} />
      )}
      <ul className={styles.list}>
        {list.map((item, index) => (
          <li key={index} className={styles.item}>
            <img src={getFlag(item.iso)} alt="Flag" className={styles.flag} />
            <p className={styles.country}>{item.country}</p>
            <p className={styles.operators}>
              {item.operators.map((i) => i.name).join(', ')}
            </p>
            <img src={CellIcon} alt="Icon" className={styles.icon} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NetworkOperatorsPage;
