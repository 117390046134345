import {
  ArchiveNumber,
  ArchiveNumbersActions,
  SAVE_ARCHIVE_NUMBERS,
} from './archiveNumbersReducer.types';

export default function (
  state: ArchiveNumber[] = [],
  action: ArchiveNumbersActions,
) {
  switch (action.type) {
    case SAVE_ARCHIVE_NUMBERS:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveArchiveNumbers: (payload: ArchiveNumber[]): ArchiveNumbersActions => ({
    type: SAVE_ARCHIVE_NUMBERS,
    payload,
  }),
};
