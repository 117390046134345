export default (number: any = '', code: any = '') => {
  let n = number.replace(code.replace('+', ''), '');
  const vanillaPart = n;

  try {
    const last4 = n.substring(n.length - 4);

    n = n.substring(0, n.length - 4);
    let parts = '';

    for (let i = 0; i < n.length; i += 3) {
      parts = `${parts} ${n.substring(
        i,
        i + 3 > n.length ? undefined : i + 3,
      )}`;
    }

    return `${code} ${parts.trim()} ${last4}`;
  } catch (e) {
    return `${code} ${vanillaPart}`;
  }
};
