import { Api } from 'src/api';
import { Country, Package } from '../../globalTypes';
import formatPrice from '../../utils/formatPrice';
import { AppThunk } from '../index';
import { actions as CountriesActions } from '../reducers/countriesPackagesReducer/countriesPackagesReducer';

export const getCountriesPackages =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const currency = getState().userProfile.paymentCurrency.code;
      const countries = await Api.plans.getCountries(currency);
      const packages = await Api.plans.getPackages();
      let count = 130;
      if (Array.isArray(countries)) {
        count = countries.length;
      }

      const GLOBAL_PACKAGES_ORDER = [
        '15',
        '23',
        '24',
        '25',
        '27',
        '22',
        '26',
        '28',
        '29',
      ];

      dispatch(
        CountriesActions.saveCountriesPackages({
          countries: countries.map((i: Country) => ({
            ...i,
            price: {
              ...i.price,
              amount: formatPrice(i.price.amount),
            },
          })),
          packages: packages
            .filter((i: Package) => !i.global)
            .map((item: any) => ({
              ...item,
              countries: item.countries
                .split(',')
                .map((id: string) =>
                  countries.find((i: Country) => i.id === id)?.search.join(),
                ),
            })),
          globalPackages: packages
            .filter((i: Package) => i.global)
            .map((item: any) => ({
              ...item,
              countries: item.countries
                .split(',')
                .map((id: string) =>
                  countries.find((i: Country) => i.id === id)?.search.join(),
                ),
              countriesIds: item.countries,
            }))
            .sort(
              (a: any, b: any) =>
                GLOBAL_PACKAGES_ORDER.indexOf(a.package_id) -
                GLOBAL_PACKAGES_ORDER.indexOf(b.package_id),
            ),
          count: count,
        }),
      );
      return;
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
