import { sendUtmLink } from '../api/common';
import { setPartnerId } from '../api/profile';
import { PartnerLinkData } from '../globalTypes';
import { redeemCode } from '../api/ycoins';

export async function sendPartnerLinkData({
  firstAuth,
}: {
  firstAuth: boolean;
}) {
  try {
    const partnerLinkDataString = localStorage.getItem('partnerLinkData');
    const referral = localStorage.getItem('referral');
    const promoCode = localStorage.getItem('promoCode');
    const utmLink = localStorage.getItem('utm_link');
    const referrer = document.referrer;

    if (partnerLinkDataString && firstAuth) {
      const partnerLinkData: PartnerLinkData = JSON.parse(
        partnerLinkDataString,
      );
      await setPartnerId({
        partnerId: partnerLinkData.partnerId,
        isRetargeting: partnerLinkData.isRetargeting,
        additional: partnerLinkData.additional,
      });
      if (partnerLinkData.partnerCode) {
        await redeemCode(partnerLinkData.partnerCode);
      }
    } else if (referral && firstAuth) {
      await redeemCode(referral);
    }

    if (promoCode) {
      await redeemCode(promoCode);
      localStorage.removeItem('promoCode');
    }

    if (utmLink) {
      if (firstAuth || !utmLink.includes('utm_medium=organic')) {
        await sendUtmLink(utmLink);
      }
      localStorage.removeItem('utm_link');
    } else if (referrer && !referrer.includes(location.hostname)) {
      await sendUtmLink(referrer);
    }

    if (partnerLinkDataString) {
      localStorage.removeItem('partnerLinkData');
    }

    if (referral) {
      localStorage.removeItem('referral');
    }
  } catch (e: any) {
    //
  }
}

export function saveLocalPartnerLinkData() {
  const linkParams = new URLSearchParams(window.location.search);
  const partnerId = linkParams.get('partner_id');
  const referral = linkParams.get('referral');

  if (partnerId) {
    const isRetargeting = linkParams.get('is_retargeting');
    const partner103SubId = linkParams.get('sub_id');
    const partnerCode = linkParams.get('partner_code');

    const linkData: PartnerLinkData = {
      partnerId: partnerId,
      isRetargeting: isRetargeting ? isRetargeting : undefined,
      additional: partner103SubId
        ? {
            sub_id: partner103SubId,
          }
        : undefined,
      partnerCode: partnerCode ? partnerCode : undefined,
    };
    localStorage.setItem('partnerLinkData', JSON.stringify(linkData));
  } else if (referral) {
    localStorage.setItem('referral', referral);
  }
}
