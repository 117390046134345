import React from 'react';

const Loader: React.FC = () => (
  <svg width="50px" height="50px" viewBox="0 0 50 50">
    <g transform="scale(1,-1) translate(0,-50)">
      <rect y="0" width="10" height="10" fill="#FFDEA5" rx="2" />
      <rect y="15" width="10" height="14" fill="#FFDEA5" rx="2">
        <animate
          attributeName="height"
          values="10;14;10"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
      </rect>

      <rect x="13" y="0" width="10" height="10" fill="#FFDEA5" rx="2" />
      <rect x="13" y="15" width="10" height="21" fill="#FFDEA5" rx="2">
        <animate
          attributeName="height"
          values="10;21;10"
          begin="0.2s"
          dur="2s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="26" y="0" width="10" height="10" fill="#FFDEA5" rx="2" />
      <rect x="26" y="15" width="10" height="28" fill="#FFDEA5" rx="2">
        <animate
          attributeName="height"
          values="10;28;10"
          begin="0.4s"
          dur="2s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="39" y="0" width="10" height="10" fill="#FFDEA5" rx="2" />
      <rect x="39" y="15" width="10" height="35" fill="#FFA400" rx="2">
        <animate
          attributeName="height"
          values="10;35;10"
          begin="0.6s"
          dur="2s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
);

export default Loader;
