import i18n from 'i18next';
import moment from 'moment/moment';
import { UserProfile } from '../reducers/userProfileReducer/userProfileReducer.types';
import { ProfileService } from 'src/services/profile_service';
import { actions as UserProfileActions } from '../reducers/userProfileReducer/userProfileReducer';
import { AppThunk } from '../index';

export const getProfileData =
  (): AppThunk<Promise<UserProfile>> => async (dispatch) => {
    try {
      const profileService = new ProfileService();
      const profile = await profileService.getProfile();
      dispatch(UserProfileActions.saveProfile(profile));
      i18n.changeLanguage(profile.lang);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.('messenger:set', 'locale', profile.lang);
      localStorage.setItem('lang', profile.lang);
      if (profile.paymentCurrency) {
        localStorage.setItem('currency', profile.paymentCurrency.code);
      }
      moment.locale(profile.lang);
      return profile;
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
