export const SAVE_CURRENCIES = 'SAVE_CURRENCIES';

export type Currency = {
  code: string;
  id: string;
  iso2: string;
  iso3: string;
  rate: string;
  symbol: string;
};

export interface SaveCurrencies {
  type: typeof SAVE_CURRENCIES;
  payload: Currency[];
}

export type CurrenciesActions = SaveCurrencies;
