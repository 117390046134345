import React from 'react';
import styles from './PlanReplaceModal.module.scss';
import Modal from '../Modal';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import WarnImage from '../../assets/images/warn.svg';

type PlanReplaceModalProps = {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const PlanReplaceModal: React.FC<PlanReplaceModalProps> = ({
  open,
  onClose,
  onContinue,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <div className={styles.content}>
        <img src={WarnImage} alt="Warn" className={styles.image} />
        <h2 className={styles.text}>{t('purchase.planWillCanceled')}</h2>
        <Button
          label={t('continue')}
          variant="secondary"
          className={styles.button}
          onClick={() => {
            onClose();
            onContinue();
          }}
        />
        <Button
          label={t('back')}
          variant="plain"
          className={styles.button}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default PlanReplaceModal;
