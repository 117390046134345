import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AuthPage.module.scss';

interface Props {
  error: string;
  handleSendAgain: () => void;
}

function ResendCode({ error, handleSendAgain }: Props) {
  const { t } = useTranslation();

  const [seconds, setSeconds] = useState(59);
  const intervalRef = useRef<NodeJS.Timer>();

  function initInterval() {
    setSeconds(59);
    intervalRef.current = setInterval(() => {
      setSeconds((s) => s - 1);
    }, 1000);
  }

  useEffect(() => {
    initInterval();
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [seconds]);

  useEffect(() => {
    if (t('wrongAuthCodeTryResend') === error || 'Error: sc-1' === error) {
      setSeconds(0);
    }
  }, [error]);

  return (
    <>
      {seconds ? (
        <div className={'f-14 t-third ' + styles.resendCodeTimer}>
          {t('resend')}:{' '}
          <span className={'f-14 t-prime '}>
            00:{seconds < 10 ? '0' + seconds : seconds}
          </span>
        </div>
      ) : (
        <button
          className={styles.resendCodeButton}
          onClick={() => {
            initInterval();
            handleSendAgain();
          }}
          id="auth-resend-code"
        >
          <span className={styles.resendCodeButtonText}>{t('resend')}</span>
        </button>
      )}
    </>
  );
}

export default ResendCode;
