import React from 'react';
import Switch from '../Switch';
import styles from './ToggleOption.module.scss';
import Tooltip from '../Tooltip';
import InfoIcon from '../../assets/icons/ic_info_orange.svg';

type ToggleOptionProps = {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: (value: boolean) => void;
  hint?: string;
  icon?: any;
  disabled?: boolean;
  className?: string;
};

const ToggleOption: React.FC<ToggleOptionProps> = ({
  label,
  checked,
  onChange,
  hint,
  icon,
  disabled,
  className,
}) => (
  <div
    className={`${styles.container} ${checked && styles.checked} ${
      disabled && styles.disabled
    }  ${className}`}
  >
    <img src={icon} alt="Icon" className={styles.icon} />
    <div className={styles.wrapper}>
      <h4 className={styles.label}>{label}</h4>
      {hint && (
        <Tooltip content={hint} position="left">
          <img src={InfoIcon} alt="Info" className={styles.hint} />
        </Tooltip>
      )}
    </div>
    <Switch checked={checked} onChange={onChange} disabled={disabled} />
  </div>
);

export default ToggleOption;
