import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AccountIcon from '../../assets/icons/ic_account.svg';
import AppStoreIcon from '../../assets/icons/ic_app_store.svg';
import ArrowDownIcon from '../../assets/icons/ic_arrow_down.svg';
import ArrowUpIcon from '../../assets/icons/ic_arrow_up.svg';
import GooglePlayIcon from '../../assets/icons/ic_google_play.svg';
import YesimMarketplaceIcon from '../../assets/icons/ic_yesim_marketplace.png';
import LogOutPopup from '../LogOutPopup';
import styles from './Account.module.scss';

const Account: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const containerRef = useRef();
  const [openLogOutPopup, setOpenLogOutPopup] = useState(false);

  useEffect(() => {
    function handleClick(e: any) {
      if (containerRef && containerRef.current) {
        const ref: any = containerRef.current;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className={styles.container} ref={containerRef as any}>
      <div
        className={styles.input}
        onClick={() => setOpen(!isOpen)}
        id="header-nav-dropdown-menu"
      >
        <img src={AccountIcon} className={styles.accountIcon} alt="Account" />
        <img
          src={isOpen ? ArrowUpIcon : ArrowDownIcon}
          className={styles.arrowIcon}
          alt="Arrow"
        />
      </div>
      {isOpen && (
        <ul className={styles.dropdown}>
          <li>
            <NavLink
              to={'/settings'}
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-settings"
            >
              <span>{t('accountSettings')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/history'}
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-history"
            >
              <span>{t('settings.purchaseHistory')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/wallet'}
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-get-more-ycoins"
            >
              <span>{t('getMoreYcoins')}</span>
            </NavLink>
          </li>
          <li>
            <a
              href={`https://yesimsupport.zendesk.com/hc/${
                i18n.languages[0] === 'ru' ? 'ru' : 'en-us'
              }`}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-help-center"
            >
              <span>{t('helpCenter')}</span>
            </a>
          </li>
          <li
            id="dropdown-menu-log-out"
            className={styles.link}
            onClick={() => {
              setOpenLogOutPopup(true);
              setOpen(false);
            }}
          >
            <span>{t('settings.logOut')}</span>
          </li>
          <span className={styles.label}>{t('yesimProducts')}</span>
          <li className={styles.product}>
            <img
              src={YesimMarketplaceIcon}
              alt="Yesim Partners Program"
              className={styles.icon}
            />
            <a
              href="https://partners.yesim.app/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-yesim-partners-program"
            >
              {'Yesim Partners Program'}
            </a>
          </li>
          <li className={styles.product}>
            <img
              src={AppStoreIcon}
              alt="iOS Application"
              className={styles.icon}
            />
            <a
              href="https://apps.apple.com/us/app/yesim-esim-internet-app-vpn/id1458505230"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-ios-app"
            >
              {'iOS Application'}
            </a>
          </li>
          <li className={styles.product}>
            <img
              src={GooglePlayIcon}
              alt="Android Application"
              className={styles.icon}
            />
            <a
              href="https://play.google.com/store/apps/details?id=com.yesimmobile"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
              onClick={() => setOpen(false)}
              id="dropdown-menu-android-app"
            >
              {'Android Application'}
            </a>
          </li>
        </ul>
      )}
      <LogOutPopup
        open={openLogOutPopup}
        onClose={() => setOpenLogOutPopup(false)}
      />
    </div>
  );
};

export default Account;
