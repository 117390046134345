import SA from '../assets/images/countries/sa.png';
import NA from '../assets/images/countries/na.png';
import CIS from '../assets/images/countries/cis.png';
import EU from '../assets/images/countries/eu.png';
import SEA from '../assets/images/countries/sea.png';
import Africa from '../assets/images/countries/africa.png';
import Nordic from '../assets/images/countries/nordic.png';
import ME from '../assets/images/countries/me.png';
import Asia from '../assets/images/countries/asia.png';
import Caribbean from '../assets/images/countries/caribbean.png';
import WorldIcon from '../assets/images/countries/world.png';
import BusinessWorld from '../assets/images/countries/business_world.png';
import UsaGlobal from '../assets/images/countries/usa_global.png';
import IndiaTraveler from '../assets/images/countries/india_traveler.png';
import AmericasAndMore from '../assets/images/countries/americas_and_more.png';
import PacificHop from '../assets/images/countries/pacific_hop.png';
import AfricaMax from '../assets/images/countries/africa_max.png';
import AfricaPlus from '../assets/images/countries/africa_plus.png';
import AfricaLight from '../assets/images/countries/africa_light.png';
import WesternCaribbeanCruise from '../assets/images/countries/western_caribbean_cruise.png';
import EasternCaribbeanCruise from '../assets/images/countries/eastern_caribbean_cruise.png';
import SouthCaribbeanCruise from '../assets/images/countries/south_caribbean_cruise.png';
import Balkans from '../assets/images/countries/balkans.png';

function getPackageIcon(packageId: string) {
  switch (packageId) {
    case '3':
      return NA;
    case '16':
      return NA;
    case '5':
      return CIS;
    case '6':
      return EU;
    case '7':
      return Caribbean;
    case '8':
      return Africa;
    case '10':
      return SA;
    case '11':
      return Asia;
    case '12':
      return SEA;
    case '13':
      return ME;
    case '14':
      return Nordic;
    case '22':
      return BusinessWorld;
    case '23':
      return UsaGlobal;
    case '24':
      return IndiaTraveler;
    case '25':
      return AmericasAndMore;
    case '26':
      return PacificHop;
    case '27':
      return AfricaMax;
    case '28':
      return AfricaPlus;
    case '29':
      return AfricaLight;
    case '31':
      return WesternCaribbeanCruise;
    case '33':
      return EasternCaribbeanCruise;
    case '35':
      return SouthCaribbeanCruise;
    case '37':
      return Balkans;
    default:
      return WorldIcon;
  }
}

export default getPackageIcon;
