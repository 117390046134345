import React, { useState } from 'react';
import styles from './DeactivatePayAsYouGo.module.scss';
import { useTranslation } from 'react-i18next';
import { deactivatePayGoPlan } from 'src/api/profile';
import { ProfileThunks } from 'src/store/thunks';
import Loader from '../Loader';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import { useSnackbar } from 'src/hooks/useSnackbar';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Modal from '../Modal';
import Button from '../Button';
import QuestionMarkImage from '../../assets/images/question_mark.svg';

const DeactivatePayAsYouGo: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const [isOpen, setOpen] = useState(false);

  const onDeactivate = async () => {
    try {
      setLoading('pending');
      await deactivatePayGoPlan();
      await dispatch(ProfileThunks.getProfileData());
      setOpen(false);
      setLoading('fulfilled');
      setSnackbar({ message: t('success'), type: 'success' });
    } catch (error: any) {
      setOpen(false);
      setLoading('rejected');
      setSnackbar({ message: error.message, type: 'error' });
    }
  };

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Button
        label={t('deactivatePlan')}
        onClick={() => setOpen(true)}
        className={styles.button}
      />
      <Modal
        open={isOpen}
        modalPresentationMobile
        onClose={() => setOpen(false)}
      >
        <div className={styles.modal}>
          <img
            src={QuestionMarkImage}
            alt="Question mark"
            className={styles.image}
          />
          <h2 className={styles.text}>{t('sureDeactivatePayGo')}</h2>
          <Button
            label={t('deactivate')}
            variant="secondary"
            onClick={onDeactivate}
          />
          <Button
            label={t('cancel')}
            variant="plain"
            onClick={() => setOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default DeactivatePayAsYouGo;
