import React, { useState } from 'react';
import styles from './CheckoutForm.module.scss';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

type CheckoutFormProps = {
  successUrl: string;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({ successUrl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: successUrl,
      },
    });

    if (
      error.message &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      setMessage(error.message);
    } else {
      setMessage(t('somethingWentWrong'));
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        label={t('purchase.paySecurely')}
        variant="plain"
        className={styles.paymentButton}
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      />
      {message && (
        <p id="payment-message" className={styles.paymentMessage}>
          {message}
        </p>
      )}
    </form>
  );
};

export default CheckoutForm;
