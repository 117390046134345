import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Modal from '../Modal';
import styles from './RegionRestrictionsPopup.module.scss';
import InfoIcon from '../../assets/images/info.svg';

type RegionRestrictionsPopupProps = {
  open: boolean;
  onClose: () => void;
};

const RegionRestrictionsPopup: React.FC<RegionRestrictionsPopupProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <div className={styles.content}>
        <img
          src={InfoIcon}
          alt="Region restrictions"
          className={styles.image}
        />
        <h2 className={styles.title}>{t('restrictedRegionPurchase')}</h2>
        <Button label={t('ok')} onClick={onClose} variant="plain" />
      </div>
    </Modal>
  );
};

export default RegionRestrictionsPopup;
