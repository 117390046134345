export const SAVE_ARCHIVE_NUMBERS = 'SAVE_ARCHIVE_NUMBERS';

export type ArchiveNumber = {
  number: string;
  unread_sms: string;
  start: string;
  end: string;
  iso: string;
  code: string;
  country: string;
};

export interface SaveArchiveNumbers {
  type: typeof SAVE_ARCHIVE_NUMBERS;
  payload: ArchiveNumber[];
}

export type ArchiveNumbersActions = SaveArchiveNumbers;
