export const useCaptcha = () => {
  async function executeCaptcha() {
    return new Promise<string>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      grecaptcha.ready(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY)
          .then((token: string) => resolve(token))
          .catch((error: Error) => reject(error));
      });
    });
  }

  return { executeCaptcha };
};
