import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import { PaymentMethods, Plan } from '../globalTypes';
import i18next from 'i18next';

export const getCountries = (currency: string) =>
  axios
    .get('/countries_for_sale', {
      params: {
        lang: i18next.languages[0],
        currency,
      },
    })
    .then((res) => res.data[0])
    .catch(catchApiMessage);

export const getPackages = () =>
  axios
    .get('/packages_for_sale', {
      params: {
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data[0])
    .catch(catchApiMessage);

export const getCountryTarifs = ({
  country,
  iso,
  currency,
}: {
  country?: string;
  iso?: string;
  currency: string;
}): Promise<Plan[]> =>
  axios
    .get('/new_plans_for_sale', {
      params: {
        ...(country ? { country } : {}),
        ...(iso ? { iso } : {}),
        currency,
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getPackageTarifs = ({
  id,
  currency,
}: {
  id: string;
  currency: string;
}): Promise<Plan[]> =>
  axios
    .get('/tarif_by_packages', {
      params: {
        package_id: id,
        lang: i18next.languages[0],
        currency,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getPurchseHistory = () =>
  axios
    .get('/purchase_history', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data[0])
    .catch(catchApiMessage);

export const activateDelayedPlan = () =>
  axios
    .post('/activate-delayed-plan', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .catch(catchApiMessage);

export const plansForTopup = (): Promise<{
  plans: Plan[];
  name: string;
  type: string;
  id: string;
}> =>
  axios
    .get('/plans_for_topup', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const plansFromHistory = (
  order_id: string,
): Promise<{
  plans: Plan[];
  name: string;
  type: string;
  country: string;
  id: string;
}> =>
  axios
    .get('/plans_from_history', {
      params: {
        auth_key: getAuthKey(),
        order_id,
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getExtraPlan = () =>
  axios
    .get('/get_extra_plans2', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getPayAsYouGoCountries = () =>
  axios
    .get('/pay_as_you_go_countries', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getCountriesCount = () =>
  axios
    .get('/count_countries')
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getPayGoHistory = () =>
  axios
    .get('/ycoins_psg_usage', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const checkPaymentStatus = ({ paymentId }: { paymentId: string }) =>
  axios
    .get('/payment_status', {
      params: {
        auth_key: getAuthKey(),
        payment_id: paymentId,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getNetworkOperators = (countries?: string[]) =>
  axios
    .get('/get_networks', {
      params: { lang: i18next.languages[0], countries: countries?.join() },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const getPaymentData = (
  data: any,
  paymentMethod: string,
  altPayment?: boolean,
  successUrl?: string,
  failUrl?: string,
  cardIso?: string,
  attempt?: number,
  coins_auto_top_up?: boolean,
) =>
  axios
    .post('/payment', null, {
      params: {
        lang: i18next.languages[0],
        auth_key: getAuthKey(),
        ostype: 3,
        plan_id: data.planId,
        coins: data.ycoins,
        delayed: data.delayed === '1',
        p: attempt,
        iso: cardIso,
        success_url: successUrl,
        fail_url: failUrl,
        system_data_overwrite: {
          success_url: successUrl,
          fail_url: failUrl,
          redirect_success_url: successUrl,
          redirect_fail_url: failUrl,
          ...(paymentMethod === PaymentMethods.APPLE_PAY
            ? { force_payment_method: 'apple_pay_core' }
            : {}),
          ...(paymentMethod === PaymentMethods.GOOGLE_PAY
            ? { force_payment_method: 'google_pay_host' }
            : {}),
          ...(paymentMethod === PaymentMethods.PAYPAL
            ? {
                payment_source: {
                  paypal: {
                    experience_context: {
                      return_url: successUrl,
                      cancel_url: failUrl,
                    },
                  },
                },
              }
            : {}),
        },
        ...(paymentMethod === PaymentMethods.APPLE_PAY ||
        paymentMethod === PaymentMethods.GOOGLE_PAY
          ? {
              payment_system: PaymentMethods.ECP,
            }
          : {
              payment_system: paymentMethod,
            }),
        coins_auto_top_up,
        old_ver: 1,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
