import React, { useState } from 'react';
import styles from './PostponePlan.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import InfoIcon from 'src/assets/icons/ic_info.svg';
import Button from '../Button';
import Modal from '../Modal';
import Checkbox from '../Checkbox';
import { activateDelayedPlan } from 'src/api/plans';
import { ProfileThunks } from 'src/store/thunks';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import { useSnackbar } from 'src/hooks/useSnackbar';

const PostponePlan: React.FC = () => {
  const { t } = useTranslation();
  const postponePlan = useSelector(
    (state: RootState) => state.userProfile.postponePlan,
  );
  const haveActivePlan = useSelector(
    (state: RootState) => state.userProfile.classicPlan.active,
  );

  const [alert, setAlert] = useState(false);
  const [agree, setAgree] = useState(false);
  const dispatch = useThunkDispatch();
  const [loadingStatus, setLoadingStatus] = useLoadingStatus('unknown');
  const { setSnackbar } = useSnackbar();

  async function activatePlan() {
    setLoadingStatus('pending');
    try {
      await activateDelayedPlan();
      await dispatch(ProfileThunks.getProfileData());
      setSnackbar({ message: t('activationSuccess'), type: 'success' });
    } catch (error: any) {
      setLoadingStatus('rejected');
      setSnackbar({ message: error.message, type: 'error' });
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={InfoIcon} alt="Info" className={styles.icon} />
        <p>
          <Trans
            i18nKey={'postponedPlan'}
            values={{ plan: postponePlan.name }}
            components={{ plan: <span /> }}
          />
        </p>
      </div>
      <Button
        label={t('activate')}
        variant="primary"
        size="small"
        className={styles.button}
        onClick={() => setAlert(true)}
      />
      <Modal
        open={alert}
        onClose={() => setAlert(false)}
        modalPresentationMobile
      >
        <div className={styles.modal}>
          <h2 className={styles.postponeTitle}>{t('activatePostponedPlan')}</h2>
          <Button
            label={t('activatePlan')}
            variant="plain"
            disabled={loadingStatus === 'pending' || (haveActivePlan && !agree)}
            onClick={activatePlan}
          />
          {haveActivePlan && (
            <Checkbox
              checked={agree}
              onChange={setAgree}
              label={t('purchase.planWillCanceled')}
              className={styles.checkbox}
            />
          )}
          <Button
            label={t('back')}
            variant="secondary"
            className={styles.postponeButton}
            onClick={() => setAlert(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PostponePlan;
