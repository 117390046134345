import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import root from './reducers/root';

const store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));

export default store;
export const getAuthKey = () => store.getState().token.sessionId;
export const getProfile = () => store.getState().userProfile;
export type AppThunk<R = any> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;
export type MyThunkDispatch = ThunkDispatch<RootState, any, Action<any>>;
export type RootState = ReturnType<typeof root>;
