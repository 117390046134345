import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styles from './ZendeskLauncher.module.scss';
import SupportIcon from '../../assets/icons/ic_support.svg';
import CrossIcon from '../../assets/icons/ic_cross.svg';

function ZendeskLauncher() {
  const [isZendeskOpen, setZendeskOpen] = React.useState(false);
  const [unreadMessages, setUnreadMessages] = React.useState(0);
  const isHidden = useRouteMatch([
    '/login',
    '/plans',
    '/payment',
    '/payment-success',
    '/ycoins',
    '/buy-number',
    '/pay-as-you-go',
    '/international-esim',
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE('messenger:on', 'open', () => setZendeskOpen(true));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE('messenger:on', 'close', () => setZendeskOpen(false));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE('messenger:on', 'unreadMessages', (count: number) =>
        setUnreadMessages(count),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  function onLauncherClick(e: any) {
    e.preventDefault();
    try {
      if (isZendeskOpen) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.zE('messenger', 'close');
        setZendeskOpen(false);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.zE('messenger', 'show');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.zE('messenger', 'open');
        setZendeskOpen(true);
        setUnreadMessages(0);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  if (isHidden) {
    return null;
  }

  return (
    <button
      className={`${styles.container}${isZendeskOpen ? ` ${styles.open}` : ''}`}
      onClick={onLauncherClick}
    >
      <div className={styles.wrapper}>
        <div
          className={styles.supportIcon}
          style={isZendeskOpen ? { top: '-100%' } : {}}
        >
          <img src={SupportIcon} alt="Support" className={styles.icon} />
        </div>
        <div
          className={styles.closeIcon}
          style={isZendeskOpen ? { top: 0 } : {}}
        >
          <img src={CrossIcon} alt="Close" className={styles.icon} />
        </div>
      </div>
      {unreadMessages > 0 && (
        <div className={styles.badge}>
          <span className={styles.count}>{unreadMessages}</span>
        </div>
      )}
    </button>
  );
}

export default ZendeskLauncher;
