import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import SelectCountryModal from '../SelectCountryModal';
import styles from './IncorrectCountryPopup.module.scss';
import Modal from '../Modal';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import AlertImage from '../../assets/images/alert.svg';

type IncorrectCountryPopupProps = {
  open: boolean;
  onClose: () => void;
  onSelectCountry: (iso: string) => void;
};

const IncorrectCountryPopup: React.FC<IncorrectCountryPopupProps> = ({
  open,
  onClose,
  onSelectCountry,
}) => {
  const { t } = useTranslation();

  const profile = useSelector((state: RootState) => state.userProfile);
  const [isOpenSelectCountry, setOpenSelectCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    profile.lastPaymentMethod.iso,
  );

  return (
    <>
      <Modal open={open} modalPresentationMobile>
        <div className={styles.content}>
          <img src={AlertImage} alt="Alert" className={styles.image} />
          <h2 className={styles.title}>{t('incorrectCardIssuingCountry')}</h2>
          <p className={styles.text}>{t('checkCountryCard')}</p>
          <Button
            label={t('selectCountry')}
            variant="plain"
            className={styles.button}
            onClick={() => {
              onClose();
              setOpenSelectCountry(true);
            }}
          />
          <Button
            label={t('contactSupport')}
            variant="outlined"
            className={styles.button}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.zE('messenger', 'show');
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.zE('messenger', 'open');
              onClose();
            }}
          />
        </div>
      </Modal>
      <SelectCountryModal
        open={isOpenSelectCountry}
        onClose={() => setOpenSelectCountry(false)}
        selectedCountry={selectedCountry}
        onSelectCountry={(iso: string) => {
          setOpenSelectCountry(false);
          setSelectedCountry(iso);
          onSelectCountry(iso);
        }}
      />
    </>
  );
};

export default IncorrectCountryPopup;
