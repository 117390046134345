import React, { useEffect, useState } from 'react';
import Agreements from '../../components/Agreements';
import PlanReplaceModal from '../../components/PlanReplaceModal';
import RedeemCode from '../../components/RedeemCode';
import { useSnackbar } from '../../hooks/useSnackbar';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './PayAsYouGoPage.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import PayAsYouGoImage from '../../assets/images/pay_as_you_go.svg';
import BackIcon from '../../assets/icons/ic_back_white.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import CountriesIcon from '../../assets/icons/ic_countries.svg';
import NetworkOperatorsIcon from '../../assets/icons/ic_network_operators.svg';
import ChevronIcon from '../../assets/icons/ic_chevron_right.svg';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import qs from 'query-string';
import { usePayGoActivation } from '../../hooks/usePayGoActivation';
import PaymentMethodModal from '../../components/PaymentMethodModal';
import { PaymentMethods } from '../../globalTypes';

const PayAsYouGoPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { paymentFail, payment_id } = qs.parse(search) as {
    [key: string]: string;
  };
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.userProfile);
  const NOT_ENOUGH_YCOINS = profile.ycoins < 100;
  const FIRST_PAYMENT = profile.countOfPurchases === 0;
  const [loading, setLoading] = useLoadingStatus('unknown');
  const [agree, setAgree] = useState(false);
  const { activatePayGo } = usePayGoActivation(setLoading);
  const { payAsYouGoFirstPayment } = qs.parse(search) as {
    [key: string]: string;
  };
  const [isOpenPaymentMethod, setOpenPaymentMethod] = useState(false);
  const { setSnackbar } = useSnackbar();
  const firstPaymentAmount = Number(profile.paymentCurrency.rate) * 0.5;
  const isSecurePayment = !NOT_ENOUGH_YCOINS && FIRST_PAYMENT;

  function goToPayment(paymentMethod: PaymentMethods, cardIso?: string) {
    history.push({
      pathname: '/payment',
      search: qs.stringify({
        payAsYouGoFirstPayment: true,
        prev: window.location.href,
        paymentMethod,
        cardIso,
      }),
    });

    if (FIRST_PAYMENT) {
      pushDataLayerEvent({
        event: 'begin_checkout',
        ecommerce: {
          currency: profile.paymentCurrency.code,
          value: firstPaymentAmount,
          items: [
            {
              item_id: 'secure_payment',
              item_category: 'Pay as You Go',
              item_name: 'Secure payment',
              price: firstPaymentAmount,
              currency: profile.paymentCurrency.code,
            },
          ],
        },
      });
    }
  }

  function onPay() {
    if (FIRST_PAYMENT) {
      setOpenPaymentMethod(true);
    } else {
      activatePayGo();
    }
  }

  useEffect(() => {
    if (paymentFail) {
      setSnackbar({
        message: t('somethingWentWrong'),
        type: 'error',
      });
    }

    if (payAsYouGoFirstPayment) {
      activatePayGo();
      pushDataLayerEvent({
        event: 'purchase',
        ecommerce: {
          transaction_id: payment_id,
          user_id: profile.id,
          currency: profile.paymentCurrency.code,
          value: firstPaymentAmount,
          items: [
            {
              item_id: 'secure_payment',
              item_category: 'Pay as You Go',
              item_name: 'Secure payment',
              price: firstPaymentAmount,
              currency: profile.paymentCurrency.code,
              item_category2: 'WEB',
              quantity: 1,
            },
          ],
        },
      });
    }

    pushDataLayerEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: 'pay_as_you_go',
            item_name: 'Pay as You Go',
          },
        ],
      },
    });
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img
        src={BackIcon}
        alt="Back"
        className={styles.back}
        onClick={() => history.push('/countries')}
      />
      <div className={styles.top}>
        <div>
          <h1 className={styles.title}>{t('internationalEsimPlan')}</h1>
          <ul className={styles.descriptionList}>
            <li className={styles.descriptionItem}>{t('payGoDesc_1')}</li>
            <li className={styles.descriptionItem}>{t('payGoDesc_2')}</li>
            <li className={styles.descriptionItem}>{t('payGoDesc_3')}</li>
            <li className={styles.descriptionItem}>{t('payGoDesc_5')}</li>
          </ul>
        </div>
        <img
          src={PayAsYouGoImage}
          alt="Pay as You Go"
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.wallet}>
          <div>
            <h2>{t('yourBalance')}</h2>
            <div className={styles.balance}>
              <p className={styles.ycoins}>{profile.ycoins}</p>
              <img src={YcoinsIcon} alt="Ycoins" className={styles.icon} />
            </div>
          </div>
          <Button
            label={t('main.topup')}
            className={styles.topUp}
            size="small"
            variant={NOT_ENOUGH_YCOINS ? 'plain' : 'outlined'}
            onClick={() => {
              history.push({
                pathname: '/ycoins',
                search: qs.stringify({
                  payAsYouGo: true,
                  ...(NOT_ENOUGH_YCOINS ? { notEnoughYcoins: true } : {}),
                }),
              });
            }}
          />
        </div>
        {NOT_ENOUGH_YCOINS && (
          <p className={styles.notEnoughYcoins}>{t('minCoinsAmount')}</p>
        )}
        <div className={styles.divider} />
        <div className={styles.items}>
          <div
            className={styles.item}
            onClick={() => history.push('/country-prices')}
          >
            <img src={CountriesIcon} alt="Icon" className={styles.icon} />
            <p className={styles.text}>{t('countryPrice')}</p>
            <img src={ChevronIcon} alt="Chevron" className={styles.chevron} />
          </div>
          <div
            className={styles.item}
            onClick={() => history.push('/network-operators')}
          >
            <img
              src={NetworkOperatorsIcon}
              alt="Icon"
              className={styles.icon}
            />
            <p className={styles.text}>{t('cellOperators')}</p>
            <img src={ChevronIcon} alt="Chevron" className={styles.chevron} />
          </div>
        </div>
        <div className={styles.redeemCode}>
          <RedeemCode alternate />
        </div>
        <div className={styles.wrapper}>
          <p className={styles.price}>{t('priceDepending')}</p>
          {isSecurePayment && (
            <p className={styles.firstPayment}>{t('firstPayment')}</p>
          )}
          <Button
            label={
              profile.payGoPlan.active
                ? t('alreadyActive')
                : isSecurePayment
                ? `${t('toPay')} ${
                    profile.paymentCurrency.symbol
                  }${firstPaymentAmount.toFixed(2)} ${
                    profile.paymentCurrency.code
                  }`
                : t('activate')
            }
            variant="plain"
            disabled={NOT_ENOUGH_YCOINS || profile.payGoPlan.active}
            onClick={() => {
              if (profile.classicPlan.active) {
                setAgree(true);
              } else {
                onPay();
              }
            }}
          />
          <p className={styles.important}>{t('coinsChargedOnly')}</p>
        </div>
        <p className={styles.agreements}>
          {t('cancelAnyMoment')}
          <br />
          <Agreements
            buttonText={isSecurePayment ? t('toPay') : t('activate')}
          />
        </p>
      </div>
      <PlanReplaceModal
        open={agree}
        onClose={() => setAgree(false)}
        onContinue={onPay}
      />
      <PaymentMethodModal
        open={isOpenPaymentMethod}
        onClose={() => setOpenPaymentMethod(false)}
        goToPayment={goToPayment}
        isSecurePayment={isSecurePayment}
      />
    </div>
  );
};

export default PayAsYouGoPage;
