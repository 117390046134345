import React from 'react';
import styles from './Modal.module.scss';
import CloseIcon from '../../assets/icons/ic_close_dark.svg';

type ModalProps = {
  open: boolean;
  onClose?: () => void;
  modalPresentationMobile?: boolean;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  modalPresentationMobile,
  open,
}) => {
  if (!open) return null;

  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        onClose?.();
      }}
    >
      <div
        className={`${styles.content} ${
          modalPresentationMobile && styles.modalPresentationMobile
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!modalPresentationMobile && Boolean(onClose) && (
          <img
            src={CloseIcon}
            alt="Close"
            className={styles.close}
            onClick={onClose}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
