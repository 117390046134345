import axios from 'axios';
import * as profile from './profile';
import * as auth from './auth';
import * as plans from './plans';
import * as common from './common';
import * as ycoins from './ycoins';
import * as numbers from './numbers';
import * as notifications from './notifications';
import * as currencies from './currencies';
import store from '../store';
import { logOut } from '../store/thunks/authThunks';

axios.defaults.baseURL = 'https://api2.yesim.co.uk/';
axios.defaults.timeout = 90000;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.dispatch(logOut());
    }
    return Promise.reject(error);
  },
);

export { profile, auth, plans };

export const Api = {
  profile,
  auth,
  plans,
  common,
  ycoins,
  numbers,
  notifications,
  currencies,
};
