import React from 'react';
import styles from './InstallationCarousel.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type InstallationCarouselProps = {
  images: string[];
};

const InstallationCarousel: React.FC<InstallationCarouselProps> = ({
  images,
}) => {
  return (
    <div className={styles.container}>
      <Swiper
        modules={[Pagination]}
        spaceBetween={100}
        slidesPerView={1.2}
        centeredSlides
        pagination={{
          clickable: true,
          clickableClass: styles.pagination,
          bulletActiveClass: styles.active,
        }}
        className={styles.swiper}
        breakpoints={{
          375: {
            slidesPerView: 1.5,
          },
          768: {
            spaceBetween: 50,
            slidesPerView: 1.5,
          },
        }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item} alt="Image" className={styles.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default InstallationCarousel;
