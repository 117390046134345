import axios, { AxiosError } from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import { Token } from '../store/reducers/tokenReducer/tokenReducer.types';
import { ProfileResponse } from '../globalTypes';
import i18next from 'i18next';

export const sideAuth = async ({
  provider,
  email,
  accessToken,
  givenName,
  familyName,
  user,
}: {
  provider: 'google' | 'apple';
  email?: string;
  accessToken?: string;
  givenName?: string;
  familyName?: string;
  user?: string;
}): Promise<Token> =>
  await axios
    .post('/side_auth', null, {
      params: {
        email,
        accessToken,
        provider,
        givenName,
        familyName,
        user,
        platform: 3,
        version: process.env.REACT_APP_VERSION,
        lang: i18next.languages[0],
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch(catchApiMessage);

export const resend = async (email: string) =>
  await axios
    .post('/resend', null, {
      params: {
        email,
        lang: i18next.languages[0],
      },
    })
    .catch(catchApiMessage);

export const getProfile = async (): Promise<ProfileResponse> =>
  await axios
    .get('/profile', {
      params: {
        auth_key: getAuthKey(),
        flag: 'off',
        version: process.env.REACT_APP_VERSION,
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const sendEmailCode = async (
  email: string,
  captcha: string,
): Promise<Token> =>
  await axios
    .post('/auth_email', null, {
      params: {
        user_email: email,
        version: process.env.REACT_APP_VERSION,
        lang: i18next.languages[0],
        platform: 3,
        token: captcha,
      },
    })
    .then((res) => {
      return res.data['firstAuth'];
    })
    .catch(catchApiMessage);

export const checkEmailCode = async (
  email: string,
  code: string,
): Promise<Token> =>
  await axios
    .post('/auth_code', null, {
      params: {
        code,
        email,
        version: process.env.REACT_APP_VERSION,
        lang: i18next.languages[0],
        platform: 3,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
