import React from 'react';
import { updateCurrency } from '../../store/thunks/currenciesThunk';
import styles from './LoginButton.module.scss';
import apple from '../../assets/icons/ic_apple.svg';
import google from '../../assets/icons/ic_google.svg';
import { AuthThunks } from '../../store/thunks';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import * as ProfileThunks from '../../store/thunks/profileThunks';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import { sendPartnerLinkData } from 'src/utils/partnerLinkActions';

type LoginButtonProps = {
  service: 'apple' | 'google';
  id?: string;
};

const LoginButton: React.FC<LoginButtonProps> = ({ service, id }) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const { search } = useLocation();
  const { redirectTo } = qs.parse(search);

  const onSubmit = async () => {
    try {
      let firstAuth;

      if (service === 'apple') {
        firstAuth = await dispatch(AuthThunks.appleAuth());
      } else if (service === 'google') {
        firstAuth = await dispatch(AuthThunks.googleAuth());
      }

      await sendPartnerLinkData({ firstAuth: firstAuth ?? false });

      await dispatch(updateCurrency());

      const profile = await dispatch(ProfileThunks.getProfileData());

      if (redirectTo) {
        history.replace(
          `${redirectTo}${
            !!firstAuth
              ? `${redirectTo.includes('?') ? '&' : '?'}firstAuth=true`
              : ''
          }`,
        );
      } else {
        history.replace({
          pathname:
            profile?.classicPlan.active ||
            profile?.postponePlan.available ||
            profile?.payGoPlan.active
              ? '/'
              : '/countries',
          search: qs.stringify({
            ...qs.parse(search),
            ...(!!firstAuth
              ? {
                  firstAuth: true,
                }
              : {}),
          }),
        });
      }
    } catch (err: any) {
      setSnackbar({ message: t('somethingWentWrong'), type: 'error' });
    }
  };

  return (
    <button className={styles.container} onClick={onSubmit} id={id}>
      <img
        src={{ apple: apple, google: google }[service]}
        alt="icon"
        className={styles.icon}
      />
      <span className={styles.text}>
        {
          {
            apple: 'Apple',
            google: 'Google',
          }[service]
        }
      </span>
    </button>
  );
};

export default LoginButton;
