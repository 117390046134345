import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import i18next from 'i18next';

export const getNotifications = () =>
  axios
    .get('/get_notifications', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const markNotificationsAsRead = () =>
  axios
    .post('/notifications-received', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        note_id: 'all',
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const deleteNotification = (notificationId: string) =>
  axios
    .post('/delete-notification', null, {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
        note_id: notificationId,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
