import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import styles from './PayAsYouGoData.module.scss';
import PayAsYouGoIcon from '../../assets/images/pay_as_you_go.svg';
import LocationIcon from '../../assets/icons/ic_location_small.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';

const PayAsYouGoData: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const amountMb = useSelector(
    (state: RootState) => state.userProfile.payGoPlan.amountMb,
  );

  const esimStatus = useSelector(
    (state: RootState) => state.userProfile.esimStatus,
  );
  const LOCATION = esimStatus.country ? esimStatus.country : t('notDetected');

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <img src={PayAsYouGoIcon} alt="Pay as You Go" className={styles.icon} />
        <span className={styles.planName}>{t('internationalEsimPlan')}</span>
      </div>
      <Button
        label={t('main.topup')}
        onClick={() => history.push('/ycoins')}
        size="small"
        variant="secondary"
        className={styles.topUpButton}
      />
      <div className={styles.content}>
        <div>
          <p className={styles.label}>{t('availableAmount')}</p>{' '}
          <div className={styles.location}>
            <img src={LocationIcon} alt={''} />
            <span className={'f-14'}>{LOCATION}</span>
          </div>
        </div>
        <div className={styles.amount}>
          <p className={styles.value}>{amountMb || '-'}</p>
          <p className={styles.unit}>{'MB'}</p>
        </div>
      </div>
    </div>
  );
};

export default PayAsYouGoData;
