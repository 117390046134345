import React from 'react';
import { useTranslation } from 'react-i18next';
import Balance from '../Balance';
import Button from '../Button';
import styles from './BonusPopup.module.scss';
import CloseIcon from '../../assets/icons/ic_close_popup.svg';
import ArrowImage from '../../assets/images/bonus_popup_arrow.svg';

type BonusPopupProps = {
  open: boolean;
  onClose: () => void;
};

const BonusPopup: React.FC<BonusPopupProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <div className={styles.container}>
      <div className={styles.balance}>
        <Balance onClick={onClose} />
        <img src={ArrowImage} alt="Arrow" className={styles.arrowImage} />
      </div>
      <div className={styles.content}>
        <img
          src={CloseIcon}
          alt="Close"
          className={styles.closeIcon}
          onClick={onClose}
          id="bonus-popup-close-icon"
        />
        <h2 className={styles.title}>{t('theBonusHasBeenCredited')}</h2>
        <p className={styles.text}>{t('useYcoinsForYourPurchases')}</p>
        <Button
          label={t('ok')}
          onClick={onClose}
          variant="plain"
          id="bonus-popup-ok-button"
        />
      </div>
    </div>
  );
};

export default BonusPopup;
