import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/archiveNumbersReducer/archiveNumbersReducer';

export const getArchiveNumbers = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.numbers.getArchiveNumbers();
    dispatch(actions.saveArchiveNumbers(res['list']));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
