import React from 'react';
import styles from './Progress.module.scss';

type ProgressProps = {
  value: number;
  max?: number;
  label?: string | React.ReactNode;
  className?: string;
};

const Progress: React.FC<ProgressProps> = ({
  value,
  max,
  label,
  className,
}) => {
  const percentage = `${max ? (100 * value) / max : value}%`;

  return (
    <div>
      {label && <span className={styles.label}>{label}</span>}
      <div className={`${styles.track} ${className}`}>
        <div className={styles.progress} style={{ width: percentage }} />
      </div>
    </div>
  );
};

export default Progress;
