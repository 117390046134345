import {
  Notification,
  NotificationsActions,
  SAVE_NOTIFICATIONS,
} from './notificationsReducer.types';

export default function (
  state: Notification[] = [],
  action: NotificationsActions,
) {
  switch (action.type) {
    case SAVE_NOTIFICATIONS:
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  saveNotifications: (payload: Notification[]): NotificationsActions => ({
    type: SAVE_NOTIFICATIONS,
    payload,
  }),
};
