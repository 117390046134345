import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NotificationsIcon from '../../assets/icons/ic_header_notifications.svg';
import NotificationsActiveIcon from '../../assets/icons/ic_header_notifications_active.svg';
import { RootState } from '../../store';
import Badge from '../Badge';
import styles from './Notifications.module.scss';

const Notifications: React.FC = () => {
  const history = useHistory();
  const isActive = useRouteMatch('/notifications');
  const notifications = useSelector((state: RootState) => state.notifications);
  const notificationsCount = useMemo(
    () => notifications.filter((i) => i.received === '0').length,
    [notifications],
  );

  return (
    <Badge content={notificationsCount}>
      <div
        className={`${styles.container}${isActive ? ` ${styles.active}` : ''}`}
        id="header-notifications"
        onClick={() => history.push('/notifications')}
      >
        <img
          src={isActive ? NotificationsActiveIcon : NotificationsIcon}
          className={styles.icon}
          alt="Notifications"
        />
      </div>
    </Badge>
  );
};

export default Notifications;
