import moment from 'moment';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MessagesIcon from '../../assets/icons/ic_number_messages.svg';
import Badge from '../../components/Badge';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import { getArchiveNumbers } from '../../store/thunks/archiveNumbersThunks';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import getFlag from '../../utils/getFlag';
import styles from './NumbersArchivePage.module.scss';

const NumbersArchivePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useThunkDispatch();
  const archiveNumbers = useSelector(
    (state: RootState) => state.archiveNumbers,
  );
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      setLoading('pending');
      await dispatch(getArchiveNumbers());
      setLoading('fulfilled');
    }

    init();
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar title={t('archive')} goBack={() => history.push('/numbers')} />
        {archiveNumbers.length > 0 ? (
          <ul className={styles.list}>
            {archiveNumbers.map((item, index) => (
              <li
                key={index}
                className={styles.item}
                onClick={() => {
                  history.push({
                    pathname: '/senders',
                    search: qs.stringify({
                      number: item.number,
                    }),
                  });
                }}
              >
                <img
                  src={getFlag(item.iso)}
                  alt="Flag"
                  className={styles.flag}
                />
                <div className={styles.wrapper}>
                  <p className={styles.number}>
                    {formatPhoneNumber(item.number, item.code)}
                  </p>
                  <p className={styles.date}>
                    {`${moment(item.start).format('DD.MM.YYYY')} - ${moment(
                      item.end,
                    ).format('DD.MM.YYYY')}`}
                  </p>
                </div>
                <Badge content={item.unread_sms}>
                  <img
                    src={MessagesIcon}
                    alt="Messages"
                    className={styles.icon}
                  />
                </Badge>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.listEmpty}>{t('noNumbersYet')}</p>
        )}
      </div>
    </div>
  );
};

export default NumbersArchivePage;
