import { useHistory } from 'react-router-dom';
import { Api } from '../api';
import { getProfileData } from '../store/thunks/profileThunks';
import { useMountedState } from './useMountedState';
import { useThunkDispatch } from './useThunkDispatch';
import { useSnackbar } from './useSnackbar';
import { LoadingStatus } from '../globalTypes';

export function usePayGoActivation(
  setLoadingCallBack: (arg: LoadingStatus) => void,
) {
  const history = useHistory();
  const isMount = useMountedState();
  const dispatch = useThunkDispatch();
  const { setSnackbar } = useSnackbar();

  async function activatePayGo() {
    try {
      setLoadingCallBack('pending');
      await Api.profile.activatePayGoPlan();
      await dispatch(getProfileData());

      if (isMount()) {
        setLoadingCallBack('unknown');
        history.replace('/international-esim-activated');
      }
    } catch (error: any) {
      if (isMount()) setLoadingCallBack('unknown');
      setSnackbar({ message: error.message, type: 'error' });
    }
  }

  return { activatePayGo };
}
