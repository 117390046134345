import React from 'react';
import './PlanData.scss';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OfferIcon from 'src/assets/icons/ic_offer.svg';
import getFlag from 'src/utils/getFlag';
import qs from 'query-string';

const PlanData: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classicPlan = useSelector(
    (state: RootState) => state.userProfile.classicPlan,
  );

  let title = '';
  if (classicPlan.active) {
    title = classicPlan.name;
  }

  const planId = classicPlan.id;
  const iso = classicPlan.iso;

  if (!classicPlan.active) {
    return (
      <div className={'noActivePlan'}>
        <span className={'f-17 f-semibold'}>{t('no_active_plan')}</span>
        <span className={'text'}>{t('checkOutDataPlans')}</span>
        <span className={'button'} onClick={() => history.push('/countries')}>
          {t('browseDataPlans')}
        </span>
      </div>
    );
  }

  return (
    <div className="plan-container">
      <div className={'plan-header'}>
        <div className={'plan-flag'}>
          {classicPlan.package ? (
            !['0', '577', '1787', '2999'].includes(planId) ? (
              <span />
            ) : (
              <img src={OfferIcon} alt="Icon" className={'plan-lo-icon'} />
            )
          ) : !!iso ? (
            <img
              src={getFlag(iso.toLowerCase())}
              alt="Icon"
              className={'flagIcon'}
            />
          ) : (
            <span />
          )}

          <div>
            <p className={'t-white f-12 t-capitalize'}>{t('purchase.plan')}</p>
            <p className={'t-white f-17 f-medium'}>{title}</p>
          </div>
        </div>
        <div>
          <p className={'t-white f-12'}> {t('activeUntil')}</p>
          <p className={'t-white f-12'}> {classicPlan.dateEnd}</p>
        </div>
      </div>
      <div>
        <div className={'plan-top-up'}>
          <div>
            {classicPlan.availableMb === 'Unlim' || classicPlan.unlimited ? (
              <span className={'t-white f-semibold plan-amount'}>
                {t('unlimited')}
              </span>
            ) : (
              <span className={'t-white f-semibold plan-amount'}>
                {classicPlan.availableMb}
                <span className={'t-white f-semibold plan-mb'}>MB</span>
              </span>
            )}
          </div>
          {classicPlan.canTopUp && (
            <button
              onClick={() => {
                history.push({
                  pathname: '/plans',
                  search: qs.stringify({ isTopup: 1 }),
                });
              }}
              className={'plan-top-up-button'}
            >
              {t('main.topup')}
            </button>
          )}
        </div>
        {classicPlan.availableMb !== 'Unlim' && (
          <div className={'plan-mb-bar'}>
            <div
              className={'plan-mb-bar-amount'}
              style={{
                width: classicPlan.dataPercentLeft + '%',
                maxWidth: '100%',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanData;
