export class GoogleAuthService {
  getTokens() {
    return new Promise<string>(async (resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!window.google?.accounts?.oauth2) {
        reject({ message: 'Google auth is not available' });
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const client = await window.google.accounts.oauth2.initTokenClient({
          client_id:
            '251129642590-6h2eabhtak788mqjoicbh094llp0g9qt.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/userinfo.email',
          callback: (res: any) => {
            resolve(res?.access_token);
          },
        });
        await client.requestAccessToken();
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.google?.accounts?.id?.disableAutoSelect();
        reject({ message: 'Google sign in failed' });
      }
    });
  }

  logOut() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.google?.accounts?.id?.disableAutoSelect();
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
