import { PaymentMethods } from '../../../globalTypes';
import { Currency } from '../currenciesReducer/currenciesReducer.types';
import { LOG_OUT } from '../sharedActionTypes';
import {
  CHANGE_CURRENCY,
  CHANGE_CURRENCY_CODE,
  ProfileActions,
  SAVE_PROFILE,
  UPDATE_EMAIL,
  UserProfile,
} from './userProfileReducer.types';

const init: UserProfile = {
  id: '',
  email: '',
  activationsCount: 0,
  countOfPurchases: 0,
  ycoinsCountPurchases: 0,
  classicPlan: {
    id: '',
    countries: [],
    dataPercentLeft: 0,
    daysPercentLeft: 0,
    duration: '0',
    package: false,
    totalMb: '0',
    active: false,
    availableMb: '0',
    name: '',
    iso: '',
    dateEnd: '',
    packageId: '',
    unlimited: false,
    canTopUp: false,
  },
  esimStatus: {
    active: false,
    iso: '',
    country: '',
  },
  friendsAttracted: 0,
  haveEsimProfile: false,
  postponePlan: {
    available: false,
    id: '',
    name: '',
    totalMb: '',
    validityPeriod: '',
    iso: '',
    packageId: '',
  },
  referralCode: '',
  ycoins: 0,
  ycoinsBonusProgram: {
    available: false,
    bonusAmount: 0,
    bonusFrom: 500,
  },
  payGoPlan: {
    active: false,
    amountMb: '',
    wasActive: false,
  },
  esimInstalledInfo: {
    installed: false,
  },
  showFree500: false,
  coinsFromReferralFriends: 0,
  throttling: {
    limit: null,
    speed: null,
  },
  ycoinsTopUpInfo: {
    start: 1500,
    max: 15000,
    min: 1000,
    preset: [],
    minAuto: 100,
    autoTopUpAmount: 0,
  },
  lastPaymentMethod: {
    iso: '',
    system: PaymentMethods.UNKNOWN,
    type: '',
  },
  lang: 'en',
  lastPurchasedIso: [],
  paymentCurrency: {
    code: 'EUR',
    id: '',
    iso2: 'EU',
    iso3: 'EUR',
    rate: '',
    symbol: '€',
  },
  did_show: true,
  isoIp: '',
};

export default function (state = init, action: ProfileActions): UserProfile {
  switch (action.type) {
    case SAVE_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        paymentCurrency: action.payload,
      };
    case CHANGE_CURRENCY_CODE:
      return {
        ...state,
        paymentCurrency: {
          ...state.paymentCurrency,
          code: action.payload,
        },
      };
    case LOG_OUT:
      return init;
    default:
      return state;
  }
}

export const actions = {
  saveProfile: (profile: UserProfile): ProfileActions => ({
    type: SAVE_PROFILE,
    payload: profile,
  }),
  updateEmail: (email: string): ProfileActions => ({
    type: UPDATE_EMAIL,
    payload: email,
  }),
  changeCurrency: (currency: Currency): ProfileActions => ({
    type: CHANGE_CURRENCY,
    payload: currency,
  }),
  changeCurrencyCode: (currencyCode: string): ProfileActions => ({
    type: CHANGE_CURRENCY_CODE,
    payload: currencyCode,
  }),
};
