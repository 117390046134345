import React from 'react';
import styles from './SmsBadge.module.scss';

const SmsBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <p className={styles.text}>{'SMS'}</p>
    </div>
  );
};

export default SmsBadge;
