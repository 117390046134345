export const SAVE_ACTIVE_NUMBERS = 'SAVE_ACTIVE_NUMBERS';

export type ActiveNumber = {
  number: string;
  unread_sms: string;
  active_untill: string;
  subscription: boolean;
  plan_id: string;
  area_id: string;
  grace_period: string | null;
  area: string;
  iso: string;
  code: string;
  country: string;
  plan: {
    id: string;
    cost: string;
    title: string;
    period: string;
    days: string;
  };
  services: {
    name: string;
    icon: string;
  }[];
};

export interface SaveActiveNumbers {
  type: typeof SAVE_ACTIVE_NUMBERS;
  payload: ActiveNumber[];
}

export type ActiveNumbersActions = SaveActiveNumbers;
