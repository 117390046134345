import React from 'react';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { logOut } from '../../store/thunks/authThunks';
import styles from './LogOutPopup.module.scss';
import Modal from '../Modal';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import LogOutImage from '../../assets/images/log_out.svg';

type LogOutPopupProps = {
  open: boolean;
  onClose: () => void;
};

const LogOutPopup: React.FC<LogOutPopupProps> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useThunkDispatch();

  return (
    <Modal open={open}>
      <div className={styles.content}>
        <img src={LogOutImage} alt="Log out" className={styles.image} />
        <h2 className={styles.text}>{t('logOut')}</h2>
        <Button
          label={t('settings.logOut')}
          variant="secondary"
          className={styles.button}
          onClick={() => {
            window.location.replace(`https://yesim.app/${i18n.languages[0]}/`);
            dispatch(logOut());
            onClose();
          }}
        />
        <Button
          label={t('back')}
          variant="plain"
          className={styles.button}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default LogOutPopup;
