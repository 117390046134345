import React, { useEffect, useRef, useState } from 'react';
import './PaymentPage.scss';
import { Api } from 'src/api';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import qs from 'query-string';
import { useSnackbar } from 'src/hooks/useSnackbar';
import * as ProfileThunks from 'src/store/thunks/profileThunks';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import { useMountedState } from 'src/hooks/useMountedState';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import CheckoutForm from '../../components/CheckoutForm';
import GoBack from '../../components/GoBack';
import IncorrectCountryPopup from '../../components/IncorrectCountryPopup';
import { PaymentMethods } from '../../globalTypes';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

const PaymentPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useLoadingStatus('pending');
  const [askAltPayment, setAskAltPayment] = useState(false);
  const [altLink, setAltLink] = useState(false);
  const profile = useSelector((state: RootState) => state.userProfile);
  const dispatch = useThunkDispatch();
  const { setSnackbar } = useSnackbar();
  const ping = useRef<NodeJS.Timer | null>();
  const isMount = useMountedState();
  const {
    ycoins,
    planId,
    delayed,
    recurring,
    additional,
    country,
    type,
    prev,
    paymentMethod,
    payAsYouGoFirstPayment,
    buyNumberFirstPayment,
    cardIso,
    autoTopUp,
  } = qs.parse(search) as { [key: string]: string };
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [openIncorrectCountryPopup, setOpenIncorrectCountryPopup] =
    useState(false);

  async function pingPaymentStatus(paymentId: string) {
    try {
      const response = await Api.plans.checkPaymentStatus({ paymentId });
      if (response['status'] === 'decline_ru' && !!ping.current) {
        if (response['alt_payment']) {
          setAskAltPayment(true);
        } else {
          setOpenIncorrectCountryPopup(true);
        }
        clearInterval(ping.current);
        ping.current = null;
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    return () => {
      if (ping.current) {
        clearInterval(ping.current);
      }
    };
  }, []);

  async function signAltPayment() {
    try {
      setLoading('pending');

      if (type === 'ycoins' ? !ycoins : !planId) {
        history.replace('/countries');
        return;
      }

      const ecommpay = {
        customer_email: profile.email,
        payment_currency: profile.paymentCurrency.code,
        language_code: i18n.languages[0],
        customer_id: profile.id,
        redirect_success_url: `${window.location.origin}/marketplace/payment-success${search}&currency=${profile.paymentCurrency.code}`,
        redirect_fail_url: `${prev}${
          prev.includes('?') ? '&' : '?'
        }paymentFail=true`,
        redirect_return_url: prev,
        merchant_return_url: prev,
      };

      if (type === 'plan') {
        Api.plans
          .getPaymentData(
            {
              ycoins,
              planId,
              delayed,
              recurring,
              additional,
              country,
              ecommpay,
            },
            PaymentMethods.ECP,
            true,
            cardIso,
          )
          .then((res) => {
            window.location.assign(res['link']);

            setLoading('fulfilled');
          })
          .catch((error) => {
            setSnackbar({
              message: error?.message || t('somethingWentWrong'),
              type: 'error',
            });
            history.replace('/countries');
            setLoading('rejected');
          });
      } else if (type === 'ycoins') {
        Api.ycoins
          .buyYcoins(
            {
              ecommpay: { ...ecommpay, payment_amount: ycoins },
            },
            PaymentMethods.ECP,
            true,
            autoTopUp === 'true' ? true : undefined,
          )
          .then((res) => {
            window.location.assign(res['link']);
            setLoading('fulfilled');
          })
          .catch((error) => {
            setSnackbar({
              message: error?.message || t('somethingWentWrong'),
              type: 'error',
            });
            history.replace('/wallet');
            setLoading('rejected');
          });
      }
    } catch (e: any) {
      if (isMount()) {
        setSnackbar({
          message: e?.message || t('somethingWentWrong'),
          type: 'error',
        });
      }
    }
  }

  const successUrl = payAsYouGoFirstPayment
    ? `${window.location.origin}/marketplace/pay-as-you-go?payAsYouGoFirstPayment=true`
    : buyNumberFirstPayment
    ? `${window.location.origin}/marketplace/buy-number?${qs.stringify({
        ...qs.parse(search),
        prev: '',
      })}`
    : `${window.location.origin}/marketplace/payment-success?${qs.stringify({
        ...qs.parse(search),
        currency: profile.paymentCurrency.code,
        prev: '',
      })}`;
  const failUrl = `${prev}${prev.includes('?') ? '&' : '?'}paymentFail=true${
    type === 'ycoins'
      ? `&prevYcoins=${ycoins}&prevIsAutoTopUpChecked=${autoTopUp}`
      : ''
  }`;

  useEffect(() => {
    function payment() {
      if (
        type === 'ycoins'
          ? !ycoins
          : !planId && !payAsYouGoFirstPayment && !buyNumberFirstPayment
      ) {
        history.replace('/countries');
        return;
      }

      const ecommpay = {
        target_element: 'widget-container',
        customer_email: profile.email,
        payment_currency: profile.paymentCurrency.code,
        language_code: i18n.languages[0],
        customer_id: profile.id,
        redirect_success_url: successUrl,
        redirect_fail_url: failUrl,
        redirect_success_mode: 'parent_page',
        redirect_fail_mode: 'parent_page',
        ...(paymentMethod === PaymentMethods.BINANCE
          ? { payment_description: 'Yesim: eSIM Mobile Data App' }
          : {}),
      };

      Api.plans
        .getPaymentData(
          {
            ycoins:
              buyNumberFirstPayment || payAsYouGoFirstPayment ? 50 : ycoins,
            planId,
            delayed,
            recurring,
            additional,
            country,
            ecommpay,
          },
          paymentMethod,
          undefined,
          successUrl,
          failUrl,
          cardIso,
          undefined,
          autoTopUp === 'true' ? true : undefined,
        )
        .then(async (res) => {
          if (res['message'] === 'success' && res['activated'] === true) {
            dispatch(ProfileThunks.getProfileData());
            history.replace({ pathname: '/payment-success', search });
          } else if (res[0]?.ecommpay) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.EPayWidget.run(res[0].ecommpay);
            setLoading('fulfilled');
            ping.current = setInterval(
              () => pingPaymentStatus(res['payment_id']),
              2000,
            );
          } else if (res.payment_system === PaymentMethods.STRIPE) {
            const stripe = await loadStripe(
              res['system_data']['publishable_key'],
            );
            setStripe(stripe);
            setClientSecret(res['system_data']['client_secret']);
            setLoading('fulfilled');
          } else if (res.payment_system === PaymentMethods.BINANCE) {
            if (res?.system_data?.link?.data?.checkoutUrl) {
              window.location.replace(res.system_data.link.data.checkoutUrl);
            } else {
              throw new Error(res?.system_data?.link?.errorMessage);
            }
          } else if (
            res.payment_system === PaymentMethods.MONETIX ||
            res.payment_system === PaymentMethods.RAPYD ||
            res.payment_system === PaymentMethods.MELANGE ||
            res.payment_system === PaymentMethods.PAYPAL
          ) {
            if (res?.system_data?.link) {
              window.location.replace(res.system_data.link);
            } else {
              throw new Error(res?.system_data?.link?.errorMessage);
            }
          }
        })
        .catch((error) => {
          setSnackbar({
            message: error?.message || t('somethingWentWrong'),
            type: 'error',
          });
          history.replace('/countries');
          setLoading('rejected');
        });
    }

    payment();
  }, [cardIso, profile.paymentCurrency.code]);

  return (
    <>
      <div className={'payment-container'}>
        {loading === 'pending' && (
          <div className={'loader'}>
            <Loader />
          </div>
        )}
        {(paymentMethod === PaymentMethods.STRIPE ||
          paymentMethod === PaymentMethods.PAYPAL) &&
        clientSecret &&
        stripe ? (
          <Elements
            stripe={stripe}
            options={{
              clientSecret,
            }}
          >
            <CheckoutForm successUrl={successUrl} />
          </Elements>
        ) : !altLink ? (
          <div>
            <div className="payment-back">
              <GoBack />
            </div>
            <div id="widget-container" />
          </div>
        ) : (
          <span />
        )}
      </div>
      <Modal open={askAltPayment} modalPresentationMobile>
        <div className={'payment-alt-modal'}>
          <h2 className={'payment-alt-title'}>{t('bankRejected')}</h2>
          <Button
            label={t('tryAnotherMethod')}
            variant="primary"
            onClick={() => {
              signAltPayment();
              setAskAltPayment(false);
              setAltLink(true);
            }}
          />
        </div>
      </Modal>
      <IncorrectCountryPopup
        open={openIncorrectCountryPopup}
        onClose={() => setOpenIncorrectCountryPopup(false)}
        onSelectCountry={(cardIso) => {
          history.replace({
            pathname,
            search: qs.stringify({ ...qs.parse(search), cardIso }),
          });
        }}
      />
    </>
  );
};

export default PaymentPage;
