export class AppleAuthService {
  async getTokens() {
    interface AppleRes {
      authorization: {
        code: string;
        id_token: string;
      };
      user?: {
        name: {
          firstName?: string;
          lastName?: string;
        };
        email?: string;
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const AppleID = window.AppleID;

    if (!AppleID) {
      return Promise.reject(new Error('Sign with apple unavailable'));
    }
    const redirectURI = process.env.REACT_APP_APPLE_REDIRECT_URL;

    try {
      AppleID?.auth.init({
        clientId: 'com.yesim.app',
        scope: 'name email',
        redirectURI,
        usePopup: true, //or false defaults to false
      });
      const credential: AppleRes = await AppleID?.auth.signIn();

      const givenName = credential?.user?.name?.firstName;
      const familyName = credential?.user?.name?.lastName;
      const accessToken = credential?.authorization?.id_token;

      return {
        givenName,
        familyName,
        accessToken,
      };
    } catch (error: any) {
      return Promise.reject(new Error('Sign in failed'));
    }
  }
}
