import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useThunkDispatch } from '../../hooks/useThunkDispatch';
import { RootState } from '../../store';
import {
  deleteNotification,
  getNotifications,
  markNotificationsAsRead,
} from '../../store/thunks/notificationsThunks';
import styles from './NotificationsPage.module.scss';
import NotificationIcon from '../../assets/icons/ic_notification.svg';
import NotificationGrayIcon from '../../assets/icons/ic_notification_gray.svg';
import DeleteIcon from '../../assets/icons/ic_delete.svg';
import DeleteHoverIcon from '../../assets/icons/ic_delete_hover.svg';

const NotificationsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const notifications = useSelector((state: RootState) => state.notifications);
  const [loading, setLoading] = useLoadingStatus('pending');
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    async function inti() {
      await dispatch(getNotifications());
      await dispatch(markNotificationsAsRead());
      setLoading('fulfilled');
    }

    inti();

    return () => {
      dispatch(getNotifications());
    };
  }, []);

  async function onDelete(notificationId: string) {
    try {
      setLoading('pending');
      await dispatch(deleteNotification(notificationId));
      setLoading('fulfilled');
      setSnackbar({ message: t('success'), type: 'success' });
    } catch (e: any) {
      setLoading('rejected');
      setSnackbar({ message: e.message, type: 'error' });
    }
  }

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {notifications.length > 0 ? (
        <ul className={styles.content}>
          {notifications.map((item, index) => (
            <li
              className={`${styles.item}${
                item.received === '1' ? ` ${styles.received}` : ''
              }`}
              key={index}
            >
              <img
                src={
                  item.received === '1'
                    ? NotificationGrayIcon
                    : NotificationIcon
                }
                alt="Notification"
                className={styles.notificationIcon}
              />
              <div className={styles.wrapper}>
                <h4 className={styles.title}>{item.title}</h4>
                <p className={styles.message}>{item.message}</p>
                <p className={styles.date}>{item.date_add}</p>
              </div>
              <img
                src={DeleteIcon}
                alt="Delete"
                className={styles.deleteIcon}
                onMouseOver={(e) => (e.currentTarget.src = DeleteHoverIcon)}
                onMouseOut={(e) => (e.currentTarget.src = DeleteIcon)}
                onClick={() => onDelete(item.id)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.listEmpty}>{t('noNotifications')}</p>
      )}
    </div>
  );
};

export default NotificationsPage;
