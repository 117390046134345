import {
  Category,
  Task,
  HistoryItem,
  SAVE_CATEGORIES,
  SAVE_TASKS,
  SAVE_HISTORY,
  YcoinsActions,
  YcoinsState,
} from './ycoinsReducer.types';

const initialState: YcoinsState = {
  categories: [],
  tasks: [],
  history: [],
};

export default function (state = initialState, action: YcoinsActions) {
  switch (action.type) {
    case SAVE_CATEGORIES:
      return { ...state, categories: action.payload };
    case SAVE_TASKS:
      return { ...state, tasks: action.payload };
    case SAVE_HISTORY:
      return { ...state, history: action.payload };
    default:
      return state;
  }
}

export const actions = {
  saveCategories: (categories: Category[]): YcoinsActions => ({
    type: SAVE_CATEGORIES,
    payload: categories,
  }),
  saveTasks: (tasks: Task[]): YcoinsActions => ({
    type: SAVE_TASKS,
    payload: tasks,
  }),
  saveHistory: (history: HistoryItem[]): YcoinsActions => ({
    type: SAVE_HISTORY,
    payload: history,
  }),
};
