import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import ru from './ru.json';
import es from './es.json';
import de from './de.json';
import cs from './cs.json';
import nl from './nl.json';
import fr from './fr.json';
import ja from './ja.json';
import uk from './uk.json';
import hr from './hr.json';
import kk from './kk.json';
import sv from './sv.json';
import moment from 'moment';

const lang = localStorage.getItem('lang');

moment.locale(lang || 'en');

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use({
    type: 'postProcessor',
    name: 'nonBreakingSpace',
    process: (value: string) => value.replace(/\\u00A0/g, '\u00A0'),
  })
  .init({
    resources: {
      en: { translation: { ...en } },
      ru: { translation: { ...ru } },
      es: { translation: { ...es } },
      de: { translation: { ...de } },
      cs: { translation: { ...cs } },
      nl: { translation: { ...nl } },
      fr: { translation: { ...fr } },
      ja: { translation: { ...ja } },
      uk: { translation: { ...uk } },
      hr: { translation: { ...hr } },
      kk: { translation: { ...kk } },
      sv: { translation: { ...sv } },
    },
    lng: lang || undefined,
    fallbackLng: 'en',
    load: 'languageOnly',
    supportedLngs: [
      'en',
      'ru',
      'es',
      'de',
      'cs',
      'nl',
      'fr',
      'ja',
      'uk',
      'hr',
      'kk',
      'sv',
    ],
    interpolation: {
      escapeValue: false,
    },
    postProcess: ['nonBreakingSpace'],
  });

export default i18n;
