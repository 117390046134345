import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../Button';
import styles from './ExtraBonusPopup.module.scss';
import CloseIcon from '../../assets/icons/ic_close_popup.svg';
import ExtraBonusImage from '../../assets/images/extra_bonus.png';
import BackgroundImage from '../../assets/images/extra_bonus_background.png';

type ExtraBonusPopupProps = {
  open: boolean;
  onClose: () => void;
};

const ExtraBonusPopup: React.FC<ExtraBonusPopupProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const popupRef = useRef();

  useEffect(() => {
    function handleClick(e: any) {
      if (popupRef && popupRef.current) {
        const ref: any = popupRef.current;
        if (!ref.contains(e.target)) {
          onClose();
        }
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  if (!open) return null;

  return (
    <div className={styles.container} id="extra-bonus-popup-container">
      <div className={styles.content} ref={popupRef as any}>
        <img
          src={BackgroundImage}
          alt="Background"
          className={styles.backgroundImage}
        />
        <img
          src={CloseIcon}
          alt="Close"
          className={styles.closeIcon}
          onClick={onClose}
          id="extra-bonus-popup-close-icon"
        />
        <div className={styles.top}>
          <img
            src={ExtraBonusImage}
            alt="Extra bonus"
            className={styles.image}
          />
        </div>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>{t('getExtraBonus')}</h2>
          <p className={styles.text}>{'500 Ycoins = 5€'}</p>
          <Button
            label={t('claimTheBonus')}
            onClick={() => {
              history.push({ pathname: '/ycoins', search });
              onClose();
            }}
            variant="plain"
            className={styles.button}
            id="extra-bonus-popup-button"
          />
        </div>
      </div>
    </div>
  );
};

export default ExtraBonusPopup;
