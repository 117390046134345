import moment from 'moment/moment';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getSenders } from '../../api/numbers';
import BubblesIcon from '../../assets/icons/ic_bubbles.svg';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import SearchInput from '../../components/SearchInput';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { usePagination } from '../../hooks/usePagination';
import styles from './SendersPage.module.scss';

type Sender = {
  sender: string;
  unread: string;
  last_sms: {
    id: string;
    text: string;
    date: string;
  };
};

const SendersPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { number } = qs.parse(search) as { [key: string]: string };
  const [data, setData] = useState<Sender[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useLoadingStatus('pending');
  const [pagination, setPagination] = usePagination();
  const [loadingMore, setLoadingMore] = useState(false);

  async function getData() {
    const res = await getSenders({ number, search: filter });

    if (Array.isArray(res?.['list'])) {
      setData(res['list']);
      setPagination(res['pagination']);
      setLoadingMore(false);
    }
  }

  async function onLoadMore() {
    setLoadingMore(true);

    const res = await getSenders({
      number,
      search: filter,
      pagination: {
        ...pagination,
        page: ++pagination.page,
      },
    });

    if (Array.isArray(res?.['list'])) {
      setData([...data, ...res['list']]);
      setPagination(res['pagination']);
      setLoadingMore(false);
    }
  }

  useEffect(() => {
    async function init() {
      setLoading('pending');
      await getData();
      setLoading('fulfilled');
    }

    init();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading === 'fulfilled') {
        getData();
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  const list = useMemo(
    () => data.sort((a, b) => (a.unread > b.unread ? -1 : 1)),
    [data],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar title={t('messages')} goBack={() => history.push('/numbers')}>
          <SearchInput
            placeholder={t('searchBySender')}
            value={filter}
            onChangeText={setFilter}
          />
        </NavBar>
        {data.length > 0 ? (
          <>
            <ul className={styles.list}>
              {list.map((item, index) => (
                <li
                  key={index}
                  className={styles.item}
                  onClick={() => {
                    history.push({
                      pathname: '/messages',
                      search: qs.stringify({
                        sender: item.sender,
                      }),
                    });
                  }}
                >
                  <img
                    src={BubblesIcon}
                    alt="Bubbles"
                    className={styles.icon}
                  />
                  <div className={styles.wrapper}>
                    <div className={styles.top}>
                      <p className={styles.number}>{item.sender}</p>
                      <p className={styles.date}>
                        {moment(item.last_sms.date).format('D MMMM')}
                      </p>
                    </div>
                    <div className={styles.bottom}>
                      <p className={styles.message}>{item.last_sms.text}</p>
                      {item.unread !== '0' && (
                        <div className={styles.badge}>
                          <p className={styles.unread}>{item.unread}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {!loadingMore && +pagination.page < +pagination.total && (
              <Button
                className={styles.loadMore}
                label={t('loadMore')}
                onClick={onLoadMore}
              />
            )}
          </>
        ) : (
          <p className={styles.listEmpty}>{t('noMessagesYet')}</p>
        )}
      </div>
    </div>
  );
};

export default SendersPage;
