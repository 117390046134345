import { combineReducers } from 'redux';

import tokenReducer from './tokenReducer/tokenReducer';
import userProfileReducer from './userProfileReducer/userProfileReducer';
import snackbarReducer from './snackbarReducer/snackbarReducer';
import countriesPackagesReducer from './countriesPackagesReducer/countriesPackagesReducer';
import ycoinsReducer from './ycoinsReducer/ycoinsReducer';
import esimProfileReducer from './esimProfileReducer/esimProfileReducer';
import numbersCountriesReducer from './numbersCountriesReducer/numbersCountriesReducer';
import numbersServicesReducer from './numbersServicesReducer/numbersServicesReducer';
import activeNumbersReducer from './activeNumbersReducer/activeNumbersReducer';
import archiveNumbersReducer from './archiveNumbersReducer/archiveNumbersReducer';
import notificationsReducer from './notificationsReducer/notificationsReducer';
import currenciesReducer from './currenciesReducer/currenciesReducer';

const appReducer = combineReducers({
  token: tokenReducer,
  countriesPackages: countriesPackagesReducer,
  snackbar: snackbarReducer,
  userProfile: userProfileReducer,
  ycoins: ycoinsReducer,
  esimProfile: esimProfileReducer,
  numbersCountries: numbersCountriesReducer,
  numbersServices: numbersServicesReducer,
  activeNumbers: activeNumbersReducer,
  archiveNumbers: archiveNumbersReducer,
  notifications: notificationsReducer,
  currencies: currenciesReducer,
});

export default appReducer;
