import { AppThunk } from '../';
import { Dispatch } from 'redux';
import { Api } from 'src/api';
import { actions as YcoinsActions } from '../reducers/ycoinsReducer/ycoinsReducer';

export const getHistory = (): AppThunk => async (dispatch: Dispatch) => {
  try {
    const res = await Api.ycoins.getHistory();
    dispatch(YcoinsActions.saveHistory(res));
    return;
  } catch (error: any) {
    return;
  }
};
