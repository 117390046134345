import React, { useState } from 'react';
import styles from './Suggestions.module.scss';
import { useTranslation } from 'react-i18next';
import img_1 from '../../assets/images/suggestions/img_1.png';
import img_1_item_sm from '../../assets/images/suggestions/img_1_item_sm.png';
import img_1_item_lg from '../../assets/images/suggestions/img_1_item_lg.png';
import img_2 from '../../assets/images/suggestions/img_2.png';
import img_2_item_sm from '../../assets/images/suggestions/img_2_item_sm.png';
import img_2_item_lg from '../../assets/images/suggestions/img_2_item_lg.png';
import img_3 from '../../assets/images/suggestions/img_3.png';
import img_3_item_sm from '../../assets/images/suggestions/img_3_item_sm.png';
import img_3_item_lg from '../../assets/images/suggestions/img_3_item_lg.png';
import img_4 from '../../assets/images/suggestions/img_4.png';
import img_4_item_sm from '../../assets/images/suggestions/img_4_item_sm.png';
import img_4_item_lg from '../../assets/images/suggestions/img_4_item_lg.png';
import img_5 from '../../assets/images/suggestions/img_5.png';
import img_5_item_sm from '../../assets/images/suggestions/img_5_item_sm.png';
import img_5_item_lg from '../../assets/images/suggestions/img_5_item_lg.png';
import img_6 from '../../assets/images/suggestions/img_6.png';
import img_6_item_sm from '../../assets/images/suggestions/img_6_item_sm.png';
import img_6_item_lg from '../../assets/images/suggestions/img_6_item_lg.png';
import CloseIcon from '../../assets/icons/ic_close_light.svg';
import PrevIcon from '../../assets/icons/ic_prev.svg';
import NextIcon from '../../assets/icons/ic_next.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import Button from '../Button';

const Suggestions: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { ycoinsBonusProgram, payGoPlan } = useSelector(
    (state: RootState) => state.userProfile,
  );
  const [suggestions] = useState(
    [
      {
        title: t('welcomeYesim'),
        text: t('storyWelcomeYesim'),
        image: img_1,
        itemImageLarge: img_1_item_lg,
        itemImageSmall: img_1_item_sm,
        color: '#FF9900',
      },
      {
        title: t('storyCoinsBonus', {
          bonusAmount: ycoinsBonusProgram.bonusAmount / 100,
        }),
        text: t('storyCoinsBonusDesc', {
          bonusFrom: ycoinsBonusProgram.bonusFrom / 100,
          bonusAmount: ycoinsBonusProgram.bonusAmount / 100,
        }),
        image: img_2,
        itemImageLarge: img_2_item_lg,
        itemImageSmall: img_2_item_sm,
        color: '#012001',
        badge: t('bonusProgram'),
        action: {
          label: t('getBonus'),
          onClick: () => history.push('/ycoins'),
        },
        hidden: !ycoinsBonusProgram.available,
      },
      {
        title: t('internationalEsimPlan'),
        text: t('storyPayGo'),
        image: img_3,
        itemImageLarge: img_3_item_lg,
        itemImageSmall: img_3_item_sm,
        color: '#3B98AD',
        badge: t('newTarif'),
        action: {
          label: t('learnMore'),
          onClick: () => history.push('/international-esim-introduction'),
        },
        hidden: payGoPlan.active,
      },
      {
        title: t('activeWhenBest'),
        text: t('storyActiveWhenBest'),
        image: img_4,
        itemImageLarge: img_4_item_lg,
        itemImageSmall: img_4_item_sm,
        color: '#AE3B79',
        badge: t('usefulFeature'),
      },
      {
        title: t('giveGet'),
        text: t('storyCoinsYouFriend'),
        image: img_5,
        itemImageLarge: img_5_item_lg,
        itemImageSmall: img_5_item_sm,
        color: '#6D3BAD',
        badge: t('bonusProgram'),
        action: {
          label: t('referFriend'),
          onClick: () => history.push('/wallet'),
        },
      },
      {
        title: t('topUpYesimBalance'),
        text: t('storyTopUpWallet'),
        image: img_6,
        itemImageLarge: img_6_item_lg,
        itemImageSmall: img_6_item_sm,
        color: '#6D3BAD',
        badge: t('bonusProgram'),
        action: {
          label: t('main.topup'),
          onClick: () => history.push('/wallet'),
        },
      },
    ].filter((i) => !i.hidden),
  );
  const length = suggestions.length;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('suggestedForYou')}</h1>
      <ul className={styles.list}>
        {suggestions.map((item, index) => (
          <li
            key={index}
            className={styles.item}
            onClick={() => {
              setCurrentIndex(index);
              setOpen(true);
            }}
          >
            <img
              src={item.itemImageLarge}
              alt="Suggestion"
              className={styles.itemImageLarge}
            />
            <img
              src={item.itemImageSmall}
              alt="Suggestion"
              className={styles.itemImageSmall}
            />
            <h3 className={styles.title}>{item.title}</h3>
          </li>
        ))}
      </ul>
      <div className={`${styles.suggestion} ${open && styles.open}`}>
        <img
          src={CloseIcon}
          alt="Close"
          className={styles.close}
          onClick={() => setOpen(false)}
        />
        <img
          src={PrevIcon}
          alt="Prev"
          className={styles.prev}
          onClick={() =>
            setCurrentIndex(
              currentIndex - 1 < 0 ? length - 1 : currentIndex - 1,
            )
          }
        />
        <img
          src={NextIcon}
          alt="Next"
          className={styles.next}
          onClick={() =>
            setCurrentIndex(currentIndex + 1 >= length ? 0 : currentIndex + 1)
          }
        />
        {suggestions.map((item, index) => (
          <div
            key={index}
            className={`${styles.content} ${
              currentIndex === index && styles.show
            }`}
          >
            {item.badge && <div className={styles.badge}>{item.badge}</div>}
            <img src={item.image} alt="Suggestion" className={styles.image} />
            <div
              className={styles.wrapper}
              style={{
                background: `linear-gradient(180deg, rgba(59, 152, 173, 0) 0%, ${item.color} 23.3%)`,
              }}
            >
              <h1 className={styles.title}>{item.title}</h1>
              <p className={styles.text}>{item.text}</p>
              {item.action && (
                <Button
                  label={item.action.label}
                  onClick={item.action.onClick}
                  variant="secondary"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Suggestions;
