export default (data: {
  event?:
    | 'view_item_list'
    | 'select_item'
    | 'view_item'
    | 'begin_checkout'
    | 'purchase';
  ecommerce?: any;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(data);
};
