import { AppThunk } from '../index';
import { Api } from 'src/api';
import { actions } from '../reducers/numbersCountriesReducer/numbersCountriesReducer';

export const getNumbersCountries = (): AppThunk => async (dispatch) => {
  try {
    const res = await Api.numbers.getNumbersCountry();
    dispatch(actions.saveNumbersCountries(res['list']));
    return;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
