import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import i18next from 'i18next';

export const getCurrencies = () =>
  axios
    .get('/currencies', {
      params: {
        auth_key: getAuthKey(),
        lang: i18next.languages[0],
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const changeCurrency = (currency: string) =>
  axios
    .post('/change_currency', null, {
      params: {
        auth_key: getAuthKey(),
        currency,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
