import React from 'react';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './MainPage.module.scss';
import YesimInstallation from '../../assets/images/yesim_installation.svg';
import BuyPlan from '../../assets/images/buy_plan.svg';
import payGoRound from '../../assets/icons/ic_pay_go_round.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlanData from '../../components/PlanData';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import PostponePlan from '../../components/PostponePlan';
import PayAsYouGoData from '../../components/PayAsYouGoData';
import DeactivatePayAsYouGo from '../../components/DeactivatePayAsYouGo';
import CoverageIcon from '../../assets/icons/ic_coverage.svg';
import Suggestions from '../../components/Suggestions';
import ThrottlingBlock from '../../components/ThrottlingBlock';
import NetworkOperatorsIcon from '../../assets/icons/ic_network_operators.svg';
import qs from 'query-string';

const MainPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.userProfile);
  const payAsYouGo = useSelector(
    (state: RootState) => state.userProfile.payGoPlan,
  );

  const havePostponePlan = useSelector(
    (state: RootState) => state.userProfile.postponePlan.available,
  );

  const qrAssigned = useSelector(
    (state: RootState) => state.userProfile.haveEsimProfile,
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('myEsim')}</h2>
        {user.throttling.limit && user.throttling.speed && <ThrottlingBlock />}
        {payAsYouGo.active ? <PayAsYouGoData /> : <PlanData />}
        <div className={styles.options}>
          <div
            className={styles.item}
            onClick={() => history.push('/countries')}
          >
            <img src={BuyPlan} alt="Buy plan" />
            <div className={styles.wrapper}>
              <h2>{t('buyNewPlan')}</h2>
              <span className={styles.details}>
                {t('chooseTheSuitableDataPlan')}
              </span>
            </div>
          </div>
          {!payAsYouGo.active && (
            <div
              className={styles.item}
              onClick={() => {
                history.push(
                  payAsYouGo.wasActive
                    ? '/international-esim'
                    : '/international-esim-introduction',
                );
                if (!payAsYouGo.active) {
                  pushDataLayerEvent({
                    event: 'select_item',
                    ecommerce: {
                      items: [
                        {
                          item_id: 'pay_as_you_go',
                          item_name: 'Pay as You Go',
                        },
                      ],
                    },
                  });
                }
              }}
            >
              <img src={payGoRound} alt="pay_go" />
              <div className={styles.wrapper}>
                <h2>{`${t('activate')} ${t('internationalEsimPlan')}`}</h2>
                <span className={styles.details}>{t('unlimitedData')}</span>
              </div>
            </div>
          )}
          {payAsYouGo.active && (
            <div
              className={styles.item}
              onClick={() => history.push('/country-prices')}
            >
              <img src={CoverageIcon} alt="Icon" />
              <div className={styles.wrapper}>
                <h2>{t('countryPrice')}</h2>
                <span className={styles.details}>
                  {t('checkPayAsYouGoRates')}
                </span>
              </div>
            </div>
          )}
          {(payAsYouGo.active || user.classicPlan.active) && (
            <div
              className={styles.item}
              onClick={() => {
                history.push({
                  pathname: '/network-operators',
                  search: qs.stringify({
                    countries: user.classicPlan.active
                      ? user.classicPlan.countries.map((i) => i.iso)
                      : null,
                  }),
                });
              }}
            >
              <img src={NetworkOperatorsIcon} alt="Icon" />
              <h2>{t('cellOperators')}</h2>
            </div>
          )}
          {qrAssigned && (
            <div
              className={styles.item}
              onClick={() => history.push('/installation')}
            >
              <img src={YesimInstallation} alt="Yesim Installation" />
              <div className={styles.wrapper}>
                <h2>{t('yesimInstallation')}</h2>
                <span className={styles.details}>
                  {`${t('esimStatus')}: `}
                  {user.esimInstalledInfo.installed ? (
                    <span className={styles.installed}>{t('qrInstalled')}</span>
                  ) : (
                    <span className={styles.notInstalled}>
                      {t('qrNotInstalled')}
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
        {payAsYouGo.active && <DeactivatePayAsYouGo />}
        {havePostponePlan && <PostponePlan />}
      </div>
      <Suggestions />
    </div>
  );
};

export default MainPage;
