import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import styles from './PurchaseHistoryPage.module.scss';
import Invoice from '../../components/Invoice';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { getPurchseHistory } from 'src/api/plans';
import { getHistory } from 'src/api/ycoins';
import Loader from 'src/components/Loader';
import { DelayedPlan, HistoryInvoice } from 'src/globalTypes';
import { useTranslation } from 'react-i18next';
import SegmentedControl from 'src/components/SegmentedControl';
import moment from 'moment';
import { HistoryItem } from 'src/store/reducers/ycoinsReducer/ycoinsReducer.types';
import YcoinsIcon from '../../assets/icons/ic_ycoins.svg';
import CreateInvoiceModal from '../../components/CreateInvoiceModal';
import formatMegabytes from '../../utils/formatMegabytes';

const PurchaseHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const paymentCurrency = useSelector(
    (state: RootState) => state.userProfile.paymentCurrency,
  );
  const [loading, setLoading] = useLoadingStatus('pending');
  const [plansHistory, setPlansHistory] = useState<HistoryInvoice[]>([]);
  const [walletHistory, setWalletHistory] = useState<
    { data: HistoryItem[]; date: string }[]
  >([]);
  const [segment, setSegment] = useState(0);
  const [isOpenCreateInvoiceModal, setOpenCreateInvoiceModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<HistoryInvoice | null>(null);

  useEffect(() => {
    async function init() {
      try {
        setLoading('pending');
        const plansHistory = await getPurchseHistory();
        const walletHistory = await getHistory();
        setPlansHistory(plansHistory);
        setWalletHistory(
          walletHistory
            .sort((a, b) => moment(b.date).diff(a.date))
            .reduce(
              (
                accumulator: { data: HistoryItem[]; date: string }[],
                currentValue: HistoryItem,
                currentIndex: number,
                array: any,
                date = currentValue.date,
              ) => {
                const keyObjectPosition = accumulator.findIndex((item) =>
                  moment(item.date).isSame(date, 'day'),
                );
                if (keyObjectPosition >= 0) {
                  accumulator[keyObjectPosition].data.push(currentValue);
                  return accumulator;
                } else {
                  return accumulator.concat({ data: [currentValue], date });
                }
              },
              [],
            ),
        );
        setLoading('fulfilled');
      } catch (err: any) {
        setLoading('rejected');
      }
    }
    init();
  }, [paymentCurrency.code]);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>{t('settings.purchaseHistory')}</h1>
      <div className={styles.content}>
        <SegmentedControl
          options={[
            { label: t('purchase.plan'), value: 0 },
            { label: t('yourBalance'), value: 1 },
          ]}
          value={segment}
          setValue={setSegment}
        />
        {segment === 0 &&
          (plansHistory.length > 0 ? (
            <div className={styles.list}>
              {plansHistory.map((item, index) => (
                <Invoice
                  key={index}
                  planId={item.planId}
                  iso={item.iso}
                  packageId={item.package_id}
                  plan={item.name}
                  totalMb={item.data}
                  period={item.days}
                  total={item.summ}
                  referralCost={item.discount}
                  planCost={item.total_summ}
                  date={item.date_add}
                  activateLater={parseInt(item.delayed) as DelayedPlan}
                  unlimited={item.unlimited}
                  price={item.price}
                  invoiceAmount={item.sum_amount}
                  onCreateInvoice={() => {
                    setSelectedPlan(item);
                    setOpenCreateInvoiceModal(true);
                  }}
                />
              ))}
            </div>
          ) : (
            <p className={styles.empty}>{t('purchase.historyIsEmpty')}</p>
          ))}
        {segment === 1 &&
          (walletHistory.length > 0 ? (
            <div className={styles.list}>
              {walletHistory.map((section, index) => (
                <div key={index} className={styles.section}>
                  <p className={styles.date}>
                    {moment(section.date).format('DD MMMM')}
                  </p>
                  {section.data.map((item, index) => (
                    <div key={index} className={styles.item}>
                      <div>
                        <p className={styles.text}>{item.title}</p>
                        <p className={styles.type}>{item.description}</p>
                      </div>
                      <div>
                        <div className={styles.ycoins}>
                          <p
                            className={`${styles.value} ${
                              item.withdrawal && styles.reduction
                            }`}
                          >
                            {Math.abs(Number(item.coins))}
                          </p>
                          <img
                            src={YcoinsIcon}
                            alt="Ycoins"
                            className={styles.icon}
                          />
                        </div>
                        <p className={styles.time}>
                          {moment(item.date).format('HH:mm')}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.empty}>{t('purchase.historyIsEmpty')}</p>
          ))}
      </div>
      {selectedPlan?.payment_id && (
        <CreateInvoiceModal
          open={isOpenCreateInvoiceModal}
          onClose={() => setOpenCreateInvoiceModal(false)}
          currency={selectedPlan.sum_currency || 'EUR'}
          paymentId={selectedPlan.payment_id}
          planName={`${selectedPlan.name} ${
            selectedPlan.unlimited
              ? 'Unlim'
              : formatMegabytes(selectedPlan.data)
          } ${selectedPlan.days} ${
            Number(selectedPlan.days) === 1 ? 'day' : 'days'
          }`}
          purchaseDate={moment(selectedPlan.date_add).format('DD.MM.YYYY')}
          purchaseAmount={selectedPlan.sum_amount || '0.00'}
        />
      )}
    </div>
  );
};

export default PurchaseHistoryPage;
