import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getNumbersForService, getNumbersList } from '../../api/numbers';
import ArrowIcon from '../../assets/icons/ic_arrow_forward.svg';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import SearchInput from '../../components/SearchInput';
import SmsBadge from '../../components/SmsBadge';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { usePagination } from '../../hooks/usePagination';
import { RootState } from '../../store';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import getFlag from '../../utils/getFlag';
import pushDataLayerEvent from '../../utils/pushDataLayerEvent';
import styles from './SelectNumberPage.module.scss';

type NumberItem = {
  area_id: string;
  code: string;
  country: string;
  district: string;
  iso: string;
  needs_registration: string;
  number: string;
  price_from: string;
  price_uuid: string;
  services: { icon: string; name: string }[];
  price: {
    amount: string;
    currency: string;
    iso2: string;
    iso3: string;
    symbol: string;
  };
};

const SelectNumberPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { districtId, serviceId, serviceName } = qs.parse(search) as {
    [key: string]: string;
  };
  const profile = useSelector((state: RootState) => state.userProfile);
  const [filter, setFilter] = useState('');
  const [data, setData] = useState<NumberItem[]>([]);
  const [loading, setLoading] = useLoadingStatus('pending');
  const [pagination, setPagination] = usePagination();
  const [loadingMore, setLoadingMore] = useState(false);

  async function getData() {
    let res;

    if (districtId) {
      res = await getNumbersList({ districtId, search: filter });
    }

    if (serviceId) {
      res = await getNumbersForService({ serviceId, search: filter });
    }

    if (Array.isArray(res?.['list'])) {
      setData(res['list']);
      setPagination(res['pagination']);
      setLoadingMore(false);
    }
  }

  async function onLoadMore() {
    setLoadingMore(true);

    let res;

    if (districtId) {
      res = await getNumbersList({
        districtId,
        search: filter,
        pagination: {
          ...pagination,
          page: ++pagination.page,
        },
      });
    }

    if (serviceId) {
      res = await getNumbersForService({
        serviceId,
        search: filter,
        pagination: {
          ...pagination,
          page: ++pagination.page,
        },
      });
    }

    if (Array.isArray(res?.['list'])) {
      setData([...data, ...res['list']]);
      setPagination(res['pagination']);
      setLoadingMore(false);
    }
  }

  useEffect(() => {
    async function init() {
      setLoading('pending');
      await getData();
      setLoading('fulfilled');
    }

    init();
  }, [profile.paymentCurrency.code]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading === 'fulfilled') {
        getData();
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  useEffect(() => {
    pushDataLayerEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'numbers_list',
        item_list_name: 'Choose number',
      },
    });
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NavBar
          title={
            serviceName
              ? t('numbersFor', { service: serviceName })
              : t('chooseNumber')
          }
          goBack={() => history.push('/numbers')}
        >
          <SearchInput
            placeholder={t('search')}
            value={filter}
            onChangeText={setFilter}
          />
        </NavBar>
        {data.length > 0 ? (
          <ul className={styles.list}>
            {data.map((item, index) => (
              <li
                key={index}
                className={styles.item}
                onClick={() => {
                  history.push({
                    pathname: '/buy-number',
                    search: qs.stringify({
                      number: item.number,
                      code: item.code,
                      country: item.country,
                      district: item.district,
                      iso: item.iso,
                      ...(item.services?.length > 0
                        ? {
                            services: item.services
                              .map((i) => i.icon)
                              .join(','),
                          }
                        : {}),
                    }),
                  });
                  pushDataLayerEvent({
                    event: 'select_item',
                    ecommerce: {
                      item_list_id: 'numbers_list',
                      item_list_name: 'Choose number',
                      items: [
                        {
                          item_id: item.number,
                          item_name: formatPhoneNumber(item.number, item.code),
                          item_list_id: 'numbers_list',
                          item_list_name: 'Choose number',
                        },
                      ],
                    },
                  });
                }}
              >
                <img
                  src={getFlag(item.iso)}
                  alt="Flag"
                  className={styles.flag}
                />
                <div className={styles.wrapper}>
                  <div className={styles.data}>
                    <p className={styles.number}>
                      {formatPhoneNumber(item.number, item.code)}
                    </p>
                    <p className={styles.price}>
                      {t('fromPrice', {
                        price: `${item.price.amount}${item.price.symbol}`,
                      })}
                    </p>
                  </div>
                  <SmsBadge />
                  <img
                    src={ArrowIcon}
                    alt="Arrow"
                    className={styles.arrowIcon}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.listEmpty}>{t('noNumbersYet')}</p>
        )}
        {!loadingMore && +pagination.page < +pagination.total && (
          <Button
            className={styles.loadMore}
            label={t('loadMore')}
            onClick={onLoadMore}
          />
        )}
      </div>
    </div>
  );
};

export default SelectNumberPage;
