import qs from 'query-string';
import React from 'react';
import styles from './GoBack.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import BackIcon from '../../assets/icons/ic_back.svg';

const GoBack: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { type } = qs.parse(search) as { [key: string]: string };
  const isPlansRoute = useRouteMatch(['/plans']);

  return (
    <button
      className={styles.container}
      onClick={() => {
        if (isPlansRoute || history.length <= 2) {
          if (type === 'package') {
            history.push({ pathname: '/regions' });
          } else if (type === 'global-package') {
            history.push({ pathname: '/global-packages' });
          } else {
            history.push({ pathname: '/countries' });
          }
        } else {
          history.goBack();
        }
      }}
    >
      <img src={BackIcon} alt="Back" className={styles.icon} />
      <span className={styles.label}>{t('back')}</span>
    </button>
  );
};

export default GoBack;
